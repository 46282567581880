import { gql } from '@apollo/client';

export const UpdateChampionshipMatchEntityPlayer = gql`
  mutation UpdateChampionshipMatchEntityPlayer(
    $championshipMatchEntityPlayers: [InputChampionshipMatchEntityPlayer]!
  ) {
    updateChampionshipMatchEntityPlayer(
      championshipMatchEntityPlayers: $championshipMatchEntityPlayers
    ) {
      uuid
    }
  }
`;

export const DeleteChampionshipMatchEntityPlayer = gql`
  mutation DeleteChampionshipMatchEntityPlayer($uuid: UUID!) {
    deleteChampionshipMatchEntityPlayer(uuid: $uuid) {
      uuid
    }
  }
`;

export const AddChampionshipMatchEntityPlayer = gql`
  mutation AddChampionshipMatchEntityPlayer(
    $championshipMatchEntityUuid: UUID!
    $championshipEntityPlayers: [InputAddChampionshipEntityPlayerToChampionshipMatchEntity]!
  ) {
    addChampionshipMatchEntityPlayer(
      championshipMatchEntityUuid: $championshipMatchEntityUuid
      championshipEntityPlayers: $championshipEntityPlayers
    ) {
      uuid
    }
  }
`;
