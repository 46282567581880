import { gql } from '@apollo/client';

export const GetTags = gql`
  query($filter: InputGenericFilter, $pagination: InputPagePagination, $order: InputGenericOrder) {
    tags(filter: $filter, order: $order, pagination: $pagination) {
      edges {
        uuid
        name
      }
    }
  }
`;
