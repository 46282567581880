import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ChampionshipMatch } from 'queries/types';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  & > span:first-of-type {
    text-align: right;
  }
`;

const Team = styled.span`
  font-size: 30px;
  line-height: 36px;
  display: inline-block;
  width: 50%;
  padding: 0 15px;
`;

const Score = styled.span`
  color: #9845fd;
  margin: 0 25px;
  display: inline-block;
`;

const Versus = styled.span`
  font-size: 22px;
  line-height: 29px;
  color: #9fa8b7;
  display: inline-block;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

interface Props {
  match: ChampionshipMatch;
}

export const HeaderMatchScore = ({ match }: Props) => {
  const { t } = useTranslation();

  const firstTeam = match.championshipMatchEntities && match.championshipMatchEntities[0];
  const secondTeam = match.championshipMatchEntities && match.championshipMatchEntities[1];

  const firstTeamName = firstTeam && firstTeam.championshipEntity.championshipTeam.team.shortName;
  const secondTeamName =
    secondTeam && secondTeam.championshipEntity.championshipTeam.team.shortName;

  return (
    <Container>
      <Team>
        {firstTeamName}
        <Score>
          {firstTeam && firstTeam.matchScore}
          {firstTeam && firstTeam.drawScore ? ` (${firstTeam.drawScore})` : null}
        </Score>
      </Team>
      <Versus>{t('sportsMatch.score.versus')}</Versus>
      <Team>
        <Score>
          {secondTeam && secondTeam.drawScore ? `(${secondTeam.drawScore}) ` : null}
          {secondTeam && secondTeam.matchScore}
        </Score>
        {secondTeamName}
      </Team>
    </Container>
  );
};
