import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { PageHeader } from 'components/pageHeader';
import { SubmitButton } from 'components/submitButton';

import { IconDelete } from 'assets/icons/iconDelete';

const StyledPageHeader = styled(PageHeader)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: 25px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;

  button {
    margin-left: 12px;
  }
`;

interface Props {
  name: string;
  loading?: boolean;
  disabled?: boolean;
}

export const Header = ({ name, loading, disabled }: Props) => {
  const { t } = useTranslation();

  return (
    <StyledPageHeader title={name}>
      <ButtonsWrapper>
        <Button
          className="mr-2"
          icon={<IconDelete width="14" height="14" className={'anticon'} />}
          htmlType="reset"
        >
          {t('sportChampionshipGroup.reset')}
        </Button>
        <SubmitButton
          isLoading={loading}
          disabled={disabled}
          label={t('sportChampionshipGroup.submit')}
        />
      </ButtonsWrapper>
    </StyledPageHeader>
  );
};
