import { UPLOAD_ENDPOINT } from 'config/api';

export default class Video {
  constructor({ data, api, readOnly }) {
    this.api = api;
    this.readOnly = readOnly;
    this.data = data;

    this.videoResult = '';
    this.handleChange.bind(this);

    this.buttonContent = 'Seleccionar Vídeo';
    this.buttonLoading = 'Loading';
  }

  static get toolbox() {
    return {
      title: 'Vídeo',
      icon:
        '<svg width="17" height="15" viewBox="0 0 336 276" xmlns="http://www.w3.org/2000/svg"><path d="M291 150V79c0-19-15-34-34-34H79c-19 0-34 15-34 34v42l67-44 81 72 56-29 42 30zm0 52l-43-30-56 30-81-67-66 39v23c0 19 15 34 34 34h178c17 0 31-13 34-29zM79 0h178c44 0 79 35 79 79v118c0 44-35 79-79 79H79c-44 0-79-35-79-79V79C0 35 35 0 79 0z"/></svg>',
    };
  }

  renderButtonLoading(button) {
    button.classList.add('ant-btn-loading');

    const loading =
      '<span class="ant-btn-loading-icon" style=""><span role="img" aria-label="loading" class="anticon anticon-loading anticon-spin"><svg viewBox="0 0 1024 1024" focusable="false" data-icon="loading" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path></svg></span></span>';

    button.innerHTML = `${loading} <span>${this.buttonLoading}</span>`;
  }

  handleChange(input, video, button) {
    const formdata = new FormData();
    formdata.append('file', input.files[0]);

    this.renderButtonLoading(button);

    const headers = new Headers();
    headers.append('Authorization', `Bearer ${localStorage.getItem('token')}`);

    const requestOptions = {
      method: 'POST',
      headers: headers,
      body: formdata,
    };
    fetch(UPLOAD_ENDPOINT, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        this.videoResult = data.data;
        this.save();

        video.setAttribute('src', data.data.url);
        video.style.display = 'block';

        button.style.display = 'none';
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    const videoArea = document.createElement('div');
    videoArea.classList.add('editor-video-element');

    const button = document.createElement('button');
    button.classList.add('ant-btn', 'ant-btn-primary');
    button.textContent = this.buttonContent;
    button.addEventListener('click', (e) => {
      e.preventDefault();
      input.click();
    });

    const video = document.createElement('video');
    video.setAttribute('controls', 'controls');
    video.style.display = 'none';
    if (this.data.file) {
      video.setAttribute('src', this.data.file.url);
      video.style.display = 'block';

      button.style.display = 'none';
    }

    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'video/mp4');
    if (this.data.file) {
      input.setAttribute('value', this.data.file);
    }
    input.addEventListener('change', () => {
      this.handleChange(input, video, button);
    });
    //
    videoArea.append(video);
    videoArea.append(button);
    videoArea.append(input);
    return videoArea;
  }

  save() {
    return {
      file: this.videoResult || this.data.file,
    };
  }
}
