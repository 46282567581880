import { gql } from '@apollo/client';

export const GetChampionshipMatch = gql`
  query GetChampionshipMatch($uuid: UUID!) {
    championshipMatch(uuid: $uuid) {
      uuid
      finishedAt
      location
      datetime
      clock {
        title
        status
        lastValue
        lastTimestamp
        direction
        startedAt
        stoppedAt
        championshipMatchEntities {
          matchScore
          drawScore
          clockScore
        }
      }
      championshipMatchEntities(orderBy: [{ position: asc }, { uuid: asc }]) {
        uuid
        matchScore
        drawScore
        position
        championshipEntity {
          uuid
          championshipTeam {
            team {
              name
              shortName
            }
          }
        }
        championshipMatchEntityPlayers(
          orderBy: { championshipEntityPlayer: { player: { firstName: asc } } }
        ) {
          uuid
          number
          championshipEntityPlayer {
            uuid
            championshipMatchEntityPlayers {
              uuid
              number
              type
            }
            player {
              identification
              firstName
              lastName
            }
          }
        }
      }
      championshipGroup {
        name
        championshipStage {
          name
          championshipCompetition {
            name
            championshipSport {
              uuid
              matchDefaultChampionshipEventKey
            }
          }
        }
        championshipEntities {
          uuid
          championshipTeam {
            uuid
            team {
              name
            }
          }
        }
      }
      championshipMatchEvents {
        uuid
        createdAt
        title
        subtitle
        time
        valueClockAggregated
        championshipEvent {
          name
          logo {
            url
          }
          championshipSport {
            uuid
            matchDefaultChampionshipEventKey
          }
        }
        championshipMatchEntityPlayer {
          championshipEntityPlayer {
            player {
              firstName
              lastName
            }
          }
        }
        auxChampionshipMatchEntityPlayer {
          championshipEntityPlayer {
            player {
              firstName
              lastName
            }
          }
        }
        championshipMatchEntity {
          position
        }
        championshipMatch {
          uuid
        }
      }
    }
  }
`;

export const EndMatch = gql`
  mutation EndMatch($uuid: UUID!) {
    championshipMatchEnd(uuid: $uuid) {
      uuid
    }
  }
`;

export const CreateChampionshipMatch = gql`
  mutation CreateChampionshipMatch($input: InputCreateChampionshipMatch!) {
    createChampionshipMatch(input: $input) {
      uuid
    }
  }
`;

export const UpdateChampionshipMatch = gql`
  mutation UpdateChampionshipMatch($uuid: UUID!, $input: InputUpdateChampionshipMatch!) {
    updateChampionshipMatch(uuid: $uuid, input: $input) {
      uuid
    }
  }
`;

export const DeleteChampionshipMatch = gql`
  mutation DeleteChampionshipMatch($uuid: UUID!) {
    deleteChampionshipMatch(uuid: $uuid) {
      uuid
    }
  }
`;

export const DeleteChampionshipMatchEvent = gql`
  mutation DeleteChampionshipMatchEvent($championshipMatchEventUuid: UUID!) {
    championshipMatchEventDelete(championshipMatchEventUuid: $championshipMatchEventUuid) {
      uuid
    }
  }
`;
