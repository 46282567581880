import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Modal, Table } from 'antd';
import { ITEMS_PER_PAGE } from 'config/main';
import { getTablePagination } from 'helpers/tablePagination';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SearchField } from 'components/searchField';

import { GetPlayers } from 'queries/sportsPlayers';
import { AddTeamToPlayer } from 'queries/sportsPlayers';
import { GetSportsTeam } from 'queries/sportsTeamsMutations';
import {
  Mutation,
  MutationAddTeamToPlayerArgs,
  Pagination,
  Player,
  Query,
  QueryPlayersArgs,
} from 'queries/types';

interface Props {
  isLoading: boolean;
  showModal: boolean;
  onClose: () => void;
  onRemoveConfirm: () => void;
  teamUuid: string;
}

export const SportsTeamsPlayersModal = ({
  isLoading,
  showModal,
  onClose,
  onRemoveConfirm,
  teamUuid,
}: Props) => {
  const { t } = useTranslation();

  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchQuery, setSearchQuery] = useState<string>('');

  const variables: QueryPlayersArgs = {
    filter: {
      query: searchQuery,
    },
    pagination: {
      page: selectedPage,
      take: ITEMS_PER_PAGE,
    },
  };

  const [getPlayers, { data }] = useLazyQuery<Query, QueryPlayersArgs>(GetPlayers, {
    variables,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  useEffect(() => {
    getPlayers();
  }, []);

  const [addTeamToPlayer, { data: isAdded, loading: isAdding }] = useMutation<
    Mutation,
    MutationAddTeamToPlayerArgs
  >(AddTeamToPlayer, {
    refetchQueries: [
      {
        query: GetSportsTeam,
        variables: {
          uuid: teamUuid,
        },
      },
    ],
    awaitRefetchQueries: true,
  });

  useEffect(() => {
    if (isAdded) handleCancel();
  }, [isAdded]);

  /**
   * Handles confirm button click
   * Calls onRemoveConfirm method that fires the mutation
   */
  const handleOk = () => {
    addTeamToPlayer({
      variables: {
        playerUuid: selectedRowKeys[0],
        teamUuid: teamUuid,
      },
    });
  };

  /**
   * Handles modal close button click
   * Closes the modal without any mutation happening
   */
  const handleCancel = useCallback(() => {
    setSelectedRowKeys([]);
    onClose();
  }, [onClose]);

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    setSearchQuery(value);

    // reset pagination
    setSelectedPage(1);
  };

  const tableColumns = [
    {
      title: '',
      dataIndex: 'firstName',
      render: (firstName: string, player: Player) => (
        <span>
          {firstName} {player.lastName}
        </span>
      ),
    },
    {
      title: '',
      dataIndex: 'identification',
      render: (identification: string) => <span>{identification}</span>,
    },
    {
      title: '',
    },
  ];

  const list = (data?.players.edges as Player[]) || [];
  const pagination = data?.players.pagination as Pagination;

  return (
    <Modal
      width="960px"
      title={t('sportsTeamsPlayersModal.title')}
      visible={showModal}
      onCancel={handleCancel}
      centered={true}
      footer={[
        <Button key="reset" loading={isAdding} onClick={handleCancel}>
          {t('actions.cancel')}
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={isAdding}
          onClick={handleOk}
          disabled={selectedRowKeys.length === 0}
        >
          {t('actions.confirm')}
        </Button>,
      ]}
    >
      <SearchField onChange={onSearch} />
      <Table
        columns={tableColumns}
        dataSource={list}
        //   loading={loading || mutationLoading}
        rowKey="uuid"
        pagination={getTablePagination(pagination, setSelectedPage)}
        rowSelection={{
          selectedRowKeys: selectedRowKeys,
          type: 'radio',
          preserveSelectedRowKeys: false,
          onChange: (selectedKeys: any) => {
            setSelectedRowKeys(selectedKeys);
          },
        }}
      />
    </Modal>
  );
};
