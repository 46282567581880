import { Input, Row, Col, Card, Form, FormInstance, Select } from 'antd';
import { useTranslation } from 'react-i18next';

import { ChampionshipSport } from 'queries/types';

export enum FormMode {
  ADD = 'add',
  EDIT = 'edit',
}

type FormProps = {
  form: FormInstance;
  mode: FormMode;
  championshipSports?: ChampionshipSport[];
  props: any;
};

export const SportsChampionshipCompetitionForm = ({
  form,
  mode,
  championshipSports,
}: FormProps) => {
  const { t } = useTranslation();

  const I18N_PREFIX = 'sportsChampionshipcompetitionForm';
  const I18N_PREFIX_FIELDS = `${I18N_PREFIX}.fields`;

  return (
    <Row gutter={16}>
      <Col xs={24}>
        <Card>
          <Form.Item
            name="name"
            label={t(`${I18N_PREFIX_FIELDS}.name.field`)}
            rules={[{ required: true, message: t(`${I18N_PREFIX_FIELDS}.name.errorMessage`) }]}
          >
            <Input placeholder={t(`${I18N_PREFIX_FIELDS}.name.placeholder`)} />
          </Form.Item>

          {championshipSports && (
            <Form.Item
              name="championshipSport"
              label={t(`${I18N_PREFIX_FIELDS}.sport.field`)}
              rules={[{ required: true, message: t(`${I18N_PREFIX_FIELDS}.sport.errorMessage`) }]}
            >
              <Select
                className="w-100"
                placeholder={t(`${I18N_PREFIX_FIELDS}.sport.placeholder`)}
                options={championshipSports?.map((sport: ChampionshipSport) => {
                  return { label: sport.name.pt, value: sport.uuid };
                })}
              />
            </Form.Item>
          )}
        </Card>
      </Col>
    </Row>
  );
};
