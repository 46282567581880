import styled from 'styled-components';

import { Championship, ChampionshipTeam, InputChampionship, Team } from 'queries/types';

export const transformFormValues = (values: any): InputChampionship => {
  return {
    name: { pt: values.name },
    shortName: { pt: values.shortName },
    logo: values.logo,
    reach: values.reach,
    managementEntity: values.managementEntity,
  };
};

export const transformInitialValues = (data: Championship): any => {
  const { name, shortName, logo, reach, managementEntity } = data;

  return {
    name: name.pt,
    shortName: shortName?.pt || '',
    logo,
    reach,
    managementEntity: managementEntity.uuid,
  };
};

export const setTeams = (
  originList: any,
  checkedList: any,
  targetList: any
): {
  teams: any;
  teamsGroup: any;
} => {
  let teamsGroup = [...targetList];

  checkedList.forEach((checked: any) => {
    const index = originList.findIndex((a: any) => (a && a.value && a.value === checked) || false);
    teamsGroup = [...teamsGroup, originList[index]];

    originList.splice(index, 1);
  });

  return {
    teams: originList,
    teamsGroup,
  };
};

export const setTeamsGroup = (
  originList: any,
  checkedList: any,
  targetList: any
): {
  teams: any;
  teamsGroup: any;
} => {
  let teamsGroup = [...targetList];

  checkedList.forEach((checked: any) => {
    const index = originList.findIndex((a: any) => (a && a.value && a.value === checked) || false);
    teamsGroup = [...teamsGroup, originList[index]];

    originList.splice(index, 1);
  });

  return {
    teams: teamsGroup,
    teamsGroup: originList,
  };
};

export const getAllTeamsValues = (teamList: any) => {
  return teamList.map((team: any) => team.value);
};

const TeamName = styled.span`
  span {
    color: #9fa8b7;
  }
`;

export const teamsToOptions = (teams: Team[]) => {
  return teams.map((team: Team) => {
    return {
      label: <TeamName>{team.name}</TeamName>,
      value: team.uuid,
    };
  });
};

export const entityTeamToOptions = (teams: ChampionshipTeam[]) => {
  return teams.map((team: ChampionshipTeam) => {
    return {
      label: <TeamName>{team.team.name} </TeamName>,
      value: team.team.uuid,
    };
  });
};
