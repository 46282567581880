import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { RemoveFromHighlightsMutation } from 'queries/mutations';
import { Mutation, MutationHighlightFeedPostArgs } from 'queries/types';

export function useHighlightToggleMutation() {
  const [removeFromHighlights, { loading: isRemoving }] = useMutation<
    Mutation,
    MutationHighlightFeedPostArgs
  >(RemoveFromHighlightsMutation);

  const [addToHighlights, { loading: isAdding }] = useMutation<
    Mutation,
    MutationHighlightFeedPostArgs
  >(RemoveFromHighlightsMutation);

  /**
   * Calls the AddToHighlights mutation
   * Adds an highlight to the list
   */
  const addHighlight = useCallback((uuid: string, cb?: () => void) => {
    addToHighlights({
      variables: {
        uuid,
        highlighted: true,
      },
    })
      .then(() => {
        cb && cb();
      })
      .catch((err) => {
        console.log('AddToHighlights Error:', err);
      });
  }, []);

  /**
   * Calls the RemoveFromHighlights mutation
   * Removes an highlight from appearing in the list
   */
  const removeHighlight = useCallback((uuid: string, cb?: () => void) => {
    removeFromHighlights({
      variables: {
        uuid,
        highlighted: false,
      },
    })
      .then(() => {
        cb && cb();
      })
      .catch((err) => {
        console.log('RemoveFromHighlights Error:', err);
      });
  }, []);

  return {
    isAdding,
    isRemoving,
    addHighlight,
    removeHighlight,
  };
}
