import { Input, Row, Col, Card, Form, FormInstance, DatePicker, Select } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import styled from 'styled-components';

import { getPagePositions } from './utils';

import { Editor, EditorMode } from 'assets/editorjs/editor';

const EditorWrapper = styled.div``;

export enum FormMode {
  ADD = 'add',
  EDIT = 'edit',
}

type FormProps = {
  form: FormInstance;
  mode: FormMode;
  props: any;
};

export const AdvertisementForm = ({ form, mode }: FormProps) => {
  const { t } = useTranslation();
  const { section, position } = useParams<{ section: string; position: string }>();

  const [blocksData, setBlocksData] = useState<any[]>(form.getFieldValue('blocks'));
  const [startDateData, setStartDateData] = useState<string>(form.getFieldValue('startDate'));
  const [endDateData, setEndDateData] = useState<string>(form.getFieldValue('endDate'));

  useEffect(() => {
    form.setFieldsValue({
      placement: form.getFieldValue('placement')
        ? form.getFieldValue('placement')
        : `${section}|${position}`,
    });
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      blocks: blocksData ? blocksData : form.getFieldValue('blocks'),
    });

    if (!blocksData) {
      setBlocksData(form.getFieldValue('blocks'));
    }
  }, [blocksData]);

  const handleStartDateChange = (_date: any, dateString: string) => {
    setStartDateData(dateString);
  };

  useEffect(() => {
    form.setFieldsValue({
      startDate: startDateData ? startDateData : form.getFieldValue('startDate'),
    });

    if (!startDateData) {
      setStartDateData(form.getFieldValue('startDate'));
    }
  }, [startDateData]);

  const handleEndDateChange = (_date: any, dateString: string) => {
    setEndDateData(dateString);
  };

  useEffect(() => {
    form.setFieldsValue({
      endDate: endDateData ? endDateData : form.getFieldValue('endDate'),
    });

    if (!endDateData) {
      setEndDateData(form.getFieldValue('endDate'));
    }
  }, [endDateData]);

  const I18N_PREFIX = 'advertisementForm';
  const I18N_PREFIX_FIELDS = `${I18N_PREFIX}.fields`;
  const I18N_PREFIX_SECTIONS = `${I18N_PREFIX}.sections`;

  return (
    <Row gutter={16}>
      <Col xs={24} sm={24} md={17}>
        <Card title={t(`${I18N_PREFIX_SECTIONS}.basicInfo`)}>
          <Form.Item
            name="title"
            label={t(`${I18N_PREFIX_FIELDS}.title.field`)}
            rules={[{ required: false, message: t(`${I18N_PREFIX_FIELDS}.title.errorMessage`) }]}
          >
            <Input placeholder={t(`${I18N_PREFIX_FIELDS}.title.placeholder`)} />
          </Form.Item>
          <Form.Item
            name="note"
            label={t(`${I18N_PREFIX_FIELDS}.note.field`)}
            rules={[{ required: true, message: t(`${I18N_PREFIX_FIELDS}.note.errorMessage`) }]}
          >
            <Input placeholder={t(`${I18N_PREFIX_FIELDS}.note.placeholder`)} />
          </Form.Item>
          <Form.Item
            name="placement"
            label={t(`${I18N_PREFIX_FIELDS}.placement.field`)}
            rules={[{ required: true, message: t(`${I18N_PREFIX_FIELDS}.placement.errorMessage`) }]}
          >
            <Select
              style={{ width: '100%' }}
              placeholder={t(`${I18N_PREFIX_FIELDS}.placement.placeholder`)}
              options={getPagePositions(t)}
              disabled={mode === FormMode.EDIT}
            />
          </Form.Item>
          <Form.Item
            name="subtitle"
            label={t(`${I18N_PREFIX_FIELDS}.subtitle.field`)}
            rules={[{ required: false, message: t(`${I18N_PREFIX_FIELDS}.subtitle.errorMessage`) }]}
          >
            <Input placeholder={t(`${I18N_PREFIX_FIELDS}.subtitle.placeholder`)} />
          </Form.Item>
          <Form.Item
            name="cta"
            label={t(`${I18N_PREFIX_FIELDS}.cta.field`)}
            rules={[{ required: false, message: t(`${I18N_PREFIX_FIELDS}.cta.errorMessage`) }]}
          >
            <Input placeholder={t(`${I18N_PREFIX_FIELDS}.cta.placeholder`)} />
          </Form.Item>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                name="startDate"
                label={t(`${I18N_PREFIX_FIELDS}.startDate.field`)}
                rules={[
                  { required: true, message: t(`${I18N_PREFIX_FIELDS}.startDate.errorMessage`) },
                ]}
              >
                <DatePicker
                  onChange={handleStartDateChange}
                  placeholder={t(`${I18N_PREFIX_FIELDS}.startDate.placeholder`)}
                  value={(startDateData && moment(startDateData)) || null}
                />
                <Input type="hidden" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="endDate"
                label={t(`${I18N_PREFIX_FIELDS}.endDate.field`)}
                rules={[
                  { required: true, message: t(`${I18N_PREFIX_FIELDS}.endDate.errorMessage`) },
                ]}
              >
                <DatePicker
                  onChange={handleEndDateChange}
                  placeholder={t(`${I18N_PREFIX_FIELDS}.endDate.placeholder`)}
                  value={(endDateData && moment(endDateData)) || null}
                />
                <Input type="hidden" />
              </Form.Item>
            </Col>
          </Row>
        </Card>
        <Card title={t(`${I18N_PREFIX_SECTIONS}.blocks`)}>
          <EditorWrapper className={`editor-gallery`}>
            <Editor blocks={blocksData} update={setBlocksData} mode={EditorMode.MEDIA} />
            <Form.Item name="blocks">
              <Input type="hidden" />
            </Form.Item>
          </EditorWrapper>
        </Card>
      </Col>
      <Col xs={24} sm={24} md={7}>
        <Card title={t(`${I18N_PREFIX_SECTIONS}.suggestions`)}>
          Pequeno texto a dar algumas sugestões de como usar isto.
        </Card>
      </Col>
    </Row>
  );
};
