import { ChampionshipStage, InputChampionshipStage } from 'queries/types';

export const transformFormValues = (values: any): InputChampionshipStage => {
  return {
    name: { pt: values.name },
    championshipCompetition: values.championshipCompetition,
    setConfigGroupPoints: values.configGroupPoints || null,
  };
};

export const transformInitialValues = (competition: string, data?: ChampionshipStage): any => {
  if (data) {
    const { name, championshipCompetition, configGroupPoints } = data;

    return {
      name: name.pt,
      championshipCompetition: championshipCompetition.uuid,
      configGroupPoints: configGroupPoints?.id || '',
    };
  }

  return {
    championshipCompetition: competition,
  };
};
