import { useLazyQuery, useMutation } from '@apollo/client';
import { Form } from 'antd';
import { Store } from 'rc-field-form/lib/interface';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { ROUTES } from 'routes/config';

import { Header } from './header';
import { SportsTeamsForm, FormMode } from './sportsTeamsForm';
import { SportsTeamsPlayers } from './sportsTeamsPlayers';
// import { SportsTeamsRemoveModal } from './sportsTeamsRemoveModal';
import { transformFormValues, transformInitialValues } from './utils';

import { CreateSportsTeams, GetSportsTeam, UpdateSportsTeams } from 'queries/sportsTeamsMutations';
import {
  Mutation,
  MutationCreateTeamArgs,
  MutationUpdateTeamArgs,
  Query,
  QueryTeamArgs,
} from 'queries/types';

export const SportsTeamsCreateEdit = () => {
  const [form] = Form.useForm();
  const history = useHistory();

  // const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);

  const { uuid, section } = useParams<{ uuid: string; section: string }>();

  const [getTeam, { data: team }] = useLazyQuery<Query, QueryTeamArgs>(GetSportsTeam, {
    variables: {
      uuid,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (uuid) getTeam();
  }, [uuid]);

  const [createSportsTeam, { loading: isCreating, data: isCreated }] = useMutation<
    Mutation,
    MutationCreateTeamArgs
  >(CreateSportsTeams);

  useEffect(() => {
    if (isCreated) {
      history.push(`${ROUTES.SportsTeams}${ROUTES.Edit}/${isCreated?.createTeam.uuid}`);
      window.location.reload();
    }
  }, [isCreated]);

  const [updateSportsTeam, { loading: isSaving, data: isUpdated }] = useMutation<
    Mutation,
    MutationUpdateTeamArgs
  >(UpdateSportsTeams);
  useEffect(() => {
    if (isUpdated) window.location.reload();
  }, [isUpdated]);

  const onFinish = async (values: any) => {
    console.log('Success:', {
      values: values,
      transformed: transformFormValues(values),
    });

    if (uuid) {
      updateSportsTeam({
        variables: {
          uuid,
          input: transformFormValues(values),
        },
      });
    } else {
      createSportsTeam({
        variables: {
          input: transformFormValues(values),
        },
      });
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  if (uuid && !team?.team) return null;

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        name="sports-team-form"
        className="sports-team-create-edit-form"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={team?.team ? (transformInitialValues(team.team) as Store) : undefined}
      >
        <Header
          uuid={uuid || ''}
          name={team?.team.name || ''}
          loading={isCreating || isSaving}
          section={section}
        />

        {section === 'players' ? (
          <SportsTeamsPlayers players={team?.team?.players} teamUuid={team?.team?.uuid || ''} />
        ) : (
          <SportsTeamsForm mode={uuid ? FormMode.EDIT : FormMode.ADD} form={form} props />
        )}
      </Form>
      {/* <SportsTeamsRemoveModal
        isLoading={isRemoving}
        showModal={showRemoveModal}
        onClose={() => setShowRemoveModal(false)}
        onRemoveConfirm={() =>
          deleteSportsTeams({
            variables: {
              uuid,
            },
          })
        }
      /> */}
    </>
  );
};
