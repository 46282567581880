import { useLazyQuery, useMutation } from '@apollo/client';
import { Form } from 'antd';
import { Store } from 'rc-field-form/lib/interface';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { ROUTES } from 'routes/config';

import { Header } from './header';
import { SportsPlayersForm, FormMode } from './sportsPlayersForm';
import { transformFormValues, transformInitialValues } from './utils';

import {
  CreateSportsPlayers,
  GetSportsPlayer,
  UpdateSportsPlayers,
} from 'queries/sportsPlayersMutations';
import {
  Mutation,
  MutationCreatePlayerArgs,
  MutationUpdatePlayerArgs,
  Query,
  QueryPlayerArgs,
} from 'queries/types';

export const SportsPlayersCreateEdit = () => {
  const [form] = Form.useForm();
  const history = useHistory();

  const { uuid } = useParams<{ uuid: string }>();

  const [getPlayer, { data: player }] = useLazyQuery<Query, QueryPlayerArgs>(GetSportsPlayer, {
    variables: {
      uuid,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (uuid) getPlayer();
  }, [uuid]);

  const [createSportsPlayer, { loading: isCreating, data: isCreated }] = useMutation<
    Mutation,
    MutationCreatePlayerArgs
  >(CreateSportsPlayers);

  useEffect(() => {
    if (isCreated) {
      history.push(`${ROUTES.SportsPlayers}${ROUTES.Edit}/${isCreated?.createPlayer.uuid}`);
      window.location.reload();
    }
  }, [isCreated]);

  const [updateSportsPlayer, { loading: isSaving, data: isUpdated }] = useMutation<
    Mutation,
    MutationUpdatePlayerArgs
  >(UpdateSportsPlayers);
  useEffect(() => {
    if (isUpdated) window.location.reload();
  }, [isUpdated]);

  const onFinish = async (values: any) => {
    console.log('Success:', {
      values: values,
      transformed: transformFormValues(values),
    });

    if (uuid) {
      updateSportsPlayer({
        variables: {
          uuid,
          input: transformFormValues(values),
        },
      });
    } else {
      createSportsPlayer({
        variables: {
          input: transformFormValues(values),
        },
      });
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  if (uuid && !player?.player) return null;

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        name="sports-player-form"
        className="sports-player-create-edit-form"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={
          player?.player ? (transformInitialValues(player.player) as Store) : undefined
        }
      >
        <Header
          uuid={uuid || ''}
          name={player?.player.firstName || ''}
          loading={isCreating || isSaving}
        />

        <SportsPlayersForm mode={uuid ? FormMode.EDIT : FormMode.ADD} form={form} props />
      </Form>
    </>
  );
};
