import { useMutation } from '@apollo/client';
import { Button } from 'antd';
import { ColorGrey01 } from 'config/main';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { IconDelete } from 'assets/icons/iconDelete';
import { IconPause } from 'assets/icons/iconPause';
import { IconPlay } from 'assets/icons/iconPlay';

import { GetChampionshipMatch } from 'queries/sportsChampionshipMatch';
import { UpdateMatchClockStatus } from 'queries/sportsChampionshipMatchClock';
import {
  ChampionshipMatch,
  ChampionshipMatchesClock,
  ClockStatus,
  Mutation,
  MutationChampionshipMatchClockUpdateStatusArgs,
} from 'queries/types';

const StyledButton = styled(Button)`
  margin-left: 10px;

  span {
    margin-left: 8px;
  }
`;

const COLOR_ENABLED = 'white';
const COLOR_DISABLED = ColorGrey01;

interface Props {
  clock: ChampionshipMatchesClock;
}

export const PartActions = ({ clock }: Props) => {
  const { status } = clock;
  const { t } = useTranslation();
  const { uuid } = useParams<{ uuid: string }>();

  const [updateMatchClockStatus, { loading, data }] = useMutation<
    Mutation,
    MutationChampionshipMatchClockUpdateStatusArgs
  >(UpdateMatchClockStatus, {
    refetchQueries: [
      {
        query: GetChampionshipMatch,
        variables: {
          uuid,
        },
      },
    ],
    awaitRefetchQueries: true,
  });

  const updateStatus = (newStatus: ClockStatus) => {
    if (status !== newStatus) {
      updateMatchClockStatus({
        variables: {
          uuid,
          clockStatus: newStatus,
        },
      });
    }
  };

  const isStatusPlay = status === ClockStatus.Play;

  return (
    <div>
      {status !== ClockStatus.Stop && (
        <>
          <StyledButton
            onClick={() => updateStatus(ClockStatus.Play)}
            type={isStatusPlay ? 'default' : 'primary'}
            loading={loading}
            shape={isStatusPlay ? 'circle' : undefined}
          >
            <IconPlay width="13" height="14" fill={isStatusPlay ? COLOR_DISABLED : COLOR_ENABLED} />
            {!isStatusPlay && <span>{t('sportsMatch.sections.summary.partActions.initLive')}</span>}
          </StyledButton>

          <StyledButton
            icon={
              <IconPause
                width="8"
                height="14"
                fill={isStatusPlay ? COLOR_ENABLED : COLOR_DISABLED}
              />
            }
            onClick={() => updateStatus(ClockStatus.Pause)}
            type={isStatusPlay ? 'primary' : 'default'}
            loading={loading}
            shape="circle"
          />

          <StyledButton onClick={() => updateStatus(ClockStatus.Stop)} loading={loading}>
            {t('sportsMatch.sections.summary.partActions.stop')}
          </StyledButton>
        </>
      )}
      {/* <StyledButton
            icon={<IconDelete width="13" height="13" fill={ColorGrey01} />}
            onClick={() => {}}
            shape="circle"
          /> */}
    </div>
  );
};
