import { TablePaginationConfig } from 'antd';
import { ITEMS_PER_PAGE } from 'config/main';

import { Pagination } from 'queries/types';

export const getTablePagination = (
  pagination: Pagination,
  setSelectedPage: any,
  pageSize = ITEMS_PER_PAGE
): TablePaginationConfig => {
  return {
    pageSize: pageSize,
    total: pageSize * (pagination?.pageCount || 1),
    current: pagination?.pageIndex || 1,
    onChange: setSelectedPage,
    showSizeChanger: false,
  };
};
