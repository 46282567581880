import { useMutation } from '@apollo/client';
import { Button } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import styled from 'styled-components';

import { MatchEndModal } from './matchEndModal';

import { SportsMatchPart } from 'components/sportsMatchPart';
import { AddPart } from 'components/sportsMatchPart/addPart';

import { EndMatch } from 'queries/sportsChampionshipMatch';
import {
  ChampionshipMatch,
  ChampionshipMatchesClock,
  ChampionshipMatchEvent,
  ClockStatus,
  Mutation,
  MutationChampionshipMatchEndArgs,
} from 'queries/types';

const MatchEndButton = styled(Button)`
  background: #dd4436;
  color: white;
  justify-content: center;
  margin-bottom: 25px;
`;

interface Props {
  parts: ChampionshipMatchesClock[];
  events: ChampionshipMatchEvent[];
  championshipMatch: ChampionshipMatch;
  refetch: any;
}

export const Summary = ({ parts, events, championshipMatch, refetch }: Props) => {
  const { t } = useTranslation();

  const [showMatchEndModal, setShowMatchEndModal] = useState<boolean>(false);

  const [setMatchEnd, { loading, data: isEnded }] = useMutation<
    Mutation,
    MutationChampionshipMatchEndArgs
  >(EndMatch, refetch);

  useEffect(() => {
    if (isEnded) setShowMatchEndModal(false);
  }, [isEnded]);

  const isMatchGoing = parts.length > 0 && parts[parts.length - 1].status !== ClockStatus.Stop;

  const showMatchEnd = !championshipMatch.finishedAt;

  const defaultEventKey =
    championshipMatch.championshipGroup.championshipStage.championshipCompetition.championshipSport
      .matchDefaultChampionshipEventKey;

  return (
    <div>
      {parts.map((clock, key) => {
        // filter events
        const eventsByPart = events.filter(
          (event: ChampionshipMatchEvent) =>
            event.createdAt >= clock.startedAt &&
            event.createdAt <= (clock.stoppedAt || event.createdAt)
        );

        return (
          <SportsMatchPart
            clock={clock}
            events={eventsByPart}
            key={clock.lastTimestamp}
            isMatchGoing={isMatchGoing}
            isLastPart={key === parts.length - 1}
            refetch={refetch}
            defaultEventKey={defaultEventKey}
          />
        );
      })}
      {showMatchEnd && (
        <>
          <AddPart disabled={isMatchGoing} />

          <MatchEndButton block onClick={() => setShowMatchEndModal(true)} loading={loading}>
            {t('sportsMatch.sections.summary.endMatch.button')}
          </MatchEndButton>
          <MatchEndModal
            showModal={showMatchEndModal}
            isLoading={loading}
            onClose={() => setShowMatchEndModal(false)}
            onRemoveConfirm={() => {
              setMatchEnd({
                variables: {
                  uuid: championshipMatch.uuid,
                },
              });
            }}
          />
        </>
      )}
    </div>
  );
};
