import { gql } from '@apollo/client';

export const GetChampionshipGroups = gql`
  query GetChampionshipGroups(
    $filter: InputChampionshipGroupsFilter!
    $pagination: InputPagePagination
  ) {
    championshipGroups(filter: $filter, pagination: $pagination) {
      pagination {
        pageIndex
        pageCount
      }
      edges {
        uuid
        name
        championshipStage {
          uuid
          championshipCompetition {
            uuid
            championship {
              uuid
            }
          }
        }
      }
    }
  }
`;

export const CreateChampionshipGroup = gql`
  mutation CreateChampionshipGroup($input: InputChampionshipGroup!) {
    createChampionshipGroup(input: $input) {
      uuid
    }
  }
`;

export const UpdateChampionshipGroup = gql`
  mutation UpdateChampionshipGroup($uuid: UUID!, $input: InputChampionshipGroup!) {
    updateChampionshipGroup(uuid: $uuid, input: $input) {
      uuid
    }
  }
`;

export const DeleteChampionshipGroup = gql`
  mutation DeleteChampionshipGroup($uuid: UUID!) {
    deleteChampionshipGroup(uuid: $uuid) {
      uuid
    }
  }
`;
