import { ROUTES } from 'routes/config';
import { MenuProps } from 'typings/menu';

const I18N_PREFIX = 'tabsMenu.sportsTeams';

/**
 * MAIN MENU SECTION
 */
export const tabsMenu = (uuid: string): MenuProps[] => {
  return [
    {
      key: `${ROUTES.SportsTeams}`,
      path: `${ROUTES.SportsTeams}${ROUTES.Edit}/${uuid}/players`,
      title: `${I18N_PREFIX}.players`,
      icon: '',
    },
    {
      key: `${ROUTES.SportsTeams}`,
      path: `${ROUTES.SportsTeams}${ROUTES.Edit}/${uuid}`,
      title: `${I18N_PREFIX}.info`,
      icon: '',
    },
  ];
};
