import { LoadingOutlined } from '@ant-design/icons';
import { Input, Row, Col, Card, Form, Upload, Select, FormInstance } from 'antd';
import { ColorGrey05 } from 'config/main';
import { useUploadFile } from 'hooks/useUploadFile';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { categoriesToOptions } from './utils';

import { Editor, EditorMode } from 'assets/editorjs/editor';
import { IconDelete } from 'assets/icons/iconDelete';
import { IconImage } from 'assets/icons/iconImage';

import { CategoryList, FeedPostType, Image, TagList, User } from 'queries/types';

const EditorWrapper = styled.div``;

export enum FormMode {
  ADD = 'add',
  EDIT = 'edit',
}

type PressFormProps = {
  mode: FormMode;
  form: FormInstance;
  props: any;
  categories?: CategoryList;
  authors?: [User];
  tags?: TagList;
  feedPostType: FeedPostType;
};

const DeleteButton = styled.button`
  background: #000;
  border: none;
  border-radius: 50%;
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  margin: -53px 0 0 15px;
`;

export const PressForm = ({
  categories,
  authors,
  tags,
  form,
  feedPostType,
  mode,
}: PressFormProps) => {
  const { t } = useTranslation();
  const { Dragger } = Upload;
  const { Option } = Select;

  const [blocksData, setBlocksData] = useState<any[]>(form.getFieldValue('blocks'));
  const [coverData, setCoverData] = useState<Image | null>(form.getFieldValue('cover'));
  const [highlightData, setHighlightData] = useState<Image | null>(form.getFieldValue('highlight'));

  useEffect(() => {
    form.setFieldsValue({
      blocks: blocksData ? blocksData : form.getFieldValue('blocks'),
    });

    if (!blocksData) {
      setBlocksData(form.getFieldValue('blocks'));
    }
  }, [blocksData]);

  const imageUploadProps: any = {
    name: 'file',
    multiple: false,
    listType: 'picture-card',
    showUploadList: false,
  };

  const I18N_PREFIX = 'pressForm';
  const I18N_PREFIX_FIELDS = `${I18N_PREFIX}.fields`;
  const I18N_PREFIX_SECTIONS = `${I18N_PREFIX}.sections`;

  const {
    isUploadFileLoading: isCoverLoading,
    uploadFileData: cover,
    uploadFile: uploadCover,
  } = useUploadFile();
  const {
    isUploadFileLoading: isHighlightLoading,
    uploadFileData: highlight,
    uploadFile: uploadHighlight,
  } = useUploadFile();
  const handleUploadChange = (field: string, info: any) => {
    switch (field) {
      case 'cover':
        uploadCover(info.fileList[0].originFileObj);
        break;
      case 'highlight':
        uploadHighlight(info.fileList[0].originFileObj);
        break;
    }
  };
  useEffect(() => {
    form.setFieldsValue({
      cover: cover?.data ? cover.data : form.getFieldValue('cover'),
    });
    setCoverData(form.getFieldValue('cover'));
  }, [cover]);

  useEffect(() => {
    form.setFieldsValue({
      highlight: highlight?.data ? highlight.data : form.getFieldValue('highlight'),
    });
    setHighlightData(form.getFieldValue('highlight'));
  }, [highlight]);

  const handleRemoveMedia = (field: string) => {
    switch (field) {
      case 'cover':
        form.setFieldsValue({
          cover: null,
        });
        setCoverData(null);
        break;
      case 'highlight':
        form.setFieldsValue({
          highlight: null,
        });
        setHighlightData(null);
        break;
    }
  };

  const editorMode =
    feedPostType === FeedPostType.Video
      ? EditorMode.VIDEO
      : feedPostType === FeedPostType.Gallery
      ? EditorMode.GALLERY
      : EditorMode.DEFAULT;

  return (
    <Row gutter={16}>
      <Col xs={24} sm={24} md={17}>
        <Card title={t(`${I18N_PREFIX_SECTIONS}.basicInfo`)}>
          <Form.Item
            name="title"
            label={t(`${I18N_PREFIX_FIELDS}.title.field`)}
            rules={[{ required: true, message: t(`${I18N_PREFIX_FIELDS}.title.errorMessage`) }]}
          >
            <Input placeholder={t(`${I18N_PREFIX_FIELDS}.title.placeholder`)} />
          </Form.Item>
          <Form.Item
            name="subtitle"
            label={t(`${I18N_PREFIX_FIELDS}.subtitle.field`)}
            rules={[{ required: false, message: t(`${I18N_PREFIX_FIELDS}.subtitle.errorMessage`) }]}
          >
            <Input placeholder={t(`${I18N_PREFIX_FIELDS}.subtitle.placeholder`)} />
          </Form.Item>
        </Card>
        <Card title={t(`${I18N_PREFIX_SECTIONS}.blocks`)}>
          <EditorWrapper className={`editor-${editorMode}`}>
            <Editor blocks={blocksData} update={setBlocksData} mode={editorMode} />
            <Form.Item name="blocks">
              <Input type="hidden" />
            </Form.Item>
          </EditorWrapper>
        </Card>
      </Col>
      <Col xs={24} sm={24} md={7}>
        {feedPostType !== FeedPostType.Video && (
          <Card title={t(`${I18N_PREFIX_SECTIONS}.media`)}>
            <Form.Item label={t(`${I18N_PREFIX_FIELDS}.cover.field`)} name="cover">
              <Input type="hidden" />
              <Dragger
                {...imageUploadProps}
                beforeUpload={() => false}
                onChange={(e) => handleUploadChange('cover', e)}
              >
                {isCoverLoading ? (
                  <div>
                    <LoadingOutlined className="font-size-xxl text-primary" />
                    <div className="mt-3">{t(`${I18N_PREFIX_FIELDS}.cover.uploading`)}</div>
                  </div>
                ) : coverData ? (
                  <img src={coverData.url || ''} alt="avatar" className="img-fluid" />
                ) : (
                  <div>
                    <IconImage width="64" height="64" />
                    <p>{t(`${I18N_PREFIX_FIELDS}.cover.placeholder`)}</p>
                  </div>
                )}
              </Dragger>
              {coverData && (
                <DeleteButton onClick={() => handleRemoveMedia('cover')}>
                  <IconDelete width="12" height="13" fill={ColorGrey05} />
                </DeleteButton>
              )}
            </Form.Item>

            {feedPostType === FeedPostType.News && (
              <Form.Item label={t(`${I18N_PREFIX_FIELDS}.image.field`)} name="highlight">
                <Input type="hidden" />
                <Dragger
                  {...imageUploadProps}
                  beforeUpload={() => false}
                  onChange={(e) => handleUploadChange('highlight', e)}
                >
                  {isHighlightLoading ? (
                    <div>
                      <LoadingOutlined className="font-size-xxl text-primary" />
                      <div className="mt-3">{t(`${I18N_PREFIX_FIELDS}.cover.uploading`)}</div>
                    </div>
                  ) : highlightData ? (
                    <img src={highlightData.url || ''} alt="avatar" className="img-fluid" />
                  ) : (
                    <div>
                      <IconImage width="64" height="64" />
                      <p>{t(`${I18N_PREFIX_FIELDS}.cover.placeholder`)}</p>
                    </div>
                  )}
                </Dragger>
                {highlightData && (
                  <DeleteButton onClick={() => handleRemoveMedia('highlight')}>
                    <IconDelete width="12" height="13" fill={ColorGrey05} />
                  </DeleteButton>
                )}
              </Form.Item>
            )}
          </Card>
        )}
        <Card title={t(`${I18N_PREFIX_SECTIONS}.organization`)}>
          {feedPostType === FeedPostType.News ||
            (feedPostType === FeedPostType.Opinion && (
              <Form.Item name="type" label={t(`${I18N_PREFIX_FIELDS}.type.field`)}>
                <Select
                  className="w-100"
                  placeholder={t(`${I18N_PREFIX_FIELDS}.type.placeholder`)}
                  defaultValue={FeedPostType.News}
                  disabled={mode === FormMode.EDIT}
                  options={[
                    { label: t(FeedPostType.News), value: FeedPostType.News },
                    { label: t(FeedPostType.Opinion), value: FeedPostType.Opinion },
                  ]}
                />
              </Form.Item>
            ))}
          {categories && (
            <Form.Item name="category" label={t(`${I18N_PREFIX_FIELDS}.category.field`)}>
              <Select
                showSearch
                className="w-100"
                placeholder={t(`${I18N_PREFIX_FIELDS}.category.placeholder`)}
                options={[
                  {
                    label: t(`${I18N_PREFIX_FIELDS}.category.none`),
                    value: '',
                  },
                  ...categoriesToOptions(categories),
                ]}
              />
            </Form.Item>
          )}
          {tags && (
            <Form.Item name="tags" label={t(`${I18N_PREFIX_FIELDS}.tags.field`)}>
              <Select
                mode="tags"
                style={{ width: '100%' }}
                placeholder={t(`${I18N_PREFIX_FIELDS}.category.placeholder`)}
                options={categoriesToOptions(tags)}
              />
            </Form.Item>
          )}
          {(feedPostType === FeedPostType.News || feedPostType === FeedPostType.Opinion) &&
            authors && (
              <Form.Item name="author" label={t(`${I18N_PREFIX_FIELDS}.author.field`)}>
                <Select
                  className="w-100"
                  placeholder={t(`${I18N_PREFIX_FIELDS}.category.placeholder`)}
                >
                  <Option key="author-0" value="">
                    {t(`${I18N_PREFIX_FIELDS}.author.none`)}
                  </Option>
                  {authors.map(({ uuid, profile }: User) => (
                    <Option key={uuid} value={uuid}>
                      {profile?.firstName} {profile?.lastName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}

          <Form.Item name="highlightValues">
            <Input type="hidden" />
          </Form.Item>
        </Card>
      </Col>
    </Row>
  );
};
