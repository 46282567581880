import { gql } from '@apollo/client';

export const RemoveFromHighlightsMutation = gql`
  mutation RemoveFromHighlightsMutation($uuid: UUID!, $highlighted: Boolean!) {
    highlightFeedPost(uuid: $uuid, highlighted: $highlighted) {
      uuid
    }
  }
`;

// export const AddToHighlightsMutation = gql`
//   mutation AddToHighlightsMutation($order: Order, $highlighted: Boolean!) {
//     highlightFeedPost(uuid: $uuid, highlighted: $highlighted) {
//       uuid
//     }
//   }
// `;

export const OrderHighlightsMutation = gql`
  mutation OrderHighlightsMutation($order: [OrderFeedPostsHighlightsArgs]!) {
    orderFeedPostHighlights(order: $order) {
      uuid
    }
  }
`;
