import { useMutation } from '@apollo/client';
import { Input, Table, Menu, Dropdown, Button } from 'antd';
import { Link } from 'react-router-dom';
import { ROUTES } from 'routes/config';
import styled from 'styled-components';

import { getTeamsByScores, sortTeamsByScores } from './utils';

import { Icon3Dots } from 'assets/icons/icon3Dots';
import { IconDownArrow } from 'assets/icons/iconDownArrow';
import { IconFeed } from 'assets/icons/iconFeed';
import { IconUpArrow } from 'assets/icons/iconUpArrow';

import {
  UpdateChampionshipEntityScore,
  UpdateChampionshipEntityWeight,
} from 'queries/sportsChampionshipGroup';
import {
  ChampionshipEntity,
  ChampionshipGroup,
  ChampionshipTeam,
  Mutation,
  MutationChampionshipEntityUpdateWeightArgs,
  MutationChampionshipEntityUpdateGroupScoreArgs,
} from 'queries/types';

const Container = styled.div`
  max-height: calc(100vh - 320px);
  overflow-y: auto;
`;

const Team = styled.span`
  font-weight: bold;
`;

const StyledTable = styled(Table)`
  th {
    color: #9fa8b7 !important;
    font-weight: 500 !important;
    font-size: 12px;
  }

  td:last-of-type {
    text-align: center;
  }
`;

const Score = styled.span`
  display: inline-block;
  font-weight: 500;
  font-size: 12px;
  color: #9845fd;
  margin-right: 20px;
  width: 22px;
  text-align: right;
`;

const StyledInput = styled(Input)`
  text-align: center;
  width: 40px;
`;

const ButtonWrapper = styled.div`
  display: fleX;
`;

const StyledButton = styled(Button)`
  width: 50%;
`;

interface Props {
  teams: ChampionshipEntity[] | null | undefined;
  championshipGroup: ChampionshipGroup;
  refetch: any;
}

export const GroupScores = ({ teams, championshipGroup, refetch }: Props) => {
  const [setChampionshipEntityScore, { loading: scoreLoading }] = useMutation<
    Mutation,
    MutationChampionshipEntityUpdateGroupScoreArgs
  >(UpdateChampionshipEntityScore);

  const [setChampionshipEntityWeight, { loading }] = useMutation<
    Mutation,
    MutationChampionshipEntityUpdateWeightArgs
  >(UpdateChampionshipEntityWeight);

  const setEntityWeight = (direction: 'up' | 'down', team: ChampionshipEntity) => {
    let weight = 0;

    const index = teamsByScores[team.groupScore || 0].indexOf(team);

    if (direction === 'up') {
      weight = teamsByScores[team.groupScore || 0][index - 1].weight + 1;
    } else {
      weight = teamsByScores[team.groupScore || 0][index + 1].weight - 1;
    }

    setChampionshipEntityWeight({
      variables: {
        championshipEntityWeight: [
          {
            uuid: team.uuid || '',
            weight,
          },
        ],
      },
    }).then(() => refetch());
  };

  if (!teams) return null;

  const teamsByScores = getTeamsByScores(teams);

  const tableColumns = [
    {
      title: 'Equipa',
      dataIndex: 'championshipTeam',
      render: (championshipTeam: ChampionshipTeam, team: ChampionshipEntity) => (
        <Team>
          <Score>{`${teams?.indexOf(team) + 1}º`}</Score>
          {championshipTeam.team.shortName}
        </Team>
      ),
    },
    {
      title: 'Desempate',
      dataIndex: 'weight',
      render: (_weight: number, team: ChampionshipEntity) => (
        <>
          {team.groupScore && teamsByScores[team.groupScore].length > 1 ? (
            <ButtonWrapper>
              {teamsByScores[team.groupScore][0] !== team ? (
                <StyledButton
                  type="text"
                  shape="circle"
                  onClick={() => setEntityWeight('up', team)}
                >
                  <IconUpArrow width="20" height="20" />
                </StyledButton>
              ) : (
                <StyledButton disabled />
              )}
              {teamsByScores[team.groupScore][teamsByScores[team.groupScore].length - 1] !==
              team ? (
                <StyledButton
                  type="text"
                  shape="circle"
                  onClick={() => setEntityWeight('down', team)}
                >
                  <IconDownArrow width="20" height="20" />
                </StyledButton>
              ) : (
                <StyledButton disabled />
              )}
            </ButtonWrapper>
          ) : null}
        </>
      ),
    },
    {
      title: 'Pts',
      dataIndex: 'groupScore',
      render: (groupScore: number, team: any) => (
        <StyledInput
          disabled={loading || scoreLoading}
          defaultValue={groupScore}
          onPressEnter={(e: any) =>
            setChampionshipEntityScore({
              variables: {
                championshipEntityGroupScore: [
                  {
                    uuid: team.uuid || '',
                    groupScore: parseFloat(e.target.value),
                  },
                ],
              },
            }).then(() => refetch())
          }
        />
      ),
      width: '3%',
    },
    {
      title: '',
      dataIndex: 'championshipTeam',
      render: (championshipTeam: ChampionshipTeam, team: any) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="0">
                <Link
                  to={`${ROUTES.SportsChampionship}/${championshipGroup.uuid}/team/${team.uuid}/${championshipTeam.team.uuid}`}
                >
                  <IconFeed width="12px" height="12px" fill="black" /> Editar Equipa
                </Link>
              </Menu.Item>
            </Menu>
          }
          trigger={['click']}
        >
          <Button
            className="ant-dropdown-link"
            onClick={(e) => e.preventDefault()}
            icon={<Icon3Dots width="15" height="15" />}
            shape="circle"
          ></Button>
        </Dropdown>
      ),
      width: '3%',
    },
  ];

  return (
    <Container>
      <div className="table-responsive">
        <StyledTable
          columns={tableColumns as any}
          dataSource={teams}
          loading={loading || scoreLoading}
          rowKey="uuid"
          pagination={false}
        />
      </div>
    </Container>
  );
};
