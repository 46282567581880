import { gql } from '@apollo/client';

export const GetPostListQuery = gql`
  query GetPostListQuery(
    $filter: InputFeedPostsFilter
    $pagination: InputPagePagination
    $order: InputFeedPostOrder
  ) {
    feedPosts(filter: $filter, pagination: $pagination, order: $order) {
      edges {
        uuid
        createdAt
        modifiedAt
        publishedAt
        title
        subtitle
        note
        type
        origin
        cover {
          url
          contentType
        }
        highlight {
          stretch
          position
          video {
            url
          }
          image {
            url
          }
        }
        blocks
        published {
          app
          site
        }
        category {
          name
        }
        advertisement {
          page
          position
          startDate
          endDate
        }
      }
      pagination {
        pageEdgesCount
        pageCount
        pageIndex
        pageEdgesMaxCount
      }
    }
  }
`;

export const UpdatePublishedPost = gql`
  mutation UpdatePublishedPost($uuid: UUID!, $published: InputPartialFeedPostPublished!) {
    publishFeedPost(uuid: $uuid, published: $published) {
      uuid
      published {
        app
      }
    }
  }
`;
