import { gql } from '@apollo/client';

export const CreateSportsPlayers = gql`
  mutation CreateSportsPlayers($input: InputPlayer!) {
    createPlayer(input: $input) {
      uuid
    }
  }
`;

export const UpdateSportsPlayers = gql`
  mutation UpdateSportsPlayers($uuid: UUID!, $input: InputPlayer!) {
    updatePlayer(uuid: $uuid, input: $input) {
      uuid
    }
  }
`;

export const GetSportsPlayer = gql`
  query GetSportsPlayer($uuid: UUID!) {
    player(uuid: $uuid) {
      firstName
      lastName
      identification
      championshipSports {
        uuid
        name
      }
    }
  }
`;
