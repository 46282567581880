import { Layout, Menu } from 'antd';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { ProfileNav } from './ProfileNav';

import { IconCollapse } from 'assets/icons/iconCollapse';
import { Logo } from 'assets/images/Logo';

const { Header: Container } = Layout;

const Nav = styled.div`
  width: 100%;
`;

const LogoWrapper = styled.div`
  width: 250px;
`;

export const Header = () => {
  return (
    <Container className="app-header">
      <div className="app-header-wrapper layout-top-nav">
        <LogoWrapper className="logo">
          <NavLink to="/">
            <Logo />
          </NavLink>
        </LogoWrapper>
        <Nav className="nav">
          <div className="nav-left">
            <Menu mode="horizontal">
              <Menu.Item key="0" onClick={() => {}}>
                <div className="nav-icon">{/*<IconCollapse width="18" height="16" />*/}</div>
              </Menu.Item>
            </Menu>
          </div>
          <div className="nav-right">
            <ProfileNav />
          </div>
        </Nav>
      </div>
    </Container>
  );
};
