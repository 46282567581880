import { useMutation, useLazyQuery } from '@apollo/client';
import { Card, Table } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import { ITEMS_PER_PAGE } from 'config/main';
import { lineClamp } from 'helpers/lineClamp';
import { getTablePagination } from 'helpers/tablePagination';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ListActionElementType, ListActionElement } from 'components/listActionElement';
import { Tag } from 'components/tag';

import { IconFacebook } from 'assets/icons/iconFacebook';
import { IconInstagram } from 'assets/icons/iconInstagram';

import { GetPostListQuery, UpdatePublishedPost } from 'queries/postsList';
import {
  FeedPost,
  Query,
  QueryFeedPostsArgs,
  Scalars,
  FeedPostOrigin,
  FeedPostType,
  InputPartialFeedPostPublished,
  Mutation,
  MutationPublishFeedPostArgs,
  Pagination,
  FeedPostOrderKeys,
  OrderDirection,
  InputFeedPostOrder,
  FeedPostPublishedType,
} from 'queries/types';

const CardWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: flex-start;
`;

const Subheader = styled.h4`
  margin-bottom: 32px;
`;

const IconTagWrapper = styled.span`
  display: flex;
  align-items: center;

  & > span {
    margin-left: 20px;
  }
`;

const AvatarTitleWrapper = styled.span`
  display: flex;
  align-items: center;
`;

const Title = styled.span`
  font-weight: bold;
  ${lineClamp(3)}
  margin-left: 20px;
`;

const I18N_PREFIX: string = 'socialList';

interface Props {
  origin?: FeedPostOrigin[];
  types?: FeedPostType[];
  order?: InputFeedPostOrder;
  published?: FeedPostPublishedType[];
  section?: FeedPostType;
  createButton?: boolean;
  actionElementType?: ListActionElementType;
}

export const SocialList: React.VFC<Props> = ({
  origin = [FeedPostOrigin.Internal],
  types = [],
  order = { key: FeedPostOrderKeys.CreatedAt, direction: OrderDirection.Desc },
  published,
  actionElementType = ListActionElementType.Toggle,
}: Props) => {
  const [selectedPage, setSelectedPage] = useState<number>(1);

  const { t } = useTranslation();

  const variables: QueryFeedPostsArgs = {
    filter: {
      query: '',
      type: types,
      origin: origin,
      published: published,
    },
    order: order,
    pagination: {
      page: selectedPage,
      take: ITEMS_PER_PAGE,
    },
  };

  const [getFeedPost, { data, loading }] = useLazyQuery<Query, QueryFeedPostsArgs>(
    GetPostListQuery,
    {
      variables,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only',
    }
  );

  const [updatePublished, { loading: mutationLoading }] = useMutation<
    Mutation,
    MutationPublishFeedPostArgs
  >(UpdatePublishedPost, {
    // TODO: replace with write on cache to avoid a refetch
    refetchQueries: [
      {
        query: GetPostListQuery,
        variables,
      },
    ],
    awaitRefetchQueries: true,
  });

  const setPublishedFeedPost = (
    uuid: Scalars['UUID'],
    currentPublished: InputPartialFeedPostPublished
  ) => {
    const newPublished: InputPartialFeedPostPublished = {
      app: !currentPublished.app,
    };

    updatePublished({
      variables: {
        uuid,
        published: newPublished,
      },
    });
  };

  useEffect(() => {
    getFeedPost();
  }, [getFeedPost]);

  const tableColumns = [
    {
      title: t(`${I18N_PREFIX}.type`),
      dataIndex: 'type',
      render: (type: string, post: FeedPost) => (
        <IconTagWrapper>
          {post.origin === FeedPostOrigin.Facebook ? (
            <IconFacebook width="11" height="19" className="ant-icon" />
          ) : (
            <IconInstagram width="16" height="15" className="ant-icon" />
          )}
          <Tag>{t(type)}</Tag>
        </IconTagWrapper>
      ),
    },
    {
      title: t(`${I18N_PREFIX}.title`),
      dataIndex: 'subtitle',
      render: (text: Scalars['TranslatableField'], post: FeedPost) => (
        <AvatarTitleWrapper>
          <div>
            <Avatar className="ant-avatar-square" src={post.cover?.url} size="large">
              {text.pt}
            </Avatar>
          </div>
          <Title>{text.pt}</Title>
        </AvatarTitleWrapper>
      ),
      width: '50%',
    },
    {
      title: t(`${I18N_PREFIX}.${order.key}`),
      dataIndex: FeedPostOrderKeys.CreatedAt,
      render: (date: string) => <span>{moment(date).format('DD/MM/YYYY')}</span>,
    },
    {
      title:
        actionElementType !== ListActionElementType.Button ? t(`${I18N_PREFIX}.published`) : '',
      dataIndex: 'published',
      render: (published: InputPartialFeedPostPublished, post: FeedPost) => (
        <ListActionElement
          type={actionElementType}
          action={() => setPublishedFeedPost(post.uuid, published)}
          checked={published.app}
        >
          {t('actions.hide')}
        </ListActionElement>
      ),
    },
  ];

  const list = (data?.feedPosts.edges as FeedPost[]) || [];
  const pagination = data?.feedPosts.pagination as Pagination;

  return (
    <Card>
      <CardWrapper>
        <div className="d-flex">
          <Subheader className="font-weight-bold">
            {origin.length === 1
              ? t(`${I18N_PREFIX}.subheader.${origin[0].toLowerCase()}`)
              : t(`${I18N_PREFIX}.subheader.default`)}
          </Subheader>
        </div>
      </CardWrapper>
      <div className="table-responsive">
        <Table
          columns={tableColumns}
          dataSource={list}
          loading={loading || mutationLoading}
          rowKey="uuid"
          pagination={getTablePagination(pagination, setSelectedPage)}
        />
      </div>
    </Card>
  );
};
