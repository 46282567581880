import { gql } from '@apollo/client';

export const GetCategory = gql`
  query($uuid: UUID!) {
    category(uuid: $uuid) {
      name
      logo {
        url
        contentType
        width
        height
      }
    }
  }
`;
