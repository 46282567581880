import { ChampionshipCompetition, ChampionshipEntity, InputPlayer, Player } from 'queries/types';

export const transformFormValues = (values: any): InputPlayer => {
  return {
    firstName: values.firstName,
    lastName: values.lastName,
    identification: values.identification,
    championshipSports: values.championshipSports ? [values.championshipSports] : null,
  };
};

export const transformInitialValues = (data: Player): any => {
  const { firstName, lastName, identification, championshipSports } = data;

  return {
    firstName,
    lastName,
    identification,
    championshipSports: championshipSports ? championshipSports[0]?.uuid : null,
  };
};

export const sortCompetitionsByName = (competitions: ChampionshipCompetition[]) =>
  [...competitions].sort((first, second) => {
    return first.name.pt.localeCompare(second.name.pt);
  }) as ChampionshipCompetition[];

export const sortTeamsByScores = (teams: ChampionshipEntity[]) =>
  [...teams].sort((first, second) => {
    return (
      (second.groupScore || 0) -
      (first.groupScore || 0) -
      (second.weight || 0) -
      (first.weight || 0)
    );
  }) as ChampionshipEntity[];

export const getTeamsByScores = (teams: ChampionshipEntity[]) => {
  let scores: any = [];
  [...teams].forEach(
    (team: any) => (scores[team.groupScore] = [...(scores[team.groupScore] || []), team])
  );

  return scores;
};
