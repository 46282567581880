import styled from 'styled-components';

const Element = styled.span`
  display: inline-block;
  padding: 5px 10px;
  border: 1px solid #eef2f8;
  border-radius: 5px;
  font-size: 12px;
  line-height: 15px;
`;

export const Tag = ({ children }: any) => {
  return <Element>{children}</Element>;
};
