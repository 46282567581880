import { useLazyQuery, useMutation } from '@apollo/client';
import { Form, Checkbox, Button } from 'antd';
import { ALL_ITEMS_ON_PAGE } from 'config/main';
import { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Header } from './header';
import {
  entityTeamToOptions,
  getAllTeamsValues,
  teamsToOptions,
  setTeams,
  setTeamsGroup,
} from './utils';

import { IconAngleLeft } from 'assets/icons/iconAngleLeft';
import { IconAngleRight } from 'assets/icons/iconAngleRight';

import { CreateChampionshipTeams } from 'queries/sportsChampionship';
import { GetTeams } from 'queries/sportsTeamsList';
import {
  ChampionshipTeam,
  Mutation,
  MutationCreateChampionshipTeamsArgs,
  Query,
  QueryTeamsArgs,
} from 'queries/types';

const CheckboxGroup = Checkbox.Group;

const Container = styled.div`
  background: white;
  border: 1px solid #edf2f9;
  border-radius: 10px;
  padding: 50px;
  display: flex;
  justify-content: center;
  align-items: stretch;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  margin: 0 10px;

  button {
    margin: 2px 0;
  }
`;

const TeamsArea = styled.div`
  background: #ffffff;
  border: 1px solid #edf2f9;
  border-radius: 10px;
  padding: 8px 12px;
  width: 350px;
`;

const TeamsAreaHeader = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eef2f8;
  padding-bottom: 8px;
`;

const TeamsList = styled(CheckboxGroup)`
  display: flex;
  flex-flow: column;
  padding: 8px 0;

  label {
    padding: 12px 0;
  }
`;

const I18N_PREFIX = 'sportsChampionshipForm';

interface Props {
  uuid: string;
  championshipTeams: ChampionshipTeam[];
}

export const SportsChampionshipTeam = ({ uuid, championshipTeams }: Props) => {
  const { t } = useTranslation();

  const [teamList, setTeamList] = useState<any>([]);
  const [checkedTeamList, setCheckedTeamList] = useState<any>([]);
  const [indeterminateTeam, setIndeterminateTeam] = useState(true);
  const [checkAllTeams, setCheckAllTeams] = useState(false);

  const [teamGroupList, setTeamGroupList] = useState<any>([]);
  const [checkedTeamGroupList, setCheckedTeamGroupList] = useState<any>([]);
  const [indeterminateTeamGroup, setIndeterminateTeamGroup] = useState(true);
  const [checkAllTeamsGroup, setCheckAllTeamsGroup] = useState(false);

  const [getTeams, { data: teams }] = useLazyQuery<Query, QueryTeamsArgs>(GetTeams, {
    variables: {
      pagination: {
        page: 1,
        take: ALL_ITEMS_ON_PAGE,
      },
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  useEffect(() => {
    getTeams();
  }, []);

  useEffect(() => {
    if (teams?.teams && championshipTeams) {
      setInitListsValues();
    }
  }, [teams, championshipTeams]);

  const [createChampionshipTeams, { loading: isCreating, data: isCreated }] = useMutation<
    Mutation,
    MutationCreateChampionshipTeamsArgs
  >(CreateChampionshipTeams);

  useEffect(() => {
    if (isCreated) {
      window.location.reload();
    }
  }, [isCreated]);

  if (!championshipTeams && !teams?.teams) {
    return null;
  }

  const onTeamChange = (list: any) => {
    setCheckedTeamList(list);
    setIndeterminateTeam(!!list.length && list.length < teamList.length);
    setCheckAllTeams(list.length === teamList.length);
  };

  const onCheckAllTeamsChange = (e: any) => {
    setCheckedTeamList(e.target.checked ? getAllTeamsValues(teamList) : []);
    setIndeterminateTeam(false);
    setCheckAllTeams(e.target.checked);
  };

  const onTeamGroupChange = (list: any) => {
    setCheckedTeamGroupList(list);
    setIndeterminateTeamGroup(!!list.length && list.length < teamGroupList.length);
    setCheckAllTeamsGroup(list.length === teamGroupList.length);
  };

  const onCheckAllTeamsGroupChange = (e: any) => {
    setCheckedTeamGroupList(e.target.checked ? getAllTeamsValues(teamGroupList) : []);
    setIndeterminateTeamGroup(false);
    setCheckAllTeamsGroup(e.target.checked);
  };

  const setInitListsValues = () => {
    setTeamList(teamsToOptions([...(teams?.teams.edges as any)]));
    setTeamGroupList(entityTeamToOptions([...(championshipTeams as any)]));
  };

  /**
   * By setting an origin and target list, the selected teams are transfered from one list to the other
   * @param isGroup if true, select function for group list. if false, select function for teams list
   */
  const setTeamsToNewList = (isGroup: boolean = false) => {
    const { teams, teamsGroup } = isGroup
      ? setTeamsGroup(teamGroupList, checkedTeamGroupList, teamList)
      : setTeams(teamList, checkedTeamList, teamGroupList);

    setTeamList(teams);
    setTeamGroupList(teamsGroup);

    resetListsAndChecks();
  };

  /**
   * reset all selection of checkboxes
   */
  const resetListsAndChecks = () => {
    setCheckedTeamList([]);
    setCheckedTeamGroupList([]);
    setCheckAllTeams(false);
    setCheckAllTeamsGroup(false);
  };

  const onFinish = () => {
    createChampionshipTeams({
      variables: {
        championshipUuid: uuid,
        teamUuids: getAllTeamsValues(teamGroupList),
      },
    });
  };

  /**
   * Discard all selection of checkboxes, and reset the list of teams to the original state
   */
  const onReset = () => {
    resetListsAndChecks();
    setInitListsValues();
  };

  return (
    <Form onFinish={onFinish} onReset={onReset}>
      <Header uuid={uuid} loading={isCreating} />

      <Container>
        <TeamsArea>
          <TeamsAreaHeader>
            <span>
              <Checkbox
                indeterminate={indeterminateTeam}
                onChange={onCheckAllTeamsChange}
                checked={checkAllTeams}
              >
                {`${checkedTeamList ? checkedTeamList?.length : '0'}/${teamList.length}`}
              </Checkbox>
            </span>
            <span>{t(`${I18N_PREFIX}.teams`)}</span>
          </TeamsAreaHeader>
          <TeamsList options={teamList} value={checkedTeamList} onChange={onTeamChange} />
        </TeamsArea>
        <ButtonWrapper>
          <Button
            type="primary"
            shape="circle"
            icon={<IconAngleRight width="11" height="8" fill="white" />}
            onClick={() => setTeamsToNewList()}
            disabled={checkedTeamList && checkedTeamList.length === 0}
          />
          <Button
            type="primary"
            shape="circle"
            icon={<IconAngleLeft width="11" height="8" fill="white" />}
            onClick={() => setTeamsToNewList(true)}
            disabled={checkedTeamGroupList && checkedTeamGroupList.length === 0}
          />
        </ButtonWrapper>
        <TeamsArea>
          <TeamsAreaHeader>
            <span>
              <Checkbox
                indeterminate={indeterminateTeamGroup}
                onChange={onCheckAllTeamsGroupChange}
                checked={checkAllTeamsGroup}
              >
                {`${checkedTeamGroupList ? checkedTeamGroupList?.length : '0'}/${
                  teamGroupList.length
                }`}
              </Checkbox>
            </span>
            <span>{t(`${I18N_PREFIX}.teamsGroup`)}</span>
          </TeamsAreaHeader>
          <TeamsList
            options={teamGroupList}
            value={checkedTeamGroupList}
            onChange={onTeamGroupChange}
          />
        </TeamsArea>
      </Container>
    </Form>
  );
};

// array com todos os jogadores da bd
// array com a copia dos jogadores, para primeira coluna
