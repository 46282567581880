import { gql } from '@apollo/client';

export const GetChampionshipSports = gql`
  query {
    championshipSports {
      edges {
        uuid
        name
      }
    }
  }
`;
