import CheckList from '@editorjs/checklist';
import Delimiter from '@editorjs/delimiter';
import Header from '@editorjs/header';
import LinkTool from '@editorjs/link';
import List from '@editorjs/list';
import Marker from '@editorjs/marker';
import Quote from '@editorjs/quote';
import Table from '@editorjs/table';

import Gallery from './custom/gallery';
import Image from './custom/image';
import Video from './custom/video';

// import Code from '@editorjs/code';
// import Embed from '@editorjs/embed';
// import Image from '@editorjs/image';
// import InlineCode from '@editorjs/inline-code';
// import Raw from '@editorjs/raw';
// import SimpleImage from '@editorjs/simple-image';
// import Warning from '@editorjs/warning';

export const EDITOR_JS_TOOLS = {
  checklist: CheckList,
  delimiter: Delimiter,
  header: Header,
  linkTool: LinkTool,
  list: List,
  marker: Marker,
  quote: Quote,
  table: Table,
  video: Video as any,
  image: Image as any,
  gallery: Gallery as any,
  //code: Code,
  //embed: Embed,
  // image: Image,
  //inlineCode: InlineCode,
  //raw: Raw,
  //simpleImage: SimpleImage,
  //warning: Warning,
};

export const EDITOR_JS_TOOLS_VIDEO = {
  video: Video as any,
};

export const EDITOR_JS_TOOLS_GALLERY = {
  gallery: Gallery as any,
};

export const EDITOR_JS_TOOLS_MEDIA = {
  video: Video as any,
  image: Image as any,
};
