import { UPLOAD_ENDPOINT } from 'config/api';
import { useState } from 'react';

interface UploadProps {
  isUploadFileLoading?: boolean;
  uploadFile: (file: any) => void;
  uploadFileData?: any;
  uploadFileError?: any;
}

export function useUploadFile(): UploadProps {
  const [isUploadFileLoading, setIsUploadFileLoading] = useState<boolean>(false);
  const [uploadFileData, setUploadFileData] = useState<any>(null);

  const uploadFile = (file: any) => {
    setIsUploadFileLoading(true);

    const formdata = new FormData();
    formdata.append('file', file);

    const headers = new Headers();
    headers.append('Authorization', `Bearer ${localStorage.getItem('token')}`);

    const requestOptions = {
      method: 'POST',
      headers: headers,
      body: formdata,
    };
    fetch(UPLOAD_ENDPOINT, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setIsUploadFileLoading(false);
        setUploadFileData(data);
      })
      .catch((error) => {
        setIsUploadFileLoading(false);
        console.log(error);
      });
  };

  return {
    isUploadFileLoading,
    uploadFileData,
    uploadFile,
  };
}
