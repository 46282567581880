import { useLazyQuery, useMutation } from '@apollo/client';
import { Form, Checkbox, Button } from 'antd';
import { ALL_ITEMS_ON_PAGE } from 'config/main';
import { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { Header } from './header';
import {
  entityPlayerToOptions,
  getAllPlayersValues,
  playerToOptions,
  setPlayers,
  setPlayersGroup,
} from './utils';

import { IconAngleLeft } from 'assets/icons/iconAngleLeft';
import { IconAngleRight } from 'assets/icons/iconAngleRight';

import {
  CreateChampionshipEntityPlayers,
  GetChampionshipEntityPlayers,
} from 'queries/championshipEntityPlayers';
import { GetPlayers } from 'queries/sportsPlayers';
import {
  Mutation,
  MutationCreateChampionshipEntityPlayersArgs,
  Player,
  Query,
  QueryChampionshipEntityPlayersArgs,
  QueryPlayerArgs,
  QueryPlayersArgs,
} from 'queries/types';

const CheckboxGroup = Checkbox.Group;

const Container = styled.div`
  background: white;
  border: 1px solid #edf2f9;
  border-radius: 10px;
  padding: 50px;
  display: flex;
  justify-content: center;
  align-items: stretch;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  margin: 0 10px;

  button {
    margin: 2px 0;
  }
`;

const PlayersArea = styled.div`
  background: #ffffff;
  border: 1px solid #edf2f9;
  border-radius: 10px;
  padding: 8px 12px;
  width: 350px;
`;

const PlayersAreaHeader = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eef2f8;
  padding-bottom: 8px;
`;

const PlayersList = styled(CheckboxGroup)`
  display: flex;
  flex-flow: column;
  padding: 8px 0;

  label {
    padding: 12px 0;
  }
`;

const I18N_PREFIX = 'sportChampionshipGroup';

export const SportsChampionshipTeam = () => {
  const { t } = useTranslation();
  const { championshipTeam, team } = useParams<{
    championship: string;
    championshipTeam: string;
    team: string;
  }>();

  const [playerList, setPlayerList] = useState<any>([]);
  const [checkedPlayerList, setCheckedPlayerList] = useState<any>([]);
  const [indeterminatePlayer, setIndeterminatePlayer] = useState(true);
  const [checkAllPlayers, setCheckAllPlayers] = useState(false);

  const [playerGroupList, setPlayerGroupList] = useState<any>([]);
  const [checkedPlayerGroupList, setCheckedPlayerGroupList] = useState<any>([]);
  const [indeterminatePlayerGroup, setIndeterminatePlayerGroup] = useState(true);
  const [checkAllPlayersGroup, setCheckAllPlayersGroup] = useState(false);

  const [getEntityPlayers, { data: entityPlayers }] = useLazyQuery<
    Query,
    QueryChampionshipEntityPlayersArgs
  >(GetChampionshipEntityPlayers, {
    variables: {
      championshipEntityUuid: championshipTeam,
      pagination: {
        page: 1,
        take: ALL_ITEMS_ON_PAGE,
      },
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  useEffect(() => {
    getEntityPlayers();
  }, []);

  const [getPlayers, { data: players }] = useLazyQuery<Query, QueryPlayersArgs>(GetPlayers, {
    variables: {
      filter: {
        teams: [team],
      },
      pagination: {
        page: 1,
        take: ALL_ITEMS_ON_PAGE,
      },
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  useEffect(() => {
    getPlayers();
  }, []);

  useEffect(() => {
    if (players?.players && entityPlayers?.championshipEntityPlayers) {
      setInitListsValues();
    }
  }, [players, entityPlayers]);

  const [createChampionshipEntityPlayers, { loading: isCreating, data: isCreated }] = useMutation<
    Mutation,
    MutationCreateChampionshipEntityPlayersArgs
  >(CreateChampionshipEntityPlayers);

  useEffect(() => {
    if (isCreated) {
      window.location.reload();
    }
  }, [isCreated]);

  if (!entityPlayers?.players && !players?.players) {
    return null;
  }

  const onPlayerChange = (list: any) => {
    setCheckedPlayerList(list);
    setIndeterminatePlayer(!!list.length && list.length < playerList.length);
    setCheckAllPlayers(list.length === playerList.length);
  };

  const onCheckAllPlayersChange = (e: any) => {
    setCheckedPlayerList(e.target.checked ? getAllPlayersValues(playerList) : []);
    setIndeterminatePlayer(false);
    setCheckAllPlayers(e.target.checked);
  };

  const onPlayerGroupChange = (list: any) => {
    setCheckedPlayerGroupList(list);
    setIndeterminatePlayerGroup(!!list.length && list.length < playerGroupList.length);
    setCheckAllPlayersGroup(list.length === playerGroupList.length);
  };

  const onCheckAllPlayersGroupChange = (e: any) => {
    setCheckedPlayerGroupList(e.target.checked ? getAllPlayersValues(playerGroupList) : []);
    setIndeterminatePlayerGroup(false);
    setCheckAllPlayersGroup(e.target.checked);
  };

  const setInitListsValues = () => {
    setPlayerList(playerToOptions([...(players?.players.edges as any)]));
    setPlayerGroupList(
      entityPlayerToOptions([...(entityPlayers?.championshipEntityPlayers.edges as any)])
    );
  };

  /**
   * By setting an origin and target list, the selected players are transfered from one list to the other
   * @param isGroup if true, select function for group list. if false, select function for players list
   */
  const setPlayersToNewList = (isGroup: boolean = false) => {
    const { players, playersGroup } = isGroup
      ? setPlayersGroup(playerGroupList, checkedPlayerGroupList, playerList)
      : setPlayers(playerList, checkedPlayerList, playerGroupList);

    setPlayerList(players);
    setPlayerGroupList(playersGroup);

    resetListsAndChecks();
  };

  /**
   * reset all selection of checkboxes
   */
  const resetListsAndChecks = () => {
    setCheckedPlayerList([]);
    setCheckedPlayerGroupList([]);
    setCheckAllPlayers(false);
    setCheckAllPlayersGroup(false);
  };

  const onFinish = () => {
    createChampionshipEntityPlayers({
      variables: {
        championshipEntityUuid: championshipTeam,
        playerUuids: getAllPlayersValues(playerGroupList),
      },
    });
  };

  /**
   * Discard all selection of checkboxes, and reset the list of players to the original state
   */
  const onReset = () => {
    resetListsAndChecks();
    setInitListsValues();
  };

  return (
    <Form onFinish={onFinish} onReset={onReset}>
      <Header name={'Editar Equipa'} loading={isCreating} />
      <Container>
        <PlayersArea>
          <PlayersAreaHeader>
            <span>
              <Checkbox
                indeterminate={indeterminatePlayer}
                onChange={onCheckAllPlayersChange}
                checked={checkAllPlayers}
              >
                {`${checkedPlayerList ? checkedPlayerList?.length : '0'}/${playerList.length}`}
              </Checkbox>
            </span>
            <span>{t(`${I18N_PREFIX}.players`)}</span>
          </PlayersAreaHeader>
          <PlayersList options={playerList} value={checkedPlayerList} onChange={onPlayerChange} />
        </PlayersArea>
        <ButtonWrapper>
          <Button
            type="primary"
            shape="circle"
            icon={<IconAngleRight width="11" height="8" fill="white" />}
            onClick={() => setPlayersToNewList()}
            disabled={checkedPlayerList && checkedPlayerList.length === 0}
          />
          <Button
            type="primary"
            shape="circle"
            icon={<IconAngleLeft width="11" height="8" fill="white" />}
            onClick={() => setPlayersToNewList(true)}
            disabled={checkedPlayerGroupList && checkedPlayerGroupList.length === 0}
          />
        </ButtonWrapper>
        <PlayersArea>
          <PlayersAreaHeader>
            <span>
              <Checkbox
                indeterminate={indeterminatePlayerGroup}
                onChange={onCheckAllPlayersGroupChange}
                checked={checkAllPlayersGroup}
              >
                {`${checkedPlayerGroupList ? checkedPlayerGroupList?.length : '0'}/${
                  playerGroupList.length
                }`}
              </Checkbox>
            </span>
            <span>{t(`${I18N_PREFIX}.playersGroup`)}</span>
          </PlayersAreaHeader>
          <PlayersList
            options={playerGroupList}
            value={checkedPlayerGroupList}
            onChange={onPlayerGroupChange}
          />
        </PlayersArea>
      </Container>
    </Form>
  );
};

// array com todos os jogadores da bd
// array com a copia dos jogadores, para primeira coluna
