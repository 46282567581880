import { ColorGrey01 } from 'config/main';

import { IconProps } from './type';

export const IconAngleLeft = ({ width, height, className, fill }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 8 11"
      fill={fill || ColorGrey01}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.75 10.6398L7.75 9.60362C7.75 9.53316 7.71338 9.46684 7.65332 9.42539L1.91699 5.50026L7.65332 1.57513C7.71338 1.53368 7.75 1.46737 7.75 1.3969L7.75 0.360703C7.75 0.270899 7.6416 0.218398 7.56396 0.270899L0.443359 5.14243C0.185546 5.31927 0.185546 5.68125 0.443359 5.85671L7.56396 10.7282C7.6416 10.7821 7.75 10.7296 7.75 10.6398Z"
        fill={fill || ColorGrey01}
      />
    </svg>
  );
};
