import { ChampionshipGroup, InputChampionshipGroup } from 'queries/types';

export const transformFormValues = (values: any): InputChampionshipGroup => {
  return {
    name: { pt: values.name },
    championshipStage: values.championshipStage,
  };
};

export const transformInitialValues = (stage: string, data?: ChampionshipGroup): any => {
  if (data) {
    const { name } = data;

    return {
      name: name.pt,
      championshipStage: stage,
    };
  }

  return {
    championshipStage: stage,
  };
};
