import { useAuthState } from 'context/AuthContext';
import { ComponentType } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

interface PrivateRouteProps extends RouteProps {
  component: ComponentType<RouteProps>;
}

const PrivateRoute = (props: PrivateRouteProps) => {
  const { isLoggedIn } = useAuthState();
  const { component: Component, ...rest } = props;

  return (
    <Route
      {...rest}
      render={(props) => (isLoggedIn ? <Component {...props} /> : <Redirect to="/login" />)}
    />
  );
};

export default PrivateRoute;
