import { useLazyQuery } from '@apollo/client';
import { Input, Row, Col, Card, Form, FormInstance, Select } from 'antd';
import { ALL_ITEMS_ON_PAGE } from 'config/main';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { GetChampionshipSports } from 'queries/sportsChampionshipSports';
import { ChampionshipSport, Query, QueryChampionshipSportsArgs } from 'queries/types';

export enum FormMode {
  ADD = 'add',
  EDIT = 'edit',
}

type FormProps = {
  form: FormInstance;
  mode: FormMode;
  props: any;
};

export const SportsPlayersForm = ({ form, mode }: FormProps) => {
  const { t } = useTranslation();

  const I18N_PREFIX = 'sportsPlayersForm';
  const I18N_PREFIX_FIELDS = `${I18N_PREFIX}.fields`;
  const I18N_PREFIX_SECTIONS = `${I18N_PREFIX}.sections`;

  const [getChampionshipSports, { data: championshipSportsData }] = useLazyQuery<
    Query,
    QueryChampionshipSportsArgs
  >(GetChampionshipSports, {
    variables: {
      pagination: {
        page: 1,
        take: ALL_ITEMS_ON_PAGE,
      },
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  useEffect(() => {
    getChampionshipSports();
  }, [getChampionshipSports]);

  const championshipSportsSelect = championshipSportsData?.championshipSports.edges.map(
    (sport: any) => {
      return { label: sport.name.pt, value: sport.uuid };
    }
  );

  return (
    <Row gutter={16}>
      <Col xs={24} sm={24} md={17}>
        <Card title={t(`${I18N_PREFIX_SECTIONS}.basicInfo`)}>
          <Form.Item
            name="firstName"
            label={t(`${I18N_PREFIX_FIELDS}.firstName.field`)}
            rules={[{ required: true, message: t(`${I18N_PREFIX_FIELDS}.firstName.errorMessage`) }]}
          >
            <Input placeholder={t(`${I18N_PREFIX_FIELDS}.firstName.placeholder`)} />
          </Form.Item>
          <Form.Item
            name="lastName"
            label={t(`${I18N_PREFIX_FIELDS}.lastName.field`)}
            rules={[{ required: true, message: t(`${I18N_PREFIX_FIELDS}.lastName.errorMessage`) }]}
          >
            <Input placeholder={t(`${I18N_PREFIX_FIELDS}.lastName.placeholder`)} />
          </Form.Item>
          <Form.Item
            name="identification"
            label={t(`${I18N_PREFIX_FIELDS}.identification.field`)}
            rules={[
              { required: true, message: t(`${I18N_PREFIX_FIELDS}.identification.errorMessage`) },
            ]}
          >
            <Input placeholder={t(`${I18N_PREFIX_FIELDS}.identification.placeholder`)} />
          </Form.Item>
          {championshipSportsSelect && (
            <Form.Item
              name="championshipSports"
              label={t(`${I18N_PREFIX_FIELDS}.championshipSports.field`)}
            >
              <Select
                className="w-100"
                placeholder={t(`${I18N_PREFIX_FIELDS}.championshipSports.placeholder`)}
                defaultValue={''}
                options={[
                  { label: t(`${I18N_PREFIX_FIELDS}.championshipSports.placeholder`), value: '' },
                  ...championshipSportsSelect,
                ]}
              />
            </Form.Item>
          )}
        </Card>
      </Col>
    </Row>
  );
};
