import { getConfig } from './env';

export const API_ENDPOINT = getConfig().apiEndpoint;
export const UPLOAD_ENDPOINT = getConfig().uploadEndpoint;

/**
 * @returns string Returns the auth endpoint in string format
 */
export function getAuthEndpoint(): string {
  const { userFlowLogin, clientId, redirectUri, authEndpoint } = getConfig();

  const queryParams = new URLSearchParams();
  queryParams.set('p', userFlowLogin);
  queryParams.set('client_id', clientId);
  queryParams.set('redirect_uri', redirectUri);
  queryParams.set('scope', 'openid');
  queryParams.set('prompt', 'login');
  queryParams.set('nonce', 'defaultNonce');
  queryParams.set('response_type', 'id_token');
  queryParams.set('response_mode', 'query');

  return `${authEndpoint}?${queryParams.toString()}`;
}

/**
 * @returns string Returns the reset password endpoint in string format
 */
export function getResetPasswordEndpoint(): string {
  const { userFlowResetPassword, clientId, redirectUri, authEndpoint } = getConfig();

  const queryParams = new URLSearchParams();
  queryParams.set('p', userFlowResetPassword);
  queryParams.set('client_id', clientId);
  queryParams.set('redirect_uri', redirectUri);
  queryParams.set('scope', 'openid');
  queryParams.set('prompt', 'login');
  queryParams.set('nonce', 'defaultNonce');
  queryParams.set('response_type', 'id_token');
  queryParams.set('response_mode', 'query');

  return `${authEndpoint}?${queryParams.toString()}`;
}
