export const ITEMS_PER_PAGE: number = 20;
export const ALL_ITEMS_ON_PAGE: number = 999;
export const MODAL_ITEMS_PER_PAGE = 10;
export const ADS_PER_PAGE = 60;

/**
 * COLORS
 */
export const ColorMain: string = '#9845fd';
export const ColorGrey01: string = '#48555f';
export const ColorGrey02: string = '#eef2f8';
export const ColorGrey03: string = '#f7f7f8';
export const ColorGrey04: string = '#9fa8b7';
export const ColorGrey05: string = '#FAFAFA';
export const ColorBlack: string = '#000';
export const ColorBlack01: string = '#171a23';
export const ColorSuccess: string = '#24b574';
export const ColorError: string = '#dd4436';
export const ColorWarning: string = '#fa8b17';
export const ColorWhite: string = '#fff';
