import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'routes/config';
import styled from 'styled-components';

import { PageHeader } from 'components/pageHeader';

const StyledPageHeader = styled(PageHeader)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: 25px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

interface Props {
  uuid?: string;
  name?: string;
  loading?: boolean;
}

export const Header = ({ uuid, name, loading }: Props) => {
  const { t } = useTranslation();

  const I18N_PREFIX = 'sportsChampionship';

  const routes = [
    {
      path: ROUTES.SportsChampionships,
      breadcrumbName: t('mainMenu.sports.championships'),
    },
    {
      path: ROUTES.SportsChampionships,
      breadcrumbName: t(`${I18N_PREFIX}.sections.competitions`),
    },
  ];

  return (
    <StyledPageHeader
      title={t(`${uuid && name ? name : `${I18N_PREFIX}.header.create`}`)}
      breadcrumbs={{ routes, separator: '/' }}
      whiteBackground={!!uuid}
    >
      {/* <ButtonsWrapper>
        <Button type="primary" htmlType="submit" loading={loading}>
          {t(`${I18N_PREFIX}.submit`)}
        </Button>
      </ButtonsWrapper> */}
    </StyledPageHeader>
  );
};
