import { FeedPost, FeedPostOrigin, FeedPostType } from 'queries/types';

export const getPostType = (post: { origin: FeedPostOrigin; type: FeedPostType }) => {
  if (
    post.origin === FeedPostOrigin.Facebook ||
    post.origin === FeedPostOrigin.Instagram ||
    post.origin === FeedPostOrigin.Pub
  ) {
    return post.origin;
  }

  return post.type;
};

export const sortByPosition = (a: FeedPost, b: FeedPost) => {
  if (a.highlight.position < b.highlight.position) {
    return -1;
  }

  if (a.highlight.position > b.highlight.position) {
    return 1;
  }

  return 0;
};
