import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Form } from 'antd';
import { ALL_ITEMS_ON_PAGE } from 'config/main';
import { Store } from 'rc-field-form/lib/interface';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { getCreatePage, ROUTES } from 'routes/config';
import styled from 'styled-components';

import { FormMode, PressForm } from './pressForm';
import {
  capitalizeString,
  getCustomTags,
  transformFormValues,
  transformInitialValues,
} from './utils';

import { PageHeader } from 'components/pageHeader';
import { RemoveModal } from 'components/removeModal';
import { SubmitButton } from 'components/submitButton';

import { IconDelete } from 'assets/icons/iconDelete';

import { GetAuthors } from 'queries/authors';
import { GetCategoriesListQuery } from 'queries/categoriesList';
import { GetFeedPost } from 'queries/feedPost';
import { CreateFeedPost, UpdateFeedPost, DeleteFeedPost, CreateTags } from 'queries/pressMutations';
import { GetTags } from 'queries/tags';
import {
  FeedPostType,
  Mutation,
  MutationCreateFeedPostArgs,
  MutationUpdateFeedPostArgs,
  MutationDeleteFeedPostArgs,
  Query,
  QueryCategoriesArgs,
  QueryFeedPostArgs,
  User,
  MutationCreateTagsArgs,
} from 'queries/types';

const StyledPageHeader = styled(PageHeader)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const I18N_PREFIX = 'pressForm';

export const PressCreateEdit = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const published: React.MutableRefObject<boolean> = React.useRef(true);
  const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);

  const { uuid, section } = useParams<{ uuid: string; section: string }>();

  const feedPostType = FeedPostType[capitalizeString(section) as keyof typeof FeedPostType];
  const sectionRouteCapitalized = `Press${capitalizeString(section)}` as keyof typeof ROUTES;

  const routes = [
    {
      path: ROUTES.PressFeed,
      breadcrumbName: t('mainMenu.content.press'),
    },
    {
      path: ROUTES[sectionRouteCapitalized],
      breadcrumbName: t(`tabsMenu.press.${section}`),
    },
    {
      path: getCreatePage(section),
      breadcrumbName: t(`${I18N_PREFIX}.header.${section}.${uuid ? 'edit' : 'create'}`),
    },
  ];

  const [getFeedPost, { data: feedPost }] = useLazyQuery<Query, QueryFeedPostArgs>(GetFeedPost, {
    variables: {
      uuid,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (uuid) getFeedPost();
  }, [uuid]);

  const [form] = Form.useForm();

  const [createFeedPost, { loading: isCreating, data: isCreated }] = useMutation<
    Mutation,
    MutationCreateFeedPostArgs
  >(CreateFeedPost);
  const [updateFeedPost, { loading: isSaving, data: isUpdated }] = useMutation<
    Mutation,
    MutationUpdateFeedPostArgs
  >(UpdateFeedPost);
  const [deleteFeedPost, { loading: isRemoving, data: isRemoved }] = useMutation<
    Mutation,
    MutationDeleteFeedPostArgs
  >(DeleteFeedPost);

  useEffect(() => {
    if (isUpdated) window.location.reload();
  }, [isUpdated]);

  useEffect(() => {
    if (isRemoved) history.push(ROUTES[sectionRouteCapitalized]);
  }, [isRemoved]);

  useEffect(() => {
    if (isCreated) {
      history.push(
        `${ROUTES[sectionRouteCapitalized]}${ROUTES.Edit}/${isCreated?.createFeedPost.uuid}`
      );
      window.location.reload();
    }
  }, [isCreated]);

  const saveAsDraft = () => {
    published.current = false;
    form.submit();
  };

  const onFinish = async (values: any) => {
    if (!tagsCreated) {
      const customTags = getCustomTags(values.tags, tags?.tags);

      if (customTags.new.length > 0) {
        createTags({
          variables: {
            input: customTags.new,
          },
        });

        return;
      }
    }

    console.log('Success:', {
      values: values,
      transformed: transformFormValues(
        feedPostType,
        values,
        published.current,
        tagsCreated?.createTags,
        tags?.tags
      ),
    });

    if (uuid) {
      updateFeedPost({
        variables: {
          uuid,
          input: transformFormValues(
            feedPostType,
            values,
            published.current,
            tagsCreated?.createTags,
            tags?.tags
          ),
        },
      });
    } else {
      //
      createFeedPost({
        variables: {
          input: transformFormValues(
            feedPostType,
            values,
            published.current,
            tagsCreated?.createTags,
            tags?.tags
          ),
        },
      });
    }

    published.current = true;
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  /**
   * Categories section
   */
  const [getFeedCategories, { data: categories }] = useLazyQuery<Query, QueryCategoriesArgs>(
    GetCategoriesListQuery,
    {
      variables: {
        pagination: {
          take: ALL_ITEMS_ON_PAGE,
        },
      },
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only',
    }
  );
  useEffect(() => {
    getFeedCategories();
  }, [getFeedCategories]);

  /**
   * Authors section
   */
  const [getAuthors, { data: authors }] = useLazyQuery<Query, {}>(GetAuthors, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });
  useEffect(() => {
    getAuthors();
  }, [getAuthors]);

  /**
   * Tags Section
   */

  const [getTags, { data: tags }] = useLazyQuery<Query, {}>(GetTags, {});
  useEffect(() => {
    getTags();
  }, [getTags]);

  const [createTags, { data: tagsCreated }] = useMutation<Mutation, MutationCreateTagsArgs>(
    CreateTags
  );
  useEffect(() => {
    if (tagsCreated?.createTags) {
      form.submit();
    }
  }, [tagsCreated]);

  if (uuid && !feedPost?.feedPost) return null;

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        name="press-form"
        className="press-create-edit-form"
        initialValues={
          feedPost?.feedPost ? (transformInitialValues(feedPost.feedPost) as Store) : undefined
        }
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <StyledPageHeader
          title={t(`${I18N_PREFIX}.header.${section}.${uuid ? 'edit' : 'create'}`)}
          breadcrumbs={{ routes, separator: '/' }}
        >
          <ButtonsWrapper>
            {uuid && (
              <Button
                className="mr-2"
                icon={<IconDelete width="14" height="14" className={'anticon'} />}
                htmlType="reset"
                onClick={() => setShowRemoveModal(true)}
              >
                {t(`${I18N_PREFIX}.reset`)}
              </Button>
            )}
            <Button className="mr-2" onClick={saveAsDraft} loading={isCreating || isSaving}>
              {t(`${I18N_PREFIX}.draft`)}
            </Button>
            <SubmitButton isLoading={isCreating || isSaving} label={t(`${I18N_PREFIX}.submit`)} />
          </ButtonsWrapper>
        </StyledPageHeader>
        <PressForm
          mode={uuid ? FormMode.EDIT : FormMode.ADD}
          categories={categories?.categories}
          authors={authors?.authors as [User]}
          tags={tags?.tags}
          form={form}
          feedPostType={feedPostType}
          props
        />
      </Form>
      <RemoveModal
        title={t('modals.pressRemoveModal.title')}
        message={t('modals.pressRemoveModal.message')}
        isLoading={isRemoving}
        showModal={showRemoveModal}
        onClose={() => setShowRemoveModal(false)}
        onRemoveConfirm={() =>
          deleteFeedPost({
            variables: {
              uuid,
            },
          })
        }
      />
    </>
  );
};
