import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Modal, Table } from 'antd';
import { ALL_ITEMS_ON_PAGE, ITEMS_PER_PAGE } from 'config/main';
import { getTablePagination } from 'helpers/tablePagination';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { SearchField } from 'components/searchField';

import { GetChampionship } from 'queries/sportsChampionship';
import { CreateChampionshipEntities } from 'queries/sportsChampionshipGroup';
import { GetPlayers } from 'queries/sportsPlayers';
import { AddTeamToPlayer } from 'queries/sportsPlayers';
import { GetTeams } from 'queries/sportsTeamsList';
import { GetSportsTeam } from 'queries/sportsTeamsMutations';
import {
  ChampionshipTeam,
  Mutation,
  MutationAddTeamToPlayerArgs,
  MutationCreateChampionshipEntitiesArgs,
  Pagination,
  Player,
  Query,
  QueryChampionshipArgs,
  QueryPlayersArgs,
  Team,
} from 'queries/types';

interface Props {
  isLoading: boolean;
  showModal: boolean;
  onClose: () => void;
  group: string;
  refetch: any;
}

export const SportsChampionshipGroupTeamsModal = ({
  isLoading,
  showModal,
  onClose,
  group,
  refetch,
}: Props) => {
  const { t } = useTranslation();

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const { uuid: championshipId } = useParams<{
    uuid: string;
    group: string;
  }>();

  const [getChampionship, { data: championship }] = useLazyQuery<Query, QueryChampionshipArgs>(
    GetChampionship,
    {
      variables: {
        uuid: championshipId,
      },
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    if (championshipId) getChampionship();
  }, []);

  // const variables: QueryPlayersArgs = {
  //   filter: {
  //     query: searchQuery,
  //   },
  //   pagination: {
  //     page: selectedPage,
  //     take: ITEMS_PER_PAGE,
  //   },
  // };

  // const [getTeams, { data }] = useLazyQuery<Query, QueryPlayersArgs>(GetTeams, {
  //   variables,
  //   fetchPolicy: 'network-only',
  //   nextFetchPolicy: 'network-only',
  // });

  // useEffect(() => {
  //   getTeams();
  // }, []);

  const [addTeamsToGroup, { data: isAdded, loading: isAdding }] = useMutation<
    Mutation,
    MutationCreateChampionshipEntitiesArgs
  >(CreateChampionshipEntities, refetch);

  useEffect(() => {
    if (isAdded) handleCancel();
  }, [isAdded]);

  /**
   * Handles confirm button click
   * Calls onRemoveConfirm method that fires the mutation
   */
  const handleOk = () => {
    addTeamsToGroup({
      variables: {
        championshipGroupUuid: group,
        championshipTeamUuids: selectedRowKeys,
      },
    });
  };

  /**
   * Handles modal close button click
   * Closes the modal without any mutation happening
   */
  const handleCancel = useCallback(() => {
    setSelectedRowKeys([]);
    onClose();
  }, [onClose]);

  // const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const value = e.currentTarget.value;
  //   setSearchQuery(value);
  //
  // reset pagination
  // setSelectedPage(1);
  // };

  const tableColumns = [
    {
      title: '',
      dataIndex: 'team',
      render: (team: Team) => <span>{team.name}</span>,
    },
    {
      title: '',
    },
  ];

  const list = (championship?.championship.championshipTeams as ChampionshipTeam[]) || [];

  return (
    <Modal
      title={t('sportsChampionshipGroupForm.addTeam.title')}
      visible={showModal}
      onCancel={handleCancel}
      centered={true}
      footer={[
        <Button key="reset" loading={isAdding} onClick={handleCancel}>
          {t('actions.cancel')}
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={isAdding}
          onClick={handleOk}
          disabled={selectedRowKeys.length === 0}
        >
          {t('actions.confirm')}
        </Button>,
      ]}
    >
      {/* <SearchField onChange={onSearch} /> */}
      <Table
        columns={tableColumns}
        dataSource={list}
        rowKey="uuid"
        pagination={false}
        rowSelection={{
          selectedRowKeys: selectedRowKeys,
          type: 'radio',
          preserveSelectedRowKeys: false,
          onChange: (selectedKeys: any) => {
            setSelectedRowKeys(selectedKeys);
          },
        }}
      />
    </Modal>
  );
};
