import { useMutation } from '@apollo/client';
import { DatePicker, Form, Input, Select } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import styled from 'styled-components';

import { ChampionshipMatchForm } from './championshipMatchForm';

import { SubmitButton } from 'components/submitButton';

import { UpdateChampionshipMatch } from 'queries/sportsChampionshipMatch';
import { ChampionshipMatch, Mutation, MutationUpdateChampionshipMatchArgs } from 'queries/types';

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  button {
    width: 100%;
    justify-content: center;
  }
`;

const I18N_PREFIX = 'sportsMatch.edit';

interface Props {
  championshipMatch: ChampionshipMatch;
  refetch: any;
}

export const MatchDetails = ({ championshipMatch, refetch }: Props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const { uuid } = useParams<{ uuid: string }>();

  const [updateChampionshipMatch, { loading: isUpdating }] = useMutation<
    Mutation,
    MutationUpdateChampionshipMatchArgs
  >(UpdateChampionshipMatch, refetch);

  const onFinish = async (values: any) => {
    updateChampionshipMatch({
      variables: {
        uuid,
        input: {
          datetime: values.datetime,
          location: {
            pt: values.location,
          },
        },
      },
    }).catch((error) => {
      console.error(error);
    });
  };

  return (
    <Form
      onFinish={onFinish}
      layout="vertical"
      form={form}
      initialValues={{
        datetime: moment(championshipMatch.datetime),
        location: championshipMatch.location.pt,
      }}
    >
      <ChampionshipMatchForm form={form}>
        <ButtonsWrapper>
          <SubmitButton isLoading={isUpdating} label={t(`${I18N_PREFIX}.submit`)} />
        </ButtonsWrapper>
      </ChampionshipMatchForm>
    </Form>
  );
};
