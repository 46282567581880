import { useLazyQuery, useMutation } from '@apollo/client';
import { Form } from 'antd';
import { ALL_ITEMS_ON_PAGE } from 'config/main';
import { Store } from 'rc-field-form/lib/interface';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { ROUTES } from 'routes/config';
import styled from 'styled-components';

import { SportsChampionshipStageForm, FormMode } from './sportsChampionshipStageForm';
import { transformFormValues, transformInitialValues } from './utils';

import { PageHeader } from 'components/pageHeader';
import { SubmitButton } from 'components/submitButton';

import { GetChampionshipCompetitions } from 'queries/sportsChampionshipCompetitions';
import {
  CreateChampionshipStage,
  GetChampionshipStage,
  GetChampionshipStagesConfigGroupPoints,
  UpdateChampionshipStage,
} from 'queries/sportsChampionshipStages';
import {
  ChampionshipCompetition,
  ConfigGroupPoints,
  Mutation,
  MutationCreateChampionshipStageArgs,
  MutationUpdateChampionshipStageArgs,
  Query,
  QueryChampionshipCompetitionsArgs,
  QueryChampionshipStageArgs,
} from 'queries/types';

const StyledPageHeader = styled(PageHeader)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: 25px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const I18N_PREFIX = 'sportsChampionshipStageForm';

export const SportsChampionshipStageCreateEdit = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const history = useHistory();

  const { uuid, competition, stage } = useParams<{
    uuid: string;
    competition: string;
    stage: string;
  }>();

  const [createChampionshipStage, { loading: isCreating, data: isCreated }] = useMutation<
    Mutation,
    MutationCreateChampionshipStageArgs
  >(CreateChampionshipStage);

  const [updateChampionshipStage, { loading: isUpdating, data: isUpdated }] = useMutation<
    Mutation,
    MutationUpdateChampionshipStageArgs
  >(UpdateChampionshipStage);

  const [getChampionshipStage, { data: championshipStage }] = useLazyQuery<
    Query,
    QueryChampionshipStageArgs
  >(GetChampionshipStage, {
    variables: {
      uuid: stage,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (stage) getChampionshipStage();
  }, []);

  useEffect(() => {
    if (isCreated) {
      history.push(`${ROUTES.SportsChampionships}/${uuid}/${competition}`);
    }
  }, [isCreated]);

  useEffect(() => {
    if (isUpdated) window.location.reload();
  }, [isUpdated]);

  const onFinish = async (values: any) => {
    console.log('Success:', {
      values: values,
      transformed: transformFormValues(values),
    });

    if (stage) {
      updateChampionshipStage({
        variables: {
          uuid: stage,
          input: transformFormValues(values),
        },
      });
    } else {
      createChampionshipStage({
        variables: {
          input: {
            ...transformFormValues(values),
          },
        },
      });
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const [getChampionshipCompetitions, { data: championshipCompetitions }] = useLazyQuery<
    Query,
    QueryChampionshipCompetitionsArgs
  >(GetChampionshipCompetitions, {
    variables: {
      filter: {
        championshipUuid: uuid,
      },
      pagination: {
        page: 1,
        take: ALL_ITEMS_ON_PAGE,
      },
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  useEffect(() => {
    getChampionshipCompetitions();
  }, []);

  const [
    getChampionshipStagesConfigGroupPoints,
    { data: championshipStagesConfigGroupPoints },
  ] = useLazyQuery<Query>(GetChampionshipStagesConfigGroupPoints);

  useEffect(() => {
    getChampionshipStagesConfigGroupPoints();
  }, []);

  if (stage && !championshipStage?.championshipStage) return null;

  return (
    <Form
      layout="vertical"
      form={form}
      name="sports-championship-form"
      className="sports-championship-create-edit-form"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={
        transformInitialValues(competition, championshipStage?.championshipStage) as Store
      }
    >
      <StyledPageHeader
        title={t(stage ? `${I18N_PREFIX}.header.edit` : `${I18N_PREFIX}.header.create`)}
      >
        <ButtonsWrapper>
          <SubmitButton isLoading={isCreating || isUpdating} label={t(`${I18N_PREFIX}.submit`)} />
        </ButtonsWrapper>
      </StyledPageHeader>
      <SportsChampionshipStageForm
        mode={stage ? FormMode.EDIT : FormMode.ADD}
        form={form}
        championshipCompetitions={
          championshipCompetitions?.championshipCompetitions.edges as ChampionshipCompetition[]
        }
        championshipStagesConfigGroupPoints={
          championshipStagesConfigGroupPoints?.championshipStagesConfigGroupPoints as ConfigGroupPoints[]
        }
        props
      />
    </Form>
  );
};
