import { ROUTES } from 'routes/config';

import { FeedPostPage } from 'queries/types';

export const getCreateAdvertisement = (page?: FeedPostPage, position?: number) => {
  return `${ROUTES.Advertisement}${ROUTES.Create}/${page || ':section'}/${
    position || position === 0 ? position : ':position'
  }`;
};

export const getEditAdvertisement = (uuid?: string) => {
  return `${ROUTES.Advertisement}${ROUTES.Edit}/${uuid || ':uuid'}`;
};
