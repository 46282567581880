import { useMutation } from '@apollo/client';
import { Table, Button, Input } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { TeamPlayersAddModal } from './teamPlayersAddModal';

import { ListActionElement, ListActionElementType } from 'components/listActionElement';
import { RemoveModal } from 'components/removeModal';

import { IconDelete } from 'assets/icons/iconDelete';
import { IconPlus } from 'assets/icons/iconPlus';

import {
  DeleteChampionshipMatchEntityPlayer,
  UpdateChampionshipMatchEntityPlayer,
} from 'queries/sportsChampionshipMatchEntityPlayer';
import {
  ChampionshipEntityPlayer,
  ChampionshipMatchEntity,
  ChampionshipMatchEntityPlayer,
  ChampionshipMatchEntityPlayerType,
  Mutation,
  MutationDeleteChampionshipMatchEntityPlayerArgs,
  MutationUpdateChampionshipMatchEntityPlayerArgs,
} from 'queries/types';

const Container = styled.div`
  display: flex;

  & > div {
    width: 50%;
  }
`;

const TeamWrapper = styled.div`
  background: white;
  padding: 20px;
  border: 1px solid #edf2f9;
  border-radius: 10px;

  &:first-of-type {
    margin-right: 8px;
  }

  &:last-of-type {
    margin-left: 8px;
  }
`;

const TeamHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const StyledH4 = styled.h4`
  font-weight: bold;
`;

const PlayerName = styled.span`
  font-weight: bold;
`;

const StyledInput = styled(Input)`
  text-align: center;
  width: 40px;
`;

interface Props {
  teams: ChampionshipMatchEntity[];
  refetch: any;
  loading: boolean;
}

const I18N_PREFIX = 'sportsMatch.sections.teams';

export const Teams = ({ teams, refetch, loading }: Props) => {
  const { t } = useTranslation();

  const [showPlayerRemoveModal, setShowPlayerRemoveModal] = useState<boolean>(false);
  const [playerToRemove, setPlayerToRemove] = useState<string>();
  const [showPlayerAddModal, setShowPlayerAddModal] = useState<boolean>(false);
  const [teamToAdd, setTeamToAdd] = useState<ChampionshipMatchEntity>();

  const [setMatchEntityPlayerSubstitute, { loading: updatingPlayer }] = useMutation<
    Mutation,
    MutationUpdateChampionshipMatchEntityPlayerArgs
  >(UpdateChampionshipMatchEntityPlayer, refetch);

  const [deleteMatchEntityPlayer, { loading: isDeleting, data: isDeleted }] = useMutation<
    Mutation,
    MutationDeleteChampionshipMatchEntityPlayerArgs
  >(DeleteChampionshipMatchEntityPlayer, refetch);

  const setSubstitute = (player: ChampionshipEntityPlayer) => {
    updatePlayer(player, undefined, true);
  };

  const setNumber = (player: ChampionshipEntityPlayer, e: any) => {
    updatePlayer(player, e.target.value);
  };

  const updatePlayer = (
    player: ChampionshipEntityPlayer,
    number?: string,
    substitute?: boolean
  ) => {
    if (player.championshipMatchEntityPlayers && player.championshipMatchEntityPlayers[0]) {
      const playerData = player.championshipMatchEntityPlayers[0];

      setMatchEntityPlayerSubstitute({
        variables: {
          championshipMatchEntityPlayers: [
            {
              uuid: playerData.uuid,
              number: number ? number : playerData.number,
              type: substitute
                ? playerData.type === ChampionshipMatchEntityPlayerType.PlayerSubstitute
                  ? ChampionshipMatchEntityPlayerType.Player
                  : ChampionshipMatchEntityPlayerType.PlayerSubstitute
                : playerData.type,
            },
          ],
        },
      });
    }
  };

  const removePlayer = (uuid: string) => {
    setPlayerToRemove(uuid);
    setShowPlayerRemoveModal(true);
  };

  const closeRemovePlayer = () => {
    setShowPlayerRemoveModal(false);
    setPlayerToRemove('');
  };

  return (
    <Container>
      {teams?.map((team: ChampionshipMatchEntity) => {
        const tableColumns = [
          {
            title: t(`${I18N_PREFIX}.name`),
            dataIndex: 'championshipEntityPlayer',
            render: (championshipEntityPlayer: ChampionshipEntityPlayer) => (
              <PlayerName>
                {championshipEntityPlayer.player.firstName}{' '}
                {championshipEntityPlayer.player.lastName}
              </PlayerName>
            ),
            width: '50%',
          },
          {
            title: t(`${I18N_PREFIX}.identification`),
            dataIndex: 'championshipEntityPlayer',
            render: (championshipEntityPlayer: ChampionshipEntityPlayer) =>
              championshipEntityPlayer.player.identification,
          },
          {
            title: t(`${I18N_PREFIX}.number`),
            dataIndex: 'championshipEntityPlayer',
            render: (championshipEntityPlayer: ChampionshipEntityPlayer) => (
              <StyledInput
                disabled={updatingPlayer || loading}
                defaultValue={
                  (championshipEntityPlayer.championshipMatchEntityPlayers &&
                    championshipEntityPlayer.championshipMatchEntityPlayers[0]?.number) ||
                  ''
                }
                onPressEnter={(e) => setNumber(championshipEntityPlayer, e)}
              />
            ),
          },
          {
            title: t(`${I18N_PREFIX}.isSubstitute`),
            dataIndex: 'championshipEntityPlayer',
            render: (championshipEntityPlayer: ChampionshipEntityPlayer) => (
              <ListActionElement
                type={ListActionElementType.Toggle}
                action={() => setSubstitute(championshipEntityPlayer)}
                disabled={updatingPlayer || loading}
                checked={
                  championshipEntityPlayer.championshipMatchEntityPlayers &&
                  championshipEntityPlayer.championshipMatchEntityPlayers[0]?.type ===
                    ChampionshipMatchEntityPlayerType.PlayerSubstitute
                }
              />
            ),
          },
          {
            title: '',
            dataIndex: 'championshipEntityPlayer',
            render: (championshipEntityPlayer: ChampionshipEntityPlayer) => (
              <Button
                onClick={() =>
                  removePlayer(
                    (championshipEntityPlayer.championshipMatchEntityPlayers &&
                      championshipEntityPlayer.championshipMatchEntityPlayers[0]?.uuid) ||
                      ''
                  )
                }
                icon={<IconDelete width="13" height="13" />}
                shape="circle"
              />
            ),
          },
        ];

        const list = (team.championshipMatchEntityPlayers as ChampionshipMatchEntityPlayer[]) || [];

        return (
          <TeamWrapper>
            <TeamHeader>
              <StyledH4>{team.championshipEntity.championshipTeam.team.name}</StyledH4>
              <Button
                onClick={() => {
                  setTeamToAdd(team);
                  setShowPlayerAddModal(true);
                }}
                type="primary"
                icon={<IconPlus width="15" height="15" />}
                shape="circle"
              />
            </TeamHeader>
            <Table
              columns={tableColumns}
              dataSource={list}
              loading={false}
              rowKey="uuid"
              pagination={false}
            />
            <RemoveModal
              title={t('sportsMatch.sections.teams.remove.title')}
              message={t('sportsMatch.sections.teams.remove.message')}
              showModal={showPlayerRemoveModal}
              isLoading={isDeleting}
              onClose={closeRemovePlayer}
              onRemoveConfirm={() => {
                deleteMatchEntityPlayer({
                  variables: {
                    uuid: playerToRemove,
                  },
                })
                  .catch((e) => console.error(e))
                  .finally(() => {
                    closeRemovePlayer();
                  });
              }}
            />
          </TeamWrapper>
        );
      })}
      {teamToAdd && (
        <TeamPlayersAddModal
          showModal={showPlayerAddModal}
          onClose={() => setShowPlayerAddModal(false)}
          isLoading={false}
          team={teamToAdd}
          refetch={refetch}
        />
      )}
    </Container>
  );
};
