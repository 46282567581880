import { ListActionElementType } from 'components/listActionElement';
import { PostsList } from 'components/postsList';

import {
  FeedPostOrderKeys,
  FeedPostOrigin,
  FeedPostPublishedType,
  OrderDirection,
} from 'queries/types';

export const PressFeed = () => {
  return (
    <PostsList
      origin={[FeedPostOrigin.Internal]}
      published={[FeedPostPublishedType.App]}
      createButton={false}
      order={{ key: FeedPostOrderKeys.PublishedAt, direction: OrderDirection.Desc }}
      actionElementType={ListActionElementType.Button}
    />
  );
};
