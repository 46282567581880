import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Modal, Form, Select, Input, Spin, InputNumber } from 'antd';
import { ALL_ITEMS_ON_PAGE } from 'config/main';
import { useCallback, useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { UpdateChampionshipMatchEntities } from 'queries/sportsChampionshipMatchTeams';
import {
  ChampionshipEntity,
  ChampionshipMatch,
  ChampionshipMatchEntity,
  Mutation,
  MutationUpdateChampionshipMatchEntitiesArgs,
} from 'queries/types';

const I18N_PREFIX_FIELDS = 'sportsMatch.edit.score';

const StyledButton = styled(Button)`
  justify-content: center;
`;

const StyledInputNumber = styled(InputNumber)`
  width: 100%;
`;

interface Props {
  showModal: boolean;
  onClose: () => void;
  match: ChampionshipMatch;
  refetch: any;
}

export const UpdateMatchScores = ({ showModal, onClose, match, refetch }: Props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const championshipMatchEntities =
    (match.championshipMatchEntities as ChampionshipMatchEntity[]) ?? [];
  console.log('****', championshipMatchEntities);

  const [updateChampionshipMatchEntities, { loading: isUpdating, data: isUpdated }] = useMutation<
    Mutation,
    MutationUpdateChampionshipMatchEntitiesArgs
  >(UpdateChampionshipMatchEntities, refetch);

  const onFinish = async (values: any) => {
    updateChampionshipMatchEntities({
      variables: {
        championshipMatchUuid: match.uuid,
        championshipMatchEntities: championshipMatchEntities.map(
          (entity: ChampionshipMatchEntity) => ({
            uuid: entity.uuid,
            matchScore: parseInt(values[`matchScore[${entity.uuid}]`]),
            drawScore: parseInt(values[`drawScore[${entity.uuid}]`]),
            position: parseInt(values[`position[${entity.uuid}]`]),
          })
        ),
      },
    })
      .then()
      .catch((e) => {
        console.error(e);
      });
  };

  useEffect(() => {
    onClose();
  }, [isUpdated]);

  /**
   * Handles confirm button click
   * Calls onRemoveConfirm method that fires the mutation
   */
  const handleOk = () => {
    form.submit();
  };

  /**
   * Handles modal close button click
   * Closes the modal without any mutation happening
   */
  const handleCancel = useCallback(() => {
    if (!false) {
      onClose();
    }
  }, [onClose]);

  if (championshipMatchEntities.length === 0) return null;

  let initialValues: any = {};
  championshipMatchEntities.forEach((entity: ChampionshipMatchEntity) => {
    const matchScoreIndex = `matchScore[${entity.uuid}]`;
    initialValues = { ...initialValues, [matchScoreIndex]: entity.matchScore };

    const drawScoreIndex = `drawScore[${entity.uuid}]`;
    initialValues = { ...initialValues, [drawScoreIndex]: entity.drawScore };

    const positionIndex = `position[${entity.uuid}]`;
    initialValues = { ...initialValues, [positionIndex]: entity.position };
  });

  return (
    <Modal
      title={t('sportsMatch.edit.score.title')}
      visible={showModal}
      onCancel={handleCancel}
      centered={true}
      footer={[
        <StyledButton
          htmlType="submit"
          type="primary"
          block
          loading={isUpdating}
          onClick={handleOk}
        >
          {t('actions.confirm')}
        </StyledButton>,
      ]}
    >
      <Form form={form} layout="vertical" onFinish={onFinish} initialValues={initialValues}>
        {championshipMatchEntities.map((entity: ChampionshipMatchEntity) => (
          <div key={entity.uuid}>
            <h4>{entity.championshipEntity.championshipTeam.team.name}</h4>
            <Form.Item
              name={`matchScore[${entity.uuid}]`}
              label={t(`${I18N_PREFIX_FIELDS}.matchScore.field`)}
              rules={[
                {
                  max: 9999,
                  type: 'number',
                  message: t(`${I18N_PREFIX_FIELDS}.matchScore.errorMessage`),
                },
              ]}
            >
              <StyledInputNumber placeholder={t(`${I18N_PREFIX_FIELDS}.matchScore.placeholder`)} />
            </Form.Item>
            <Form.Item
              name={`drawScore[${entity.uuid}]`}
              label={t(`${I18N_PREFIX_FIELDS}.drawScore.field`)}
              rules={[
                {
                  max: 9999,
                  type: 'number',
                  message: t(`${I18N_PREFIX_FIELDS}.drawScore.errorMessage`),
                },
              ]}
            >
              <StyledInputNumber placeholder={t(`${I18N_PREFIX_FIELDS}.drawScore.placeholder`)} />
            </Form.Item>

            <Form.Item
              name={`position[${entity.uuid}]`}
              label={t(`${I18N_PREFIX_FIELDS}.position.field`)}
            >
              <Select
                className="w-100"
                placeholder={t(`${I18N_PREFIX_FIELDS}.type.placeholder`)}
                options={[
                  { label: t(`${I18N_PREFIX_FIELDS}.position.homeTeam`), value: 0 },
                  { label: t(`${I18N_PREFIX_FIELDS}.position.visitorTeam`), value: 1 },
                ]}
              />
            </Form.Item>
          </div>
        ))}
      </Form>
    </Modal>
  );
};
