import { Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { MenuProps } from 'typings/menu';

type Props = {
  data: MenuProps[];
};

export const TabsMenu = ({ data }: Props) => {
  const location = useLocation();
  const selectedKey = location.pathname;

  const { t } = useTranslation();

  return (
    <Menu theme="light" mode="horizontal" defaultSelectedKeys={[selectedKey]}>
      {data.map((item: any) => (
        <Menu.Item {...item} key={item.path}>
          {item.icon ? <div /> : null}
          <span>{t(item?.title)}</span>
          {item.path ? <Link onClick={() => {}} to={item.path} /> : null}
        </Menu.Item>
      ))}
    </Menu>
  );
};
