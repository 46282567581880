import { ListActionElementType } from 'components/listActionElement';
import { SocialList } from 'components/socialList';

import { FeedPostOrigin, FeedPostPublishedType } from 'queries/types';

export const SocialFeed = () => {
  return (
    <SocialList
      origin={[FeedPostOrigin.Facebook, FeedPostOrigin.Instagram]}
      actionElementType={ListActionElementType.Button}
      published={[FeedPostPublishedType.App]}
    />
  );
};
