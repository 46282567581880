import { ChampionshipCompetition, InputChampionshipCompetition } from 'queries/types';

export const transformFormValues = (
  values: any,
  championship: string
): InputChampionshipCompetition => {
  return {
    name: { pt: values.name },
    championshipSport: values.championshipSport,
    championship,
  };
};

export const transformInitialValues = (data: ChampionshipCompetition): any => {
  const { name, championshipSport } = data;

  return {
    name: name.pt,
    championshipSport: championshipSport.uuid,
  };
};
