import { useLazyQuery } from '@apollo/client';
import { Button } from 'antd';
import { ColorGrey05 } from 'config/main';
import { useState } from 'react';
import { useEffect } from 'react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { AddEventModal } from './addEventModal';
import { Events } from './events';
import { PartActions } from './partActions';
import { generateTimeByStatus } from './utils';

import { IconDelete } from 'assets/icons/iconDelete';
import { IconPlus } from 'assets/icons/iconPlus';

import { GetChampionshipMatch } from 'queries/sportsChampionshipMatch';
import {
  ChampionshipMatch,
  ChampionshipMatchesClock,
  ChampionshipMatchEvent,
  ClockDirection,
  ClockStatus,
  Query,
  QueryChampionshipMatchArgs,
} from 'queries/types';

const PartContainer = styled.div`
  background: white;
  padding: 20px;
  border: 1px solid #edf2f9;
  border-radius: 10px;
  margin-bottom: 25px;
`;

const StyledH4 = styled.h4`
  font-weight: bold;
  margin-bottom: 25px;
`;

const PartInfo = styled.div`
  padding: 25px 0;
  border-bottom: 1px dashed #9fa8b7;
  display: flex;
  justify-content: space-between;
`;

const PartStatusTime = styled.span`
  color: #9fa8b7;
  display: flex;
  align-items: center;

  &.live {
    color: #9845fd;
  }
`;

const PartTime = styled.span`
  color: #9845fd;
  font-size: 22px;
  line-height: 29px;
  margin-left: 10px;
`;

const StyledButton = styled(Button)`
  background: #48555f;
  color: #fafafa;
  display: flex;
  justify-content: center;
  margin: 50px 0 30px;

  &:focus,
  &:hover {
    background: #48555f !important;
    color: #fafafa !important;
  }
`;

const StyledIconPlus = styled(IconPlus)`
  margin-right: 8px;
`;

const I18N_PREFIX = 'sportsMatch.sections.summary';

interface Props {
  clock: ChampionshipMatchesClock;
  events: ChampionshipMatchEvent[];
  isMatchGoing: boolean;
  isLastPart: boolean;
  refetch: any;
  defaultEventKey: any;
}

export const SportsMatchPart: FC<Props> = ({
  clock,
  events,
  isMatchGoing,
  isLastPart,
  refetch,
  defaultEventKey,
}: Props) => {
  const { t } = useTranslation();
  const { uuid } = useParams<{ uuid: string }>();

  const [counter, setCounter] = useState<number>(0);
  const [showAddEventModal, setShowAddEventModal] = useState<boolean>(false);

  const [getChampionshipMatch, { data }] = useLazyQuery<Query, QueryChampionshipMatchArgs>(
    GetChampionshipMatch,
    {
      variables: {
        uuid,
      },
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    getChampionshipMatch();
  }, []);

  const { title, status, lastValue, lastTimestamp, direction } = clock;

  useEffect(() => {
    if (status === ClockStatus.Play) {
      setTimeout(() => {
        setCounter(counter + 1);
      }, 1000);
    }
  }, [counter, status]);

  const sportUuid =
    data &&
    data.championshipMatch.championshipGroup.championshipStage.championshipCompetition
      .championshipSport.uuid;

  const timeOfMatch = generateTimeByStatus(
    status,
    lastValue,
    lastTimestamp,
    direction || ClockDirection.Asc
  );

  return (
    <PartContainer>
      <StyledH4>{title.pt}</StyledH4>
      <PartInfo>
        <PartStatusTime className={status === ClockStatus.Play ? 'live' : ''}>
          {t(`${I18N_PREFIX}.status.${status}`)}
          <PartTime>{timeOfMatch}</PartTime>
        </PartStatusTime>
        <PartActions clock={clock} />
      </PartInfo>
      <Events
        events={events}
        clock={clock}
        status={status}
        isMatchGoing={isMatchGoing}
        areEventsOnLastPart={isLastPart}
        refetch={refetch}
      />
      {status !== ClockStatus.Stop && (
        <StyledButton block onClick={() => setShowAddEventModal(true)}>
          <StyledIconPlus width="15" height="15" fill={ColorGrey05} />
          {t('sportsMatch.sections.summary.event.addEvent')}
        </StyledButton>
      )}
      {data && sportUuid && showAddEventModal && (
        <AddEventModal
          showModal={showAddEventModal}
          onClose={() => setShowAddEventModal(false)}
          championshipSport={sportUuid}
          championshipMatch={data.championshipMatch as ChampionshipMatch}
          timeForEvent={
            (events && events[events.length - 1] && events[events.length - 1].time) ||
            clock.lastValue
          }
          timeDirection={clock.direction}
          timeOfMatch={timeOfMatch}
          defaultEventKey={defaultEventKey}
        />
      )}
    </PartContainer>
  );
};
