import { useParams } from 'react-router-dom';
import { ROUTES } from 'routes/config';
import styled from 'styled-components';
import { MenuProps } from 'typings/menu';

import { GroupMatches } from './groupMatches';
import { GroupPlayers } from './groupPlayers';
import { GroupScores } from './groupScores';
import { GroupTeams } from './groupTeams';

import { TabsMenu } from 'components/tabsMenu';

import { ChampionshipEntity, ChampionshipGroup, ChampionshipMatch } from 'queries/types';

const Container = styled.div`
  background: white;
  width: 50%;
`;

const StyledH4 = styled.h4`
  padding: 20px 23px;
  margin: 0;

  span {
    color: #9fa8b7;
  }
`;

interface Props {
  data?: ChampionshipGroup | null | undefined;
  loading: boolean;
  championshipId: string;
  refetch: any;
}

const I18N_PREFIX = 'sportsChampionship.group';

export const GroupDetails = ({ data, championshipId, refetch }: Props) => {
  const { section } = useParams<{ section: string }>();
  const { uuid, competition, stage, group } = useParams<{
    uuid: string;
    competition: string;
    stage: string;
    group: string;
  }>();

  const baseUrl = `${ROUTES.SportsChampionships}/${uuid}/${competition}/${stage}/${group}`;

  const tabsMenu = (championshipId: string): MenuProps[] => [
    {
      key: `${baseUrl}/matches`,
      path: `${baseUrl}/matches`,
      title: `${I18N_PREFIX}.matches`,
      icon: '',
    },
    // {
    //   key: `${baseUrl}/teams`,
    //   path: `${baseUrl}/teams`,
    //   title: `${I18N_PREFIX}.teams`,
    //   icon: '',
    // },
    // {
    //   key: `${baseUrl}/players`,
    //   path: `${baseUrl}/players`,
    //   title: `${I18N_PREFIX}.players`,
    //   icon: '',
    // },
    {
      key: `${baseUrl}/scores`,
      path: `${baseUrl}/scores`,
      title: `${I18N_PREFIX}.scores`,
      icon: '',
    },
  ];

  let component;

  if (!data) return null;

  switch (section) {
    // case 'teams':
    //   component = (
    //     <GroupTeams
    //       teams={data.championshipEntities as ChampionshipEntity[]}
    //       championshipGroup={data}
    //     />
    //   );
    //   break;
    // case 'players':
    //   component = <GroupPlayers teams={data.championshipEntities as ChampionshipEntity[]} />;
    //   break;

    case 'scores':
      component = (
        <GroupScores
          teams={data.championshipEntities as ChampionshipEntity[]}
          championshipGroup={data}
          refetch={refetch}
        />
      );
      break;
    default:
      component = <GroupMatches matches={data.championshipMatches as ChampionshipMatch[]} />;
      break;
  }

  return (
    <Container>
      <StyledH4>
        {data.name.pt} <span>/ {data.championshipStage.championshipCompetition.name.pt}</span>
      </StyledH4>
      <TabsMenu data={tabsMenu(championshipId)} />
      {component}
    </Container>
  );
};
