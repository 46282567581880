import { Category, InputCategory } from 'queries/types';

export const transformFormValues = (values: any): InputCategory => {
  return {
    ...values,
    name: {
      pt: values.name,
    },
  };
};

export const transformInitialValues = (data: Category): any => {
  const { name, logo } = data;

  return {
    name: name.pt,
    logo: logo,
  };
};
