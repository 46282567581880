import { ColorBlack } from 'config/main';
import { ColorWhite } from 'config/main';

import { IconProps } from './type';

export const IconCalendar = ({ width, height, className, fill }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 18 18"
      fill={ColorWhite}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.2122 1.67858H13.4622V0.250005C13.4622 0.151791 13.3818 0.071434 13.2836 0.071434H12.0336C11.9354 0.071434 11.8551 0.151791 11.8551 0.250005V1.67858H6.14076V0.250005C6.14076 0.151791 6.06041 0.071434 5.96219 0.071434H4.71219C4.61398 0.071434 4.53362 0.151791 4.53362 0.250005V1.67858H0.783622C0.388532 1.67858 0.0693359 1.99777 0.0693359 2.39286V17.2143C0.0693359 17.6094 0.388532 17.9286 0.783622 17.9286H17.2122C17.6073 17.9286 17.9265 17.6094 17.9265 17.2143V2.39286C17.9265 1.99777 17.6073 1.67858 17.2122 1.67858ZM16.3193 16.3214H1.67648V7.83929H16.3193V16.3214ZM1.67648 6.32143V3.28572H4.53362V4.35715C4.53362 4.45536 4.61398 4.53572 4.71219 4.53572H5.96219C6.06041 4.53572 6.14076 4.45536 6.14076 4.35715V3.28572H11.8551V4.35715C11.8551 4.45536 11.9354 4.53572 12.0336 4.53572H13.2836C13.3818 4.53572 13.4622 4.45536 13.4622 4.35715V3.28572H16.3193V6.32143H1.67648Z"
        fill={fill || ColorBlack}
      />
    </svg>
  );
};
