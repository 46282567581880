import { InputTeam, Team } from 'queries/types';

export const transformFormValues = (values: any): InputTeam => {
  return {
    name: values.name,
    shortName: values.shortName,
    logo: values.logo,
    colors: [values.color1, values.color2, values.color3],
  };
};

export const transformInitialValues = (data: Team): any => {
  const { name, shortName, logo, colors } = data;

  return {
    name: name,
    shortName: shortName,
    logo: logo,
    color1: colors[0],
    color2: colors[1],
    color3: colors[2],
  };
};
