import { useTranslation } from 'react-i18next';
import { Route } from 'react-router-dom';
import PrivateRoute from 'routes/PrivateRoute';
import { getCreatePage, getEditPage, ROUTES } from 'routes/config';
import styled from 'styled-components';

import { PressCategories } from './categories';
import { tabsMenu } from './config';
import { PressFeed } from './feed';
import { PressGallery } from './gallery';
import { PressNews } from './news';
import { PressVideo } from './video';

import { CategoriesCreateEdit } from 'components/categoriesCreateEdit';
import { PageHeader } from 'components/pageHeader';
import { PressCreateEdit } from 'components/pressCreateEdit';
import { TabsMenu } from 'components/tabsMenu';

const StyledPageHeader = styled(PageHeader)`
  margin-bottom: 40px;
  padding-bottom: 0;
`;

export const Press = () => {
  const { t } = useTranslation();

  return (
    <div>
      <StyledPageHeader title={t('mainMenu.content.press')} whiteBackground={true}>
        <TabsMenu data={tabsMenu} />
      </StyledPageHeader>

      <Route path={ROUTES.PressFeed} component={PressFeed} />
      <Route path={ROUTES.PressNews} component={PressNews} exact={true} />
      <Route path={ROUTES.PressVideo} component={PressVideo} exact={true} />
      <Route path={ROUTES.PressGallery} component={PressGallery} exact={true} />
      <Route path={ROUTES.PressCategories} component={PressCategories} exact={true} />
      <PrivateRoute
        path={getEditPage(':section', ':uuid')}
        component={PressCreateEdit}
        exact={true}
      />
      <PrivateRoute path={getCreatePage(':section')} component={PressCreateEdit} exact={true} />
    </div>
  );
};
