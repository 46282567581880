import { FeedPostPage } from 'queries/types';

export type PagesPosition = {
  title: string;
  page: FeedPostPage;
  position: number;
};

export const advertisementPagesPosition: PagesPosition[] = [
  {
    title: 'Highlights',
    page: FeedPostPage.Highlights,
    position: -1,
  },
  {
    title: 'News1',
    page: FeedPostPage.News,
    position: 2,
  },
  {
    title: 'News2',
    page: FeedPostPage.News,
    position: 6,
  },
  {
    title: 'Social1',
    page: FeedPostPage.Social,
    position: 2,
  },
  {
    title: 'Social2',
    page: FeedPostPage.Social,
    position: 6,
  },
];
