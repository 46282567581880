import { useTranslation } from 'react-i18next';
import { Route } from 'react-router-dom';
import styled from 'styled-components';

import { tabsMenu } from './config';
import { SocialFacebook } from './facebook';
import { SocialFeed } from './feed';
import { SocialInstagram } from './instagram';

import { PageHeader } from 'components/pageHeader';
import { TabsMenu } from 'components/tabsMenu';

const StyledPageHeader = styled(PageHeader)`
  margin-bottom: 40px;
  padding-bottom: 0;
`;

export const Social = () => {
  const { t } = useTranslation();

  return (
    <div>
      <StyledPageHeader title={t('mainMenu.content.social')} whiteBackground={true}>
        <TabsMenu data={tabsMenu} />
      </StyledPageHeader>

      <Route path="/social/feed" component={SocialFeed} />
      <Route path="/social/facebook" component={SocialFacebook} exact={true} />
      <Route path="/social/instagram" component={SocialInstagram} exact={true} />
    </div>
  );
};
