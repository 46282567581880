import moment from 'moment';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { FeedPostAdvertisement } from 'queries/types';

const Tag = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  border: 1px solid #eef2f8;
  border-radius: 5px;
  float: right;
`;

const TagFuture = styled(Tag)`
  border-color: #9845fd;
  color: #9845fd;
`;

const TagActive = styled(Tag)`
  border-color: #24b574;
  color: #24b574;
`;

interface Props {
  advertisement: FeedPostAdvertisement;
}

export const DateStatusTag = ({ advertisement }: Props) => {
  const { t } = useTranslation();
  const { startDate, endDate } = advertisement;

  const today = moment().format('YYYY-MM-DD');

  if (today < startDate) return <TagFuture>{t('advertisementList.status.future')}</TagFuture>;

  if (today >= startDate && today <= endDate)
    return <TagActive>{t('advertisementList.status.active')}</TagActive>;

  return <Tag>{t('advertisementList.status.expired')}</Tag>;
};
