import { gql } from '@apollo/client';

export const CreateCategory = gql`
  mutation CreateCategory($input: InputCategory!) {
    createCategory(input: $input) {
      uuid
    }
  }
`;

export const UpdateCategory = gql`
  mutation UpdateCategory($uuid: UUID!, $input: InputCategory!) {
    updateCategory(uuid: $uuid, input: $input) {
      uuid
    }
  }
`;

export const DeleteCategory = gql`
  mutation DeleteCategory($uuid: UUID!) {
    deleteCategory(uuid: $uuid) {
      uuid
    }
  }
`;
