import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { tabsMenu } from './config';

import { PageHeader } from 'components/pageHeader';
import { SubmitButton } from 'components/submitButton';
import { TabsMenu } from 'components/tabsMenu';

const StyledPageHeader = styled(PageHeader)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 42px;
  position: relative;
  margin-bottom: 25px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TabsMenuWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
`;

interface Props {
  uuid?: string;
  name?: string;
  loading?: boolean;
}

export const Header = ({ uuid, loading }: Props) => {
  const { t } = useTranslation();

  const I18N_PREFIX = 'sportsChampionshipForm';

  return (
    <StyledPageHeader
      title={t(uuid ? `${I18N_PREFIX}.header.edit` : `${I18N_PREFIX}.header.create`)}
      whiteBackground={!!uuid}
    >
      <ButtonsWrapper>
        <SubmitButton isLoading={loading} label={t(`${I18N_PREFIX}.submit`)} />
      </ButtonsWrapper>

      {uuid && (
        <TabsMenuWrapper>
          <TabsMenu data={tabsMenu(uuid)} />
        </TabsMenuWrapper>
      )}
    </StyledPageHeader>
  );
};
