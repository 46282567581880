import { MenuProps } from '../../typings/menu';

const I18N_PREFIX = 'tabsMenu.social';

/**
 * MAIN MENU SECTION
 */
export const tabsMenu: MenuProps[] = [
  {
    key: '/social/feed',
    path: '/social/feed',
    title: `${I18N_PREFIX}.feed`,
    icon: '',
  },
  {
    key: '/social/facebook',
    path: '/social/facebook',
    title: `${I18N_PREFIX}.facebook`,
    icon: '',
  },
  {
    key: '/social/instagram',
    path: '/social/instagram',
    title: `${I18N_PREFIX}.instagram`,
    icon: '',
  },
];
