import { PostsList } from 'components/postsList';

import { FeedPostType, FeedPostOrigin } from 'queries/types';

export const PressGallery = () => {
  return (
    <PostsList
      origin={[FeedPostOrigin.Internal]}
      types={[FeedPostType.Gallery]}
      section={FeedPostType.Gallery}
    />
  );
};
