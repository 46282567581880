import { gql } from '@apollo/client';

export const UpdateMatchClockStatus = gql`
  mutation UpdateMatchClockStatus($uuid: UUID!, $clockStatus: ClockStatus!) {
    championshipMatchClockUpdateStatus(uuid: $uuid, clockStatus: $clockStatus) {
      uuid
    }
  }
`;

export const CreateMatchClock = gql`
  mutation CreateMatchClock($uuid: UUID!, $clock: InputChampionshipMatchesClock!) {
    championshipMatchClockCreate(uuid: $uuid, clock: $clock) {
      uuid
    }
  }
`;
