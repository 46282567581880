import { useTranslation } from 'react-i18next';
import { Route } from 'react-router';
import PrivateRoute from 'routes/PrivateRoute';
import { ROUTES } from 'routes/config';
import styled from 'styled-components';

import { getCreateAdvertisement, getEditAdvertisement } from './utils';

import { AdvertisementCreateEdit } from 'components/advertisementCreateEdit';
import { AdvertisementList } from 'components/advertisementsList';
import { PageHeader } from 'components/pageHeader';

const StyledPageHeader = styled(PageHeader)`
  padding-bottom: 0;
`;

export const Advertisement = () => {
  return (
    <>
      <PrivateRoute
        path={`${getCreateAdvertisement()}`}
        component={AdvertisementCreateEdit}
        exact={true}
      />
      <PrivateRoute
        path={`${getEditAdvertisement()}`}
        component={AdvertisementCreateEdit}
        exact={true}
      />
      <Route path={ROUTES.Advertisement} component={AdvertisementListPage} exact={true} />
    </>
  );
};

export const AdvertisementListPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <StyledPageHeader title={t('mainMenu.content.advertisement')} />
      <AdvertisementList />
    </>
  );
};
