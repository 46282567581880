import { gql } from '@apollo/client';

export const CreateFeedPost = gql`
  mutation CreateFeedPost($input: InputFeedPosts!) {
    createFeedPost(input: $input) {
      uuid
    }
  }
`;

export const UpdateFeedPost = gql`
  mutation UpdateFeedPost($uuid: UUID!, $input: InputFeedPosts!) {
    updateFeedPost(uuid: $uuid, input: $input) {
      uuid
    }
  }
`;

export const DeleteFeedPost = gql`
  mutation DeleteFeedPost($uuid: UUID!) {
    deleteFeedPost(uuid: $uuid) {
      uuid
    }
  }
`;

export const CreateTags = gql`
  mutation CreateTags($input: [InputTag]!) {
    createTags(input: $input) {
      uuid
    }
  }
`;
