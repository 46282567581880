import { ApolloProvider } from '@apollo/client';
import { AuthProvider } from 'context/AuthContext';
import { BrowserRouter } from 'react-router-dom';
import { AppRouter } from 'routes/router';
import { client } from 'services/client';

function App() {
  return (
    <ApolloProvider client={client}>
      <AuthProvider>
        <BrowserRouter>
          <AppRouter />
        </BrowserRouter>
      </AuthProvider>
    </ApolloProvider>
  );
}

export default App;
