import { useLazyQuery } from '@apollo/client';
import { ALL_ITEMS_ON_PAGE } from 'config/main';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';

import { Groups } from './competitionsGroups';
import { Competitions, Stages } from './competitionsStages';
import { GroupDetails } from './groupDetails';
import { Header } from './header';

import { GetChampionship } from 'queries/sportsChampionship';
import { GetChampionshipCompetitions } from 'queries/sportsChampionshipCompetitions';
import { GetChampionshipGroup } from 'queries/sportsChampionshipGroup';
import { GetChampionshipGroups } from 'queries/sportsChampionshipGroups';
import { GetChampionshipStages } from 'queries/sportsChampionshipStages';
import {
  ChampionshipCompetition,
  ChampionshipGroup,
  ChampionshipStage,
  FeedPostOrderKeys,
  OrderDirection,
  Query,
  QueryChampionshipArgs,
  QueryChampionshipCompetitionsArgs,
  QueryChampionshipGroupArgs,
  QueryChampionshipGroupsArgs,
  QueryChampionshipStagesArgs,
} from 'queries/types';

const CompetitionsStagesWrapper = styled.div`
  display: flex;
`;

export const SportsChampionshipDetails = () => {
  const { uuid, competition, stage, group } = useParams<{
    uuid: string;
    competition: string;
    stage: string;
    group: string;
  }>();

  const [getChampionship, { data: championship }] = useLazyQuery<Query, QueryChampionshipArgs>(
    GetChampionship,
    {
      variables: {
        uuid,
      },
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    if (uuid) getChampionship();
  }, [uuid]);

  const [
    getChampionshipCompetitions,
    { data: championshipCompetitions, loading: championshipCompetitionsLoading },
  ] = useLazyQuery<Query, QueryChampionshipCompetitionsArgs>(GetChampionshipCompetitions, {
    variables: {
      filter: {
        championshipUuid: uuid,
      },
      pagination: {
        page: 1,
        take: ALL_ITEMS_ON_PAGE,
      },
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (uuid) getChampionshipCompetitions();
  }, [uuid]);

  const [
    getChampionshipStages,
    { data: championshipStages, loading: championshipStagesLoading },
  ] = useLazyQuery<Query, QueryChampionshipStagesArgs>(GetChampionshipStages, {
    variables: {
      filter: {
        championshipCompetitionUuid: competition,
      },
      pagination: {
        page: 1,
        take: ALL_ITEMS_ON_PAGE,
      },
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (competition) getChampionshipStages();
  }, [competition]);

  const [
    getChampionshipGroups,
    { data: championshipGroups, loading: championshipGroupsLoading },
  ] = useLazyQuery<Query, QueryChampionshipGroupsArgs>(GetChampionshipGroups, {
    variables: {
      filter: {
        championshipStageUuid: stage,
      },
      pagination: {
        page: 1,
        take: ALL_ITEMS_ON_PAGE,
      },
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (stage) getChampionshipGroups();
  }, [stage]);

  const [
    getChampionshipGroup,
    { data: championshipGroup, loading: championshipGroupLoading, refetch: refetchGroup },
  ] = useLazyQuery<Query, QueryChampionshipGroupArgs>(GetChampionshipGroup, {
    variables: {
      uuid: group,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  useEffect(() => {
    getChampionshipGroup();
  }, [group]);

  if (!uuid) return null;

  return (
    <>
      <Header uuid={uuid} name={championship?.championship.name.pt} />
      <CompetitionsStagesWrapper>
        <Competitions
          data={
            championshipCompetitions?.championshipCompetitions.edges as ChampionshipCompetition[]
          }
          loading={championshipCompetitionsLoading}
        />
        <Stages
          data={championshipStages?.championshipStages.edges as ChampionshipStage[]}
          loading={championshipStagesLoading}
        />
        <Groups
          data={championshipGroups?.championshipGroups.edges as ChampionshipGroup[]}
          loading={championshipGroupsLoading}
        />
        <GroupDetails
          data={championshipGroup?.championshipGroup}
          loading={championshipGroupLoading}
          championshipId={uuid}
          refetch={refetchGroup}
        />
      </CompetitionsStagesWrapper>
    </>
  );
};
