import { ColorGrey01 } from 'config/main';

import { IconProps } from './type';

export const IconPress = ({ width, height, className, fill }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M17.3571 1.94864H12.7607C11.7743 1.94864 10.81 2.2319 9.98036 2.76627L9 3.39507L8.01964 2.76627C7.19078 2.232 6.22542 1.94811 5.23929 1.94864H0.642857C0.287277 1.94864 0 2.23592 0 2.5915V14.0022C0 14.3578 0.287277 14.6451 0.642857 14.6451H5.23929C6.22567 14.6451 7.18996 14.9283 8.01964 15.4627L8.91161 16.0373C8.93772 16.0533 8.96786 16.0634 8.99799 16.0634C9.02812 16.0634 9.05826 16.0553 9.08438 16.0373L9.97634 15.4627C10.808 14.9283 11.7743 14.6451 12.7607 14.6451H17.3571C17.7127 14.6451 18 14.3578 18 14.0022V2.5915C18 2.23592 17.7127 1.94864 17.3571 1.94864ZM5.23929 13.1986H1.44643V3.39507H5.23929C5.95045 3.39507 6.64152 3.59797 7.23817 3.98168L8.21853 4.61047L8.35714 4.70087V13.9821C7.40089 13.4678 6.33214 13.1986 5.23929 13.1986ZM16.5536 13.1986H12.7607C11.6679 13.1986 10.5991 13.4678 9.64286 13.9821V4.70087L9.78147 4.61047L10.7618 3.98168C11.3585 3.59797 12.0496 3.39507 12.7607 3.39507H16.5536V13.1986ZM6.68772 5.9665H2.95513C2.87679 5.9665 2.8125 6.0348 2.8125 6.11717V7.02118C2.8125 7.10355 2.87679 7.17185 2.95513 7.17185H6.68571C6.76406 7.17185 6.82835 7.10355 6.82835 7.02118V6.11717C6.83036 6.0348 6.76607 5.9665 6.68772 5.9665ZM11.1696 6.11717V7.02118C11.1696 7.10355 11.2339 7.17185 11.3123 7.17185H15.0429C15.1212 7.17185 15.1855 7.10355 15.1855 7.02118V6.11717C15.1855 6.0348 15.1212 5.9665 15.0429 5.9665H11.3123C11.2339 5.9665 11.1696 6.0348 11.1696 6.11717ZM6.68772 8.779H2.95513C2.87679 8.779 2.8125 8.8473 2.8125 8.92967V9.83368C2.8125 9.91605 2.87679 9.98435 2.95513 9.98435H6.68571C6.76406 9.98435 6.82835 9.91605 6.82835 9.83368V8.92967C6.83036 8.8473 6.76607 8.779 6.68772 8.779ZM15.0449 8.779H11.3123C11.2339 8.779 11.1696 8.8473 11.1696 8.92967V9.83368C11.1696 9.91605 11.2339 9.98435 11.3123 9.98435H15.0429C15.1212 9.98435 15.1855 9.91605 15.1855 9.83368V8.92967C15.1875 8.8473 15.1232 8.779 15.0449 8.779Z"
          fill={fill || ColorGrey01}
        />
      </g>
    </svg>
  );
};
