import jwt from 'jsonwebtoken';
import React, { useEffect, useState } from 'react';

// Types
type Action = { type: 'LOGIN'; payload: string } | { type: 'LOGOUT' };
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type Dispatch = (action: Action) => void;

type State = {
  isLoggedIn: boolean;
};

function getInitialState(): State {
  let token = localStorage.getItem('token');

  if (token) {
    // check if token is still valid, comparing the expiration date
    const jwtDecoded: any = jwt.decode(token, { complete: true });
    if (jwtDecoded.payload.exp <= Date.now() / 1000) {
      localStorage.removeItem('token');
      token = null;
    }
  }

  return {
    isLoggedIn: !!token,
  };
}

const AuthStateContext = React.createContext<State>(getInitialState());
const AuthDispatchContext = React.createContext<Dispatch | undefined>(undefined);

const defaultState = {
  isLoggedIn: false,
};

export const authReducer = (state: State, action: any) => {
  switch (action.type) {
    case 'LOGIN':
      localStorage.setItem('token', action.payload);
      return {
        ...state,
        isLoggedIn: true,
        token: action.payload,
      };
    case 'LOGOUT':
      localStorage.removeItem('token');
      return defaultState;

    default:
      return state;
  }
};

export const AuthProvider: React.ComponentType<React.ReactNode> = ({ children }: any) => {
  const [state, dispatch] = React.useReducer(authReducer, getInitialState());

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>{children}</AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};

export const useAuthState = () => {
  const context = React.useContext(AuthStateContext);
  if (context === undefined) {
    throw new Error('useAuthState must be used within a AuthProvider');
  }
  return context;
};

export const useAuthDispatch = () => {
  const context = React.useContext(AuthDispatchContext);
  if (context === undefined) {
    throw new Error('useAuthDispatch must be used within a AuthProvider');
  }
  return context;
};
