export function getConfig() {
  return {
    clientId: process.env.REACT_APP_CLIENT_ID as string,
    redirectUri: process.env.REACT_APP_REDIRECT_URI as string,
    userFlowLogin: process.env.REACT_APP_USERFLOW_LOGIN as string,
    userFlowResetPassword: process.env.REACT_APP_USERFLOW_PASSWORD as string,
    apiEndpoint: process.env.REACT_APP_API_ENDPOINT as string,
    authEndpoint: process.env.REACT_APP_AUTH_ENDPOINT as string,
    uploadEndpoint: process.env.REACT_APP_UPLOAD_ENDPOINT as string,
  };
}
