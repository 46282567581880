import { ROUTES } from 'routes/config';

const I18N_PREFIX = 'tabsMenu.sportsChampionshipGroups';

/**
 * MAIN MENU SECTION
 */
export const tabsMenu = ({ uuid, competition, stage, group }: any) => {
  return [
    {
      key: `${ROUTES.SportsTeams}`,
      path: `${ROUTES.SportsChampionships}/${uuid}/${competition}/${stage}/group/edit/${group}/teams`,
      title: `${I18N_PREFIX}.teams`,
      icon: '',
    },
    {
      key: `${ROUTES.SportsTeams}`,
      path: `${ROUTES.SportsChampionships}/${uuid}/${competition}/${stage}/group/edit/${group}`,
      title: `${I18N_PREFIX}.info`,
      icon: '',
    },
  ];
};
