import { InputPlayer, Player } from 'queries/types';

export const transformFormValues = (values: any): InputPlayer => {
  return {
    firstName: values.firstName,
    lastName: values.lastName,
    identification: values.identification,
    championshipSports: values.championshipSports ? [values.championshipSports] : null,
  };
};

export const transformInitialValues = (data: Player): any => {
  const { firstName, lastName, identification, championshipSports } = data;

  return {
    firstName,
    lastName,
    identification,
    championshipSports: championshipSports ? championshipSports[0]?.uuid : null,
  };
};
