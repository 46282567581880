import { Menu, MenuItemProps } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { mainMenu } from '../../config/mainMenu';
import { MenuProps } from '../../typings/menu';

type Props = {
  sideNavTheme?: any;
  hideGroupTitle?: any;
  localization?: any;
};

const StyledMenu = styled(Menu)`
  height: '100%';
  border-right: 0;
  content: '';
`;

const StyledMenuItem = styled(Menu.Item)`
  > span {
    display: inline-flex;
    align-items: center;
  }
`;

export const MenuContent = ({ hideGroupTitle }: Props) => {
  const { SubMenu } = Menu;

  const location = useLocation();
  const selectedKey = location.pathname;

  const { t } = useTranslation();

  return (
    <StyledMenu
      defaultSelectedKeys={[selectedKey]}
      theme="light"
      mode="inline"
      className={hideGroupTitle ? 'hide-group-title' : ''}
    >
      {mainMenu.map((menu: MenuProps) =>
        menu.submenu && menu.submenu.length > 0 ? (
          <Menu.ItemGroup key={menu.path} title={t(menu.title as string)}>
            {menu.submenu.map((subMenuFirst: MenuProps) =>
              subMenuFirst.submenu && subMenuFirst.submenu.length > 0 ? (
                <SubMenu
                  icon={subMenuFirst.icon ? <div /> : null}
                  key={subMenuFirst.path}
                  title={t(subMenuFirst.title as string)}
                >
                  {subMenuFirst.submenu.map((subMenuSecond: MenuProps) => (
                    <MenuItem {...subMenuSecond} />
                  ))}
                </SubMenu>
              ) : (
                <MenuItem {...subMenuFirst} />
              )
            )}
          </Menu.ItemGroup>
        ) : (
          <MenuItem {...menu} />
        )
      )}
    </StyledMenu>
  );
};

const MenuItem = (menu: MenuProps & MenuItemProps) => {
  const { t } = useTranslation();
  return (
    <StyledMenuItem {...menu} key={menu.path}>
      {menu.icon !== ''
        ? React.createElement(menu.icon as string, {
            width: '18',
            height: '18',
            className: 'anticon',
          })
        : null}
      <span>{t(menu?.title as string)}</span>
      {menu.path ? <Link onClick={() => {}} to={menu.path} /> : null}
    </StyledMenuItem>
  );
};
