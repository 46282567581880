import { gql } from '@apollo/client';

export const GetChampionships = gql`
  query($pagination: InputPagePagination) {
    championships(pagination: $pagination) {
      edges {
        uuid
        name
        shortName
        logo {
          url
        }
        managementEntity {
          name
        }
      }
    }
  }
`;
