import { ColorGrey01 } from 'config/main';

import { IconProps } from './type';

export const Icon3Dots = ({ width, height, className, fill }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.98438 13.625C8.10749 13.625 8.2294 13.6008 8.34314 13.5536C8.45688 13.5065 8.56023 13.4375 8.64729 13.3504C8.73434 13.2634 8.8034 13.16 8.85051 13.0463C8.89763 12.9325 8.92187 12.8106 8.92187 12.6875C8.92187 12.5644 8.89763 12.4425 8.85051 12.3287C8.8034 12.215 8.73434 12.1116 8.64729 12.0246C8.56023 11.9375 8.45688 11.8685 8.34314 11.8214C8.2294 11.7742 8.10749 11.75 7.98437 11.75C7.86126 11.75 7.73935 11.7742 7.62561 11.8214C7.51187 11.8685 7.40852 11.9375 7.32146 12.0246C7.23441 12.1116 7.16535 12.215 7.11824 12.3287C7.07112 12.4425 7.04687 12.5644 7.04687 12.6875C7.04687 12.8106 7.07112 12.9325 7.11824 13.0463C7.16535 13.16 7.23441 13.2634 7.32146 13.3504C7.40852 13.4375 7.51187 13.5065 7.62561 13.5536C7.73935 13.6008 7.86126 13.625 7.98438 13.625ZM7.98437 8.9375C8.23302 8.9375 8.47147 8.83873 8.64729 8.66291C8.8231 8.4871 8.92187 8.24864 8.92187 8C8.92187 7.75136 8.8231 7.5129 8.64729 7.33709C8.47147 7.16127 8.23302 7.0625 7.98437 7.0625C7.73573 7.0625 7.49728 7.16127 7.32146 7.33709C7.14565 7.5129 7.04687 7.75136 7.04687 8C7.04687 8.24864 7.14565 8.4871 7.32146 8.66291C7.49728 8.83873 7.73573 8.9375 7.98437 8.9375ZM7.98437 4.25C8.23302 4.25 8.47147 4.15123 8.64729 3.97541C8.8231 3.79959 8.92187 3.56114 8.92187 3.3125C8.92187 3.06386 8.8231 2.8254 8.64729 2.64959C8.47147 2.47377 8.23301 2.375 7.98437 2.375C7.73573 2.375 7.49728 2.47377 7.32146 2.64959C7.14565 2.8254 7.04687 3.06386 7.04687 3.3125C7.04687 3.56114 7.14565 3.79959 7.32146 3.97541C7.49728 4.15123 7.73573 4.25 7.98437 4.25Z"
        fill={fill || ColorGrey01}
      />
    </svg>
  );
};
