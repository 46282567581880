import { IconProps } from './type';

export const IconAdvertisement = ({ width, height, className, fill }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.2122 0.0714722C17.1274 0.0714722 17.0403 0.0870972 16.9533 0.122812L4.08719 5.29245H0.426479C0.23005 5.29245 0.0693359 5.45763 0.0693359 5.66299V12.3371C0.0693359 12.5425 0.23005 12.7076 0.426479 12.7076H2.69657C2.61398 12.9666 2.56934 13.2411 2.56934 13.5201C2.56934 14.9911 3.77023 16.1875 5.24791 16.1875C6.48451 16.1875 7.52693 15.3483 7.83496 14.2143L16.9555 17.8795C17.0426 17.913 17.1296 17.9308 17.2144 17.9308C17.5917 17.9308 17.9287 17.6139 17.9287 17.1898V0.812544C17.9265 0.388436 17.5917 0.0714722 17.2122 0.0714722ZM5.24791 14.5871C4.65639 14.5871 4.17648 14.1094 4.17648 13.5201C4.17648 13.2701 4.26353 13.0313 4.42201 12.8416L6.3171 13.6027C6.27246 14.1518 5.81041 14.5871 5.24791 14.5871ZM16.3193 15.8907L4.68541 11.2166L4.39746 11.1005H1.67648V6.8996H4.39746L4.68541 6.78353L16.3193 2.10942V15.8907Z"
        fill={fill || 'black'}
      />
    </svg>
  );
};
