import { gql } from '@apollo/client';

export const GetChampionshipEntityPlayers = gql`
  query GetChampionshipEntityPlayers(
    $championshipEntityUuid: UUID!
    $pagination: InputPagePagination
  ) {
    championshipEntityPlayers(
      championshipEntityUuid: $championshipEntityUuid
      pagination: $pagination
    ) {
      edges {
        uuid
        player {
          uuid
          firstName
          lastName
          identification
        }
        championshipMatchEntityPlayers {
          uuid
        }
      }
      pagination {
        pageEdgesCount
        pageCount
        pageIndex
        pageEdgesMaxCount
      }
    }
  }
`;

export const CreateChampionshipEntityPlayers = gql`
  mutation CreateChampionshipEntityPlayers($championshipEntityUuid: UUID!, $playerUuids: [UUID]!) {
    createChampionshipEntityPlayers(
      championshipEntityUuid: $championshipEntityUuid
      playerUuids: $playerUuids
    ) {
      uuid
    }
  }
`;
