import { ColorSuccess } from 'config/main';

import { IconProps } from './type';

export const IconUpArrow = ({ width, height, className, fill }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.1469 15L4.52851 15C4.62246 15 4.71088 14.9512 4.76614 14.8711L9.99965 7.22266L15.2332 14.8711C15.2884 14.9512 15.3768 15 15.4708 15L16.8524 15C16.9721 15 17.0421 14.8555 16.9721 14.752L10.4768 5.25781C10.241 4.91406 9.75833 4.91406 9.52438 5.25781L3.02901 14.752C2.95716 14.8555 3.02717 15 3.1469 15Z"
        fill={fill || ColorSuccess}
      />
    </svg>
  );
};
