import { UPLOAD_ENDPOINT } from 'config/api';

export default class Gallery {
  constructor({ data, api, readOnly }) {
    this.api = api;
    this.readOnly = readOnly;
    this.galleryData = data;

    this.galleryDataToSave = this.galleryData.files || [];
    this.handleGalleryChange.bind(this);
    this.updateImagesOrder.bind(this);

    this.buttonContent = 'Adicionar Imagens';
    this.buttonLoading = 'Loading';
  }

  static get toolbox() {
    return {
      title: 'Galeria',
      icon:
        '<svg width="17" height="15" viewBox="0 0 336 276" xmlns="http://www.w3.org/2000/svg"><path d="M291 150V79c0-19-15-34-34-34H79c-19 0-34 15-34 34v42l67-44 81 72 56-29 42 30zm0 52l-43-30-56 30-81-67-66 39v23c0 19 15 34 34 34h178c17 0 31-13 34-29zM79 0h178c44 0 79 35 79 79v118c0 44-35 79-79 79H79c-44 0-79-35-79-79V79C0 35 35 0 79 0z"/></svg>',
    };
  }

  renderButtonLoading(button) {
    button.classList.add('ant-btn-loading');

    const loading =
      '<span class="ant-btn-loading-icon" style=""><span role="img" aria-label="loading" class="anticon anticon-loading anticon-spin"><svg viewBox="0 0 1024 1024" focusable="false" data-icon="loading" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path></svg></span></span>';

    button.innerHTML = `${loading} <span>${this.buttonLoading}</span>`;
  }

  handleGalleryChange(input, button, galleryWrapper) {
    this.renderButtonLoading(button);

    const headers = new Headers();
    headers.append('Authorization', `Bearer ${localStorage.getItem('token')}`);

    const promises = [];

    const requestOptions = {
      method: 'POST',
      headers: headers,
    };

    for (let index = 0; index < input.files.length; index++) {
      const file = input.files[index];

      const formdata = new FormData();
      formdata.append('file', file);
      requestOptions.body = formdata;

      promises.push(
        Promise.resolve(fetch(UPLOAD_ENDPOINT, requestOptions).then((response) => response.json()))
      );
    }

    Promise.allSettled(promises)
      .then((list) => {
        const gallery = list.map((item) => {
          return { type: 'image', data: { file: item.value.data } };
        });

        for (let index = 0; index < gallery.length; index++) {
          const item = gallery[index];

          const imageWrapper = this.renderImage(item.data.file.url, galleryWrapper);
          galleryWrapper.append(imageWrapper);
        }

        this.galleryDataToSave = [...this.galleryDataToSave, ...gallery];

        this.save();

        button.textContent = this.buttonContent;
        button.classList.remove('ant-btn-loading');
      })
      .catch((error) => {
        console.log('Error:', error);
      });
  }

  renderImage(url) {
    const image = document.createElement('img');
    image.setAttribute('src', url);

    const imageWrapper = document.createElement('div');
    imageWrapper.classList.add('image-wrapper');

    // actions
    const actions = document.createElement('div');
    actions.classList.add('actions');

    // arrows
    const arrowLeft = document.createElement('div');
    arrowLeft.classList.add('actions-arrow-left');
    const arrowLeftIcon = document.createElement('img');
    arrowLeftIcon.src = '/icons/arrow-left.svg';
    arrowLeft.append(arrowLeftIcon);

    const arrowRight = document.createElement('div');
    arrowRight.classList.add('actions-arrow-right');
    const arrowRightIcon = document.createElement('img');
    arrowRightIcon.src = '/icons/arrow-right.svg';
    arrowRight.append(arrowRightIcon);

    // remove
    const remove = document.createElement('div');
    remove.classList.add('actions-remove');
    const removeIcon = document.createElement('img');
    removeIcon.src = '/icons/remove.svg';
    remove.append(removeIcon);

    actions.append(arrowLeft);
    actions.append(remove);
    actions.append(arrowRight);

    imageWrapper.append(image);
    imageWrapper.append(actions);

    return imageWrapper;
  }

  updateImagesOrder(event) {
    this.disableScrolling();

    const target = event.target;
    const imageWrapper = target.parentNode.parentNode;
    const imageWrapperCloned = imageWrapper.cloneNode(true);

    const gallery = imageWrapper.parentNode;

    const imageIndex = [...gallery.children].indexOf(imageWrapper);
    let nextImageIndex = imageIndex;

    let targetPosition = null;

    switch (target.classList[0]) {
      case 'actions-arrow-left':
        targetPosition = imageWrapper.previousSibling;
        nextImageIndex--;

        targetPosition && gallery.insertBefore(imageWrapperCloned, targetPosition);
        break;
      case 'actions-arrow-right':
        targetPosition = imageWrapper.nextSibling;

        if (targetPosition) {
          targetPosition.nextSibling
            ? gallery.insertBefore(imageWrapperCloned, targetPosition.nextSibling)
            : gallery.append(imageWrapperCloned);
        }

        nextImageIndex++;
        break;
      case 'actions-remove':
        gallery.removeChild(imageWrapper);

        // remove position from array
        const tempGalleryResult = [...this.galleryDataToSave];
        tempGalleryResult.splice(imageIndex, 1);
        this.galleryDataToSave = [...tempGalleryResult];

        break;
      default:
        break;
    }

    if (targetPosition) {
      gallery.removeChild(imageWrapper);

      const tempGalleryResult = [...this.galleryDataToSave];

      [tempGalleryResult[imageIndex], tempGalleryResult[nextImageIndex]] = [
        tempGalleryResult[nextImageIndex],
        tempGalleryResult[imageIndex],
      ];

      this.galleryDataToSave = [...tempGalleryResult];
    }

    this.api.saver.save().then(() => {
      setTimeout(this.enableScrolling, 1000);
    });
  }

  disableScrolling() {
    var x = window.scrollX;
    var y = window.scrollY;
    window.onscroll = function () {
      window.scrollTo(x, y);
    };
  }

  enableScrolling() {
    window.onscroll = function () {};
  }

  render() {
    const editorGalleryContainer = document.createElement('div');
    editorGalleryContainer.classList.add('editor-gallery-element');

    const galleryWrapper = document.createElement('div');
    galleryWrapper.classList.add('gallery-wrapper');
    galleryWrapper.addEventListener('click', (e) => this.updateImagesOrder(e));

    const button = document.createElement('button');
    button.classList.add('ant-btn', 'ant-btn-primary');
    button.textContent = this.buttonContent;
    button.addEventListener('click', (e) => {
      e.preventDefault();
      input.click();
    });

    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/jpeg, image/png, image/gif');
    input.setAttribute('multiple', 'multiple');
    if (this.galleryData.file) {
      input.setAttribute('value', this.galleryData.file);
    }
    input.addEventListener('change', () => {
      this.handleGalleryChange(input, button, galleryWrapper);
    });

    if (this.galleryData.files) {
      for (let index = 0; index < this.galleryData.files.length; index++) {
        const item = this.galleryData.files[index];

        const imageWrapper = this.renderImage(item.data.file.url, galleryWrapper);
        galleryWrapper.append(imageWrapper);
      }
    }

    editorGalleryContainer.append(galleryWrapper);
    editorGalleryContainer.append(button);
    editorGalleryContainer.append(input);

    return editorGalleryContainer;
  }

  save() {
    return {
      files: this.galleryDataToSave || this.galleryData.file,
    };
  }
}
