import { ColorGrey01 } from 'config/main';

import { IconProps } from './type';

export const IconPause = ({ width, height, className, fill }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 9 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.38423 15.1998C6.83703 15.1998 6.39062 14.7534 6.39062 14.2062V1.7934C6.39062 1.2462 6.83703 0.799805 7.38423 0.799805C7.93143 0.799805 8.37782 1.2462 8.37782 1.7934V14.1918C8.37782 14.7534 7.93143 15.1998 7.38423 15.1998Z"
        fill={fill || ColorGrey01}
      />
      <path
        d="M1.39204 15.1998C0.844838 15.1998 0.398438 14.7534 0.398438 14.2062V1.7934C0.398438 1.2462 0.844838 0.799805 1.39204 0.799805C1.93924 0.799805 2.38564 1.2462 2.38564 1.7934V14.1918C2.38564 14.7534 1.93924 15.1998 1.39204 15.1998Z"
        fill={fill || ColorGrey01}
      />
    </svg>
  );
};
