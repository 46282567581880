import { gql } from '@apollo/client';

export const GetCategoriesListQuery = gql`
  query($filter: InputGenericFilter, $pagination: InputPagePagination, $order: InputGenericOrder) {
    categories(filter: $filter, order: $order, pagination: $pagination) {
      pagination {
        pageIndex
        pageCount
      }
      edges {
        uuid
        name
        createdAt
        logo {
          url
        }
      }
    }
  }
`;
