import { gql } from '@apollo/client';

export const CreateAdvertisement = gql`
  mutation CreateAdvertisement($input: InputFeedPosts!) {
    createFeedPost(input: $input) {
      uuid
    }
  }
`;

export const UpdateAdvertisement = gql`
  mutation UpdateAdverstisement($uuid: UUID!, $input: InputFeedPosts!) {
    updateFeedPost(uuid: $uuid, input: $input) {
      uuid
    }
  }
`;

export const DeleteAdvertisement = gql`
  mutation DeleteAdverstisement($uuid: UUID!) {
    deleteFeedPost(uuid: $uuid) {
      uuid
    }
  }
`;
