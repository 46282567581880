import { useLazyQuery } from '@apollo/client';
import { Card, Select, Table } from 'antd';
import { Option } from 'antd/lib/mentions';
import { ALL_ITEMS_ON_PAGE, ITEMS_PER_PAGE } from 'config/main';
import { getTablePagination } from 'helpers/tablePagination';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ROUTES } from 'routes/config';
import styled from 'styled-components';

import { SearchField } from 'components/searchField';
import { Tag } from 'components/tag';

import { GetChampionshipSports } from 'queries/sportsChampionshipSports';
import { GetPlayers } from 'queries/sportsPlayers';
import {
  Query,
  Pagination,
  GenericOrderKeys,
  Player,
  QueryPlayersArgs,
  ChampionshipSport,
  QueryChampionshipSportsArgs,
} from 'queries/types';

const CardWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: flex-start;
`;

const StyledTag = styled(Tag)`
  margin-right: 3px;
`;

const I18N_PREFIX: string = 'sportsPlayersList';

export const SportsPlayersList: React.VFC = () => {
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [selectedChampionshipSport, setSelectedChampionshipSport] = useState<string>('');

  const { t } = useTranslation();

  const variables: QueryPlayersArgs = {
    filter: {
      query: searchQuery,
      // championshipSports: selectedChampionshipSport ? [selectedChampionshipSport] : null,
    },
    pagination: {
      page: selectedPage,
      take: ITEMS_PER_PAGE,
    },
  };

  const [getPlayers, { data, loading }] = useLazyQuery<Query, QueryPlayersArgs>(GetPlayers, {
    variables,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  useEffect(() => {
    getPlayers();
  }, [getPlayers]);

  const [getChampionshipSports, { data: championshipSportsData }] = useLazyQuery<
    Query,
    QueryChampionshipSportsArgs
  >(GetChampionshipSports, {
    variables: {
      pagination: {
        page: 1,
        take: ALL_ITEMS_ON_PAGE,
      },
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  useEffect(() => {
    getChampionshipSports();
  }, [getChampionshipSports]);

  const onSearch = (e: any) => {
    const value = e.currentTarget.value;
    setSearchQuery(value);

    // reset pagination
    setSelectedPage(1);
  };

  const tableColumns = [
    {
      title: t(`${I18N_PREFIX}.name`),
      dataIndex: 'firstName',
      render: (firstName: string, player: Player) => (
        <Link to={`${ROUTES.SportsPlayers}${ROUTES.Edit}/${player.uuid}`}>
          <span className="font-weight-bold">
            {firstName} {player.lastName}
          </span>
        </Link>
      ),
      width: '40%',
    },
    {
      title: t(`${I18N_PREFIX}.championshipSports`),
      dataIndex: 'championshipSports',
      render: (championshipSports: ChampionshipSport[]) =>
        championshipSports
          ? championshipSports.map((sport) => {
              return <StyledTag>{sport.name.pt}</StyledTag>;
            })
          : null,
      width: '30%',
    },
    {
      title: t(`${I18N_PREFIX}.${GenericOrderKeys.CreatedAt}`),
      dataIndex: GenericOrderKeys.CreatedAt,
      render: (date: string) => <span>{moment(date).format('DD/MM/YYYY')}</span>,
      width: '20%',
    },
    {
      title: '',
      dataIndex: '',
      render: () => <span></span>,
      width: '1%',
    },
  ];

  const list = (data?.players.edges as Player[]) || [];
  const pagination = data?.players.pagination as Pagination;

  const championshipSports =
    (championshipSportsData?.championshipSports.edges as ChampionshipSport[]) || [];

  return (
    <Card>
      <CardWrapper>
        <div className="mb-1 d-flex">
          <div className="mr-md-3 mb-3">
            <SearchField onChange={onSearch} />
          </div>
          {championshipSportsData && (
            <div className="mr-md-3 mb-3">
              <Select
                defaultValue={selectedChampionshipSport}
                className="w-100"
                style={{ minWidth: 180 }}
                onChange={setSelectedChampionshipSport}
                placeholder={t(`${I18N_PREFIX}.type`)}
              >
                <Option value="">{t(`${I18N_PREFIX}.filter.all`)}</Option>
                {championshipSports.map((elm) => {
                  return (
                    <Option key={elm.uuid} value={elm.uuid}>
                      {t(elm.name.pt)}
                    </Option>
                  );
                })}
              </Select>
            </div>
          )}
        </div>
      </CardWrapper>
      <div className="table-responsive">
        <Table
          columns={tableColumns}
          dataSource={list}
          loading={loading}
          rowKey="uuid"
          pagination={getTablePagination(pagination, setSelectedPage)}
        />
      </div>
    </Card>
  );
};
