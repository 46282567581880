import { Layout } from 'antd';

import { MenuContent } from './menuContent';

const { Sider } = Layout;

export const SideMenu = () => {
  return (
    <Sider className="side-nav" collapsed={false}>
      <MenuContent />
    </Sider>
  );
};
