import { ColorGrey01 } from 'config/main';

import { IconProps } from './type';

export const IconDelete = ({ width, height, className, fill }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.45693 1.50895H4.323C4.39666 1.50895 4.45693 1.44868 4.45693 1.37502V1.50895H9.54621V1.37502C9.54621 1.44868 9.60648 1.50895 9.68014 1.50895H9.54621V2.7143H10.7516V1.37502C10.7516 0.784058 10.2711 0.303589 9.68014 0.303589H4.323C3.73204 0.303589 3.25157 0.784058 3.25157 1.37502V2.7143H4.45693V1.50895ZM12.8944 2.7143H1.10871C0.812395 2.7143 0.572998 2.9537 0.572998 3.25002V3.78573C0.572998 3.85939 0.633266 3.91966 0.706927 3.91966H1.71809L2.13159 12.6752C2.15838 13.2461 2.63048 13.6964 3.20135 13.6964H10.8018C11.3743 13.6964 11.8448 13.2478 11.8715 12.6752L12.2851 3.91966H13.2962C13.3699 3.91966 13.4301 3.85939 13.4301 3.78573V3.25002C13.4301 2.9537 13.1907 2.7143 12.8944 2.7143ZM10.6729 12.4911H3.33025L2.92512 3.91966H11.078L10.6729 12.4911Z"
        fill={fill || ColorGrey01}
      />
    </svg>
  );
};
