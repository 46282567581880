import { ColorGrey05 } from 'config/main';

import { IconProps } from './type';

export const IconPlus = ({ width, height, className, fill }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.99672 1.97321H8.00119C8.09047 1.97321 8.13511 2.01785 8.13511 2.10713V13.8928C8.13511 13.9821 8.09047 14.0268 8.00119 14.0268H6.99672C6.90744 14.0268 6.86279 13.9821 6.86279 13.8928V2.10713C6.86279 2.01785 6.90744 1.97321 6.99672 1.97321Z"
        fill={fill || ColorGrey05}
      />
      <path
        d="M1.87367 7.36383H13.1237C13.213 7.36383 13.2576 7.40847 13.2576 7.49776V8.50222C13.2576 8.59151 13.213 8.63615 13.1237 8.63615H1.87367C1.78439 8.63615 1.73975 8.59151 1.73975 8.50222V7.49776C1.73975 7.40847 1.78439 7.36383 1.87367 7.36383Z"
        fill={fill || ColorGrey05}
      />
    </svg>
  );
};
