/**
 * PROFILE MENU DATA
 */
export const profileMenu = [
  {
    title: 'Edit Profile',
    icon: '',
    path: '/',
  },

  {
    title: 'Account Setting',
    icon: '',
    path: '/',
  },
  {
    title: 'Billing',
    icon: '',
    path: '/',
  },
  {
    title: 'Help Center',
    icon: '',
    path: '/',
  },
];
