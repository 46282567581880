import { useLazyQuery } from '@apollo/client';
import { Card, Table, Button } from 'antd';
import { ITEMS_PER_PAGE } from 'config/main';
import { getTablePagination } from 'helpers/tablePagination';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { getCreatePage, getEditPage } from 'routes/config';
import styled from 'styled-components';

import { SearchField } from 'components/searchField';

import { IconPlus } from 'assets/icons/iconPlus';

import { GetCategoriesListQuery } from 'queries/categoriesList';
import {
  Query,
  Scalars,
  Pagination,
  OrderDirection,
  InputGenericOrder,
  QueryCategoriesArgs,
  GenericOrderKeys,
  Category,
} from 'queries/types';

const CardWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: flex-start;
`;

const LogoTitleWrapper = styled.span`
  display: flex;
  align-items: center;
`;

const LogoWrapper = styled.span`
  display: block;
  width: 18px;
  height: 18px;
  margin-right: 20px;
  overflow: hidden;
  line-height: 0;

  img {
    width: 18px;
  }
`;

const I18N_PREFIX: string = 'feedCategoriesList';

export enum ActionElementType {
  Toggle = 'Toggle',
  Button = 'Button',
}

interface Props {
  order?: InputGenericOrder;
  section?: string;
  createButton?: boolean;
}

export const CategoriesList: React.VFC<Props> = ({
  order = { key: GenericOrderKeys.CreatedAt, direction: OrderDirection.Desc },
  section,
  createButton = true,
}: Props) => {
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [searchQuery, setSearchQuery] = useState<string>('');

  const { t } = useTranslation();

  const variables: QueryCategoriesArgs = {
    filter: {
      query: searchQuery,
    },
    order: order,
    pagination: {
      page: selectedPage,
      take: ITEMS_PER_PAGE,
    },
  };

  const [getFeedCategories, { data, loading }] = useLazyQuery<Query, QueryCategoriesArgs>(
    GetCategoriesListQuery,
    {
      variables,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    // get feed posts
    getFeedCategories();
  }, [getFeedCategories]);

  const onSearch = (e: any) => {
    const value = e.currentTarget.value;
    setSearchQuery(value);

    // reset pagination
    setSelectedPage(1);
  };

  const tableColumns = [
    {
      title: t(`${I18N_PREFIX}.name`),
      dataIndex: 'name',
      render: (text: Scalars['TranslatableField'], category: Category) => (
        <LogoTitleWrapper>
          <LogoWrapper>
            {category.logo?.url && <img src={category.logo?.url} alt={text.pt} />}
          </LogoWrapper>
          <Link to={`${getEditPage(section || '', category.uuid)}`}>
            <span className="font-weight-bold">{text.pt}</span>
          </Link>
        </LogoTitleWrapper>
      ),
      width: '50%',
    },
    {
      title: t(`${I18N_PREFIX}.${GenericOrderKeys.CreatedAt}`),
      dataIndex: GenericOrderKeys.CreatedAt,
      render: (date: string) => <span>{moment(date).format('DD/MM/YYYY')}</span>,
    },
    {
      title: '',
      dataIndex: '',
      render: () => <span></span>,
    },
  ];

  const list = (data?.categories.edges as Category[]) || [];
  const pagination = data?.categories.pagination as Pagination;

  const history = useHistory();
  const goToCreatePage = () => history.push(getCreatePage(section || ''));

  return (
    <Card>
      <CardWrapper>
        <div className="mb-1 d-flex">
          <div className="mr-md-3 mb-3">
            <SearchField onChange={onSearch} />
          </div>
        </div>
        {createButton && (
          <div>
            <Button
              onClick={goToCreatePage}
              type="primary"
              icon={<IconPlus className={'anticon'} width="15" height="16" />}
              block
            >
              {t(`${I18N_PREFIX}.actions.create`)} {t('Category')}
            </Button>
          </div>
        )}
      </CardWrapper>
      <div className="table-responsive">
        <Table
          columns={tableColumns}
          dataSource={list}
          loading={loading}
          rowKey="uuid"
          pagination={getTablePagination(pagination, setSelectedPage)}
        />
      </div>
    </Card>
  );
};
