import {
  FeedPost,
  FeedPostOrigin,
  FeedPostPage,
  FeedPostType,
  InputFeedPosts,
} from 'queries/types';

export const getPagePositions = (t: any): [{ label: string; value: string }] => {
  const options: any = [
    {
      label: t('advertisementList.pages.Highlights'),
      value: `${FeedPostPage.Highlights}|-1`,
    },
    {
      label: t('advertisementList.pages.News1'),
      value: `${FeedPostPage.News}|2`,
    },
    {
      label: t('advertisementList.pages.News2'),
      value: `${FeedPostPage.News}|6`,
    },
    {
      label: t('advertisementList.pages.Social1'),
      value: `${FeedPostPage.Social}|2`,
    },
    {
      label: t('advertisementList.pages.Social2'),
      value: `${FeedPostPage.Social}|6`,
    },
  ];

  return options;
};

export const transformFormValues = (values: any): InputFeedPosts => {
  const pagePosition = values.placement.split('|');
  const page = pagePosition[0] as FeedPostPage;
  const position = parseInt(pagePosition[1]) || -1;

  return {
    note: {
      pt: values.note || '',
    },
    title: {
      pt: values.title || '',
    },
    subtitle: {
      pt: values.subtitle || '',
    },
    cta: values.cta,
    type:
      values.blocks && values.blocks[0] && values.blocks[0].type === 'video'
        ? FeedPostType.Video
        : FeedPostType.Gallery,
    origin: FeedPostOrigin.Pub,
    highlight: {
      highlighted: page === FeedPostPage.Highlights,
      position: 0,
      stretch: true,
      image: null,
    },
    published: {
      app: true,
      site: true,
    },
    blocks: transformBlocks(values.blocks || []),
    advertisement: {
      page: page,
      position: position,
      startDate: values.startDate,
      endDate: values.endDate,
    },
  };
};

interface BlocksProps {
  time: number;
  blocks: any;
  version: string;
}

const transformBlocks = (blocks: any): { pt: BlocksProps } => {
  return {
    pt: {
      time: new Date().getTime(),
      blocks,
      version: '1.9.0',
    },
  };
};

export const transformInitialValues = (data: FeedPost): any => {
  const { title, subtitle, note, blocks, advertisement, cta } = data;

  return {
    title: title.pt,
    note: note && note.pt,
    subtitle: subtitle.pt,
    blocks: blocks.pt ? blocks.pt.blocks : null,
    placement: `${advertisement?.page}|${advertisement?.position}`,
    startDate: advertisement?.startDate,
    endDate: advertisement?.endDate,
    cta: cta,
  };
};

export const capitalizeString = (value: string) => {
  return value.replace(/^\w/, (firstLetter) => firstLetter.toUpperCase());
};
