import { gql } from '@apollo/client';

export const GetPlayers = gql`
  query GetPlayers($filter: InputPlayersFilter, $pagination: InputPagePagination) {
    players(filter: $filter, pagination: $pagination) {
      pagination {
        pageIndex
        pageCount
      }
      edges {
        uuid
        firstName
        lastName
        identification
        createdAt
        championshipSports {
          uuid
          name
        }
      }
    }
  }
`;

export const AddTeamToPlayer = gql`
  mutation AddTeamToPlayer($playerUuid: UUID!, $teamUuid: UUID!) {
    addTeamToPlayer(playerUuid: $playerUuid, teamUuid: $teamUuid) {
      uuid
    }
  }
`;
