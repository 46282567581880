import { Button, Modal } from 'antd';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  title: string;
  message: string;
  isLoading: boolean;
  showModal: boolean;
  onClose: () => void;
  onRemoveConfirm: () => void;
}

export const RemoveModal = ({
  title,
  message,
  isLoading,
  showModal,
  onClose,
  onRemoveConfirm,
}: Props) => {
  const { t } = useTranslation();

  /**
   * Handles confirm button click
   * Calls onRemoveConfirm method that fires the mutation
   */
  const handleOk = useCallback(() => {
    onRemoveConfirm();
  }, [onRemoveConfirm]);

  /**
   * Handles modal close button click
   * Closes the modal without any mutation happening
   */
  const handleCancel = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Modal
      title={title}
      visible={showModal}
      onCancel={handleCancel}
      centered={true}
      footer={[
        <Button key="submit" type="primary" loading={isLoading} onClick={handleOk}>
          {t('actions.confirm')}
        </Button>,
      ]}
    >
      <div>{message}</div>
    </Modal>
  );
};
