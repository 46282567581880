import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { ROUTES } from 'routes/config';
import styled from 'styled-components';

import { PageHeader } from 'components/pageHeader';
import { SportsChampionshipsList } from 'components/sportsChampionshipsList';

// import { SportsTeamsList } from 'components/sportsTeamsList';
import { IconPlus } from 'assets/icons/iconPlus';

const StyledPageHeader = styled(PageHeader)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
`;

export const SportsChampionships = () => {
  const { t } = useTranslation();

  const routes = [
    {
      path: ROUTES.Sports,
      breadcrumbName: t('mainMenu.sports.default'),
    },
    {
      path: ROUTES.SportsChampionships,
      breadcrumbName: t(`tabsMenu.sports.championships`),
    },
  ];

  const history = useHistory();
  const createRoute = `${ROUTES.SportsChampionships}${ROUTES.Create}`;

  return (
    <>
      <StyledPageHeader
        title={t('mainMenu.sports.championships')}
        breadcrumbs={{ routes, separator: '/' }}
      >
        <Button
          onClick={() => history.push(createRoute)}
          type="primary"
          icon={<IconPlus className={'anticon'} width="15" height="16" />}
        >
          {t(`sportsChampionshipsList.actions.create`)}
        </Button>
      </StyledPageHeader>
      <SportsChampionshipsList />
    </>
  );
};
