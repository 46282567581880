export const Logo = () => {
  return (
    <svg width='59' height='25' viewBox='0 0 59 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.14397 3.69843C8.76472 3.58732 8.42762 3.50795 8.11861 3.44446C7.77702 3.36276 7.42829 3.32548 7.07921 3.33335C6.86389 3.32927 6.65 3.37346 6.44989 3.46337C6.24979 3.55327 6.06743 3.68712 5.91338 3.85716C5.74509 4.05104 5.61444 4.28247 5.52979 4.53665C5.44514 4.79084 5.40835 5.06218 5.42177 5.33335V6.4762H9.08778L8.849 9.53969H5.42177V24H2.06476V9.53969H0V6.4762H2.06476V5.33335C2.04665 4.52781 2.19579 3.72869 2.50019 3.00002C2.78803 2.39048 3.17943 1.85109 3.65197 1.41272C4.12366 0.938411 4.6722 0.571869 5.26727 0.333351C5.86071 0.110909 6.48206 -0.00165358 7.1073 1.83544e-05H7.83768C8.09725 0.032147 8.35513 0.0798332 8.61022 0.142875L9.29847 0.301605L9.81818 0.460335L9.14397 3.69843Z'
        fill='#171A23'
      />
      <path
        d='M25.1818 24.1655H21.9144L21.4733 23.755C21.015 23.9412 20.546 24.1036 20.0684 24.2415C19.4987 24.3856 18.9106 24.4572 18.3203 24.4544C17.3579 24.4601 16.4018 24.311 15.494 24.0135C14.6419 23.7287 13.859 23.2895 13.1905 22.7212C12.4963 22.1461 11.9397 21.4417 11.5568 20.6536C11.1711 19.7867 10.982 18.8551 11.0014 17.917V13.4472C10.9911 12.569 11.1804 11.6986 11.5568 10.8931C11.9473 10.1136 12.503 9.41544 13.1905 8.84067C13.8668 8.26703 14.6466 7.80897 15.494 7.48758C16.3866 7.1532 17.3419 6.98781 18.304 7.00108C19.2663 6.98491 20.2222 7.1504 21.1139 7.48758C21.9229 7.79959 22.6563 8.25973 23.2704 8.84067C23.8798 9.42596 24.3477 10.1257 24.6427 10.8931C24.9743 11.709 25.1405 12.575 25.1328 13.4472L25.1818 24.1655ZM18.1079 10.2242C17.6624 10.2122 17.2192 10.2887 16.8079 10.4486C16.3966 10.6085 16.0267 10.8481 15.7227 11.1516C15.0933 11.7702 14.7526 12.5956 14.7752 13.4472V17.917C14.7455 18.3705 14.8142 18.8249 14.9769 19.2532C15.1397 19.6815 15.3933 20.075 15.7227 20.4103C16.0385 20.694 16.4124 20.9157 16.8222 21.0619C17.232 21.2082 17.6693 21.2762 18.1079 21.2617C18.7448 21.2644 19.3802 21.2032 20.003 21.0793C20.5167 20.9661 21.0194 20.8136 21.506 20.6232V13.4472C21.543 13.0057 21.4759 12.5619 21.3095 12.147C21.143 11.7321 20.8811 11.356 20.5421 11.0451C20.2218 10.765 19.8439 10.5479 19.4313 10.4068C19.0186 10.2657 18.5797 10.2036 18.1406 10.2242H18.1079Z'
        fill='#171A23'
      />
      <path
        d='M41.4538 17.6059C41.4676 18.4865 41.2951 19.3598 40.9481 20.1664C40.5931 20.9349 40.0868 21.6208 39.4607 22.1816C38.8666 22.7594 38.17 23.217 37.4081 23.53C36.6073 23.8485 35.7541 24.0079 34.8945 23.9997C34.03 24.008 33.1719 23.8486 32.366 23.53C30.8166 22.9133 29.5632 21.7071 28.8706 20.1664C28.5236 19.3598 28.3511 18.4865 28.3649 17.6059V13.1514C28.347 12.2312 28.5193 11.3175 28.8706 10.4696C29.1932 9.70775 29.6659 9.02149 30.2595 8.45333C30.8531 7.88518 31.5548 7.44722 32.3213 7.1666C33.6652 6.69496 35.1089 6.60075 36.5008 6.89388C36.9496 7.00357 37.3916 7.14017 37.8246 7.30297V1.09093H41.3794L41.4538 17.6059ZM37.899 10.515C37.4668 10.3415 37.0248 10.1947 36.5752 10.0757C36.0472 9.96146 35.5086 9.90559 34.9689 9.90899C34.2101 9.88351 33.4704 10.1545 32.9014 10.6666C32.6093 10.9925 32.3849 11.3753 32.2417 11.7919C32.0986 12.2086 32.0397 12.6504 32.0685 13.0908V17.5453C32.0435 17.9761 32.1044 18.4076 32.2476 18.8139C32.3907 19.2201 32.6131 19.5926 32.9014 19.9089C33.1721 20.2058 33.5018 20.4404 33.8686 20.5972C34.2354 20.754 34.6307 20.8292 35.0283 20.8179C35.4215 20.8288 35.8124 20.7532 36.1743 20.5964C36.5362 20.4395 36.8606 20.205 37.1255 19.9089C37.4071 19.6003 37.6258 19.2378 37.7688 18.8425C37.9118 18.4471 37.9762 18.0268 37.9585 17.6059L37.899 10.515Z'
        fill='#171A23'
      />
      <path
        d='M58.342 20.1452C57.979 20.9179 57.4621 21.6148 56.8203 22.1969C56.158 22.7739 55.3894 23.2272 54.5539 23.5335C53.6844 23.8463 52.7628 24.0043 51.8343 23.9998C50.8951 24.0057 49.9626 23.8477 49.0822 23.5335C48.2467 23.2272 47.4781 22.7739 46.8159 22.1969C46.1681 21.6198 45.6503 20.9216 45.2941 20.1452C44.9107 19.3245 44.7174 18.4336 44.7276 17.5341V6.65423H48.5966V17.6118C48.5745 18.0368 48.6436 18.4617 48.7996 18.86C48.9555 19.2584 49.195 19.6218 49.5031 19.9276C49.8063 20.2226 50.1702 20.4538 50.5715 20.6063C50.9729 20.7588 51.4029 20.8294 51.8343 20.8136C52.2618 20.8259 52.6872 20.7521 53.0832 20.5968C53.4791 20.4415 53.8368 20.2083 54.133 19.9121C54.4363 19.6068 54.6716 19.2453 54.8247 18.8499C54.9777 18.4544 55.0453 18.0332 55.0234 17.6118V6.54543H58.9086V17.5341C58.9187 18.4336 58.7254 19.3245 58.342 20.1452Z'
        fill='#171A23'
      />
    </svg>
  );
};
