import { LoadingOutlined } from '@ant-design/icons';
import { Input, Row, Col, Card, Form, Upload, FormInstance } from 'antd';
import { ColorGrey05 } from 'config/main';
import { useUploadFile } from 'hooks/useUploadFile';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { IconDelete } from 'assets/icons/iconDelete';
import { IconImage } from 'assets/icons/iconImage';

import { Image } from 'queries/types';

type CategoriesFormProps = {
  form: FormInstance;
  props: any;
};

const DeleteButton = styled.button`
  background: #000;
  border: none;
  border-radius: 50%;
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  margin: -53px 0 0 15px;
`;

export const CategoriesForm = ({ form }: CategoriesFormProps) => {
  const { t } = useTranslation();
  const { Dragger } = Upload;

  const [logoData, setLogoData] = useState<Image | null>(form.getFieldValue('logo'));

  const imageUploadProps: any = {
    name: 'file',
    multiple: false,
    listType: 'picture-card',
    showUploadList: false,
  };

  const I18N_PREFIX = 'pressForm';
  const I18N_PREFIX_FIELDS = `${I18N_PREFIX}.fields`;
  const I18N_PREFIX_SECTIONS = `${I18N_PREFIX}.sections`;

  const {
    isUploadFileLoading: isLogoLoading,
    uploadFileData: logo,
    uploadFile: uploadLogo,
  } = useUploadFile();

  const handleUploadChange = (info: any) => {
    uploadLogo(info.fileList[0].originFileObj);
  };
  useEffect(() => {
    form.setFieldsValue({
      logo: logo?.data ? logo.data : form.getFieldValue('logo'),
    });
    setLogoData(form.getFieldValue('logo'));
  }, [logo]);

  const handleRemoveLogo = () => {
    form.setFieldsValue({
      logo: null,
    });
    setLogoData(null);
  };

  return (
    <Row gutter={16}>
      <Col xs={24} sm={24} md={17}>
        <Card title={t(`${I18N_PREFIX_SECTIONS}.basicInfo`)}>
          <Form.Item
            name="name"
            label={t(`${I18N_PREFIX_FIELDS}.name.field`)}
            rules={[{ required: true, message: t(`${I18N_PREFIX_FIELDS}.name.errorMessage`) }]}
          >
            <Input placeholder={t(`${I18N_PREFIX_FIELDS}.name.placeholder`)} />
          </Form.Item>
        </Card>
      </Col>
      <Col xs={24} sm={24} md={7}>
        <Card title={t(`${I18N_PREFIX_SECTIONS}.media`)}>
          <Form.Item label={t(`${I18N_PREFIX_FIELDS}.logo.field`)} name="logo">
            <Input type="hidden" />
            <Dragger
              {...imageUploadProps}
              beforeUpload={() => false}
              onChange={(e) => handleUploadChange(e)}
            >
              {isLogoLoading ? (
                <div>
                  <LoadingOutlined className="font-size-xxl text-primary" />
                  <div className="mt-3">{t(`${I18N_PREFIX_FIELDS}.logo.uploading`)}</div>
                </div>
              ) : logoData ? (
                <img src={logoData.url || ''} alt="avatar" className="img-fluid" />
              ) : (
                <div>
                  <IconImage width="64" height="64" />
                  <p>{t(`${I18N_PREFIX_FIELDS}.logo.placeholder`)}</p>
                </div>
              )}
            </Dragger>
            {logoData && (
              <DeleteButton onClick={handleRemoveLogo}>
                <IconDelete width="12" height="13" fill={ColorGrey05} />
              </DeleteButton>
            )}
          </Form.Item>
        </Card>
      </Col>
    </Row>
  );
};
