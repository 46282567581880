import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import commonPT from './translations/pt.json';

i18n.use(initReactI18next).init({
  debug: true,
  lng: 'pt',
  fallbackLng: 'pt',
  resources: {
    pt: {
      translation: commonPT,
    },
  },
  interpolation: {
    escapeValue: false,
    format: function (value, format) {
      if (format === 'uppercase') return value.toUpperCase();
      if (format === 'capitalize') return `${value.charAt(0).toUpperCase()}${value.substr(1)}`;
      if (format === 'lowercase') return value.toLowerCase();
    },
  },
});

export default i18n;
