import { getAuthEndpoint, getResetPasswordEndpoint } from 'config/api';
import { useAuthDispatch } from 'context/AuthContext';
import qs from 'query-string';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'routes/config';

export const Login = () => {
  const history = useHistory();
  const authDispatch = useAuthDispatch();
  const { id_token, error_description } = qs.parse(window.location.search);

  useEffect(() => {
    // User is recovering password
    if (error_description?.includes('AADB2C')) {
      window.location.href = getResetPasswordEndpoint();
      return;
    }

    // User was redirected with the auth token back to the app
    if (id_token) {
      authDispatch({ type: 'LOGIN', payload: id_token as string });
      history.push(ROUTES.Highlights);
      return;
    }

    // Normal login screen - redirects to the auth url
    window.location.href = getAuthEndpoint();
  });

  return null;
};
