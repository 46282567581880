import { gql } from '@apollo/client';

export const GetManagementEntities = gql`
  query($pagination: InputPagePagination) {
    managementEntities(pagination: $pagination) {
      edges {
        uuid
        name
      }
    }
  }
`;
