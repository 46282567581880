import { gql } from '@apollo/client';

export const GetAuthors = gql`
  query {
    authors {
      uuid
      profile {
        firstName
        lastName
        picture
      }
    }
  }
`;
