import { gql } from '@apollo/client';

export const GetChampionshipCompetitions = gql`
  query GetChampionshipCompetitions(
    $filter: InputChampionshipCompetitionsFilter!
    $pagination: InputPagePagination
  ) {
    championshipCompetitions(filter: $filter, pagination: $pagination) {
      pagination {
        pageIndex
        pageCount
      }
      edges {
        uuid
        name
        championshipSport {
          name
        }
        championship {
          uuid
        }
      }
    }
  }
`;

export const CreateChampionshipCompetition = gql`
  mutation CreateChampionshipCompetition($input: InputChampionshipCompetition!) {
    createChampionshipCompetition(input: $input) {
      uuid
    }
  }
`;

export const UpdateChampionshipCompetition = gql`
  mutation UpdateChampionshipCompetition($uuid: UUID!, $input: InputChampionshipCompetition!) {
    updateChampionshipCompetition(uuid: $uuid, input: $input) {
      uuid
    }
  }
`;

export const GetChampionshipCompetition = gql`
  query GetChampionshipCompetition($uuid: UUID!) {
    championshipCompetition(uuid: $uuid) {
      uuid
      name
      championshipSport {
        uuid
      }
    }
  }
`;

export const DeleteChampionshipCompetition = gql`
  mutation DeleteChampionshipCompetition($uuid: UUID!) {
    deleteChampionshipCompetition(uuid: $uuid) {
      uuid
    }
  }
`;
