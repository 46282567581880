import moment from 'moment';

import {
  ChampionshipEvent,
  ChampionshipMatchEntity,
  ChampionshipMatchEntityPlayer,
  ClockDirection,
  ClockStatus,
  InputEventData,
  Player,
  Scalars,
  Team,
} from 'queries/types';

export const generateTimeByStatus = (
  status: ClockStatus,
  lastValue: number,
  lastTimestamp: Scalars['DateTime'],
  direction: ClockDirection
) => {
  switch (status) {
    // PLAY
    case ClockStatus.Play:
      const timeDiff = Date.now() - lastTimestamp;
      const newClockValue = lastValue + (direction === ClockDirection.Asc ? timeDiff : -timeDiff);
      return `${getDurationAsMinutes(newClockValue < 0 ? 0 : newClockValue)}${moment(
        newClockValue < 0 ? 0 : newClockValue
      ).format(':ss')}`;

    // STOP and PAUSE
    default:
      return `${getDurationAsMinutes(lastValue)}${moment(lastValue).format(':ss')}`;
  }
};

const getDurationAsMinutes = (value: number) => {
  return Math.floor(moment.duration(value).asMinutes());
};

export const championshipEventsToOptions = (
  events: ChampionshipEvent[]
): [{ label: string; value: string }] => {
  const options: any = [];

  events.forEach((event: any) => {
    options.push({
      label: event?.name?.pt || event.key,
      value: event.key,
    });
  });
  return options;
};

export const teamsToOptions = (
  teams: ChampionshipMatchEntity[]
): [{ label: string; value: string }] => {
  const options: any = [];

  teams &&
    teams.forEach((team: ChampionshipMatchEntity) => {
      options.push({
        label: team.championshipEntity.championshipTeam.team.name,
        value: team.uuid,
      });
    });
  return options;
};

export const playersToOptions = (
  players: ChampionshipMatchEntityPlayer[]
): [{ label: string; value: string }] => {
  const options: any = [];

  players &&
    players.forEach((player: ChampionshipMatchEntityPlayer) => {
      const playerNumber = player.number ? `${player.number} - ` : '';

      options.push({
        label: `${playerNumber}${player.championshipEntityPlayer.player.firstName} ${player.championshipEntityPlayer.player.lastName}`,
        value: player.uuid,
      });
    });
  return options;
};

export const generateAddEventFields = (fields: any): [] => {
  let fieldsArray: any = [];

  if (fields) {
    const mandatory = Object.entries(fields?.configs.mandatoryFields);
    const optional = Object.entries(fields?.configs.optionalFields);

    mandatory.forEach((field) => {
      fieldsArray.push({
        field: field[0],
        required: true,
      });
    });

    optional.forEach((field) => {
      fieldsArray.push({
        field: field[0],
        required: false,
      });
    });
  }

  return fieldsArray;
};

export const isFieldToShow = (fields: any, field: string): any => {
  return (
    (fields &&
      (Object.keys(fields?.configs.mandatoryFields).find((a) => a === field) ||
        Object.keys(fields?.configs.optionalFields).find((a) => a === field))) ||
    false
  );
};

export const isFieldMandatory = (fields: any, field: string): any => {
  return (fields && Object.keys(fields?.configs.mandatoryFields).find((a) => a === field)) || false;
};

export const getFieldDefault = (fields: any, field: string): any => {
  return fields?.configs.defaults[field] || '';
};

export const transformFormValues = (configFields: any, values: any): InputEventData => {
  const {
    championshipEventKey,
    championshipMatchEntityUuid,
    championshipMatchEntityPlayerUuid,
    auxChampionshipMatchEntityPlayerUuid,
    title,
    subtitle,
    value,
    time,
  } = values;
  return {
    championshipEventKey,
    championshipMatchEntityUuid: championshipMatchEntityUuid || null,
    championshipMatchEntityPlayerUuid: championshipMatchEntityPlayerUuid || null,
    auxChampionshipMatchEntityPlayerUuid: auxChampionshipMatchEntityPlayerUuid || null,
    title: title || null,
    subtitle: subtitle || null,
    value: value !== null ? parseFloat(value || getFieldDefault(configFields, 'value')) : null,
    time: time !== null ? minutesToMiliseconds(parseFloat(time)) : null,
  };
};

export const getFormInititalValues = (
  fields: any,
  time: string,
  championshipEventKey?: string | undefined | null
): InputEventData => {
  if (!fields) {
    return {
      championshipEventKey: championshipEventKey || '',
    };
  }

  return {
    championshipEventKey: championshipEventKey || '',
    championshipMatchEntityUuid: getFieldDefault(fields, 'championshipMatchEntityUuid'),
    championshipMatchEntityPlayerUuid: getFieldDefault(fields, 'championshipMatchEntityPlayerUuid'),
    auxChampionshipMatchEntityPlayerUuid: getFieldDefault(
      fields,
      'auxChampionshipMatchEntityPlayerUuid'
    ),
    title: getFieldDefault(fields, 'title'),
    subtitle: getFieldDefault(fields, 'subtitle'),
    value: getFieldDefault(fields, 'value'),
    time: parseInt((time && time.split(':')[0]) || '0') + 1,
  };
};

export const milisecondToMinutes = (time: number): number => {
  return Math.floor(time / 60000);
};

export const minutesToMiliseconds = (time: number): number => {
  return Math.floor(time * 60000);
};
