import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { OrderHighlightsMutation } from 'queries/mutations';
import {
  Mutation,
  MutationOrderFeedPostHighlightsArgs,
  OrderFeedPostsHighlightsArgs,
} from 'queries/types';

export function useOrderingMutation() {
  const [orderHighlights, { loading: isOrdering }] = useMutation<
    Mutation,
    MutationOrderFeedPostHighlightsArgs
  >(OrderHighlightsMutation);

  const saveOrder = useCallback((order: OrderFeedPostsHighlightsArgs[], cb?: () => void) => {
    orderHighlights({
      variables: { order },
    })
      .then(() => {
        cb && cb();
      })
      .catch((err) => {
        console.log('OrderingHighlights Error:', err);
      });
  }, []);

  return {
    saveOrder,
    isOrdering,
  };
}
