import { Switch } from 'antd';
import { SwitchChangeEventHandler } from 'antd/lib/switch';
import { MouseEventHandler } from 'react';
import styled from 'styled-components';

const SwitchWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledButton = styled.button`
  border: 1px solid #eef2f8;
  background: white;
  border-radius: 10px;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export enum ListActionElementType {
  Toggle = 'Toggle',
  Button = 'Button',
}

interface ListActionElementProps {
  type: ListActionElementType;
  action: MouseEventHandler | SwitchChangeEventHandler;
  checked?: boolean | null | undefined;
  disabled?: boolean | null | undefined;
}

export const ListActionElement: React.FC<ListActionElementProps> = ({
  type,
  action,
  checked,
  disabled,
  children,
}) => {
  switch (type) {
    case ListActionElementType.Button:
      return (
        <ButtonWrapper>
          <StyledButton onClick={action as MouseEventHandler}>{children}</StyledButton>
        </ButtonWrapper>
      );
    default:
      return (
        <SwitchWrapper>
          <Switch
            defaultChecked={checked || false}
            onChange={action as SwitchChangeEventHandler}
            disabled={disabled || false}
          />
        </SwitchWrapper>
      );
  }
};
