import { Input, Row, Col, Card, Form, FormInstance, Select } from 'antd';
import { useTranslation } from 'react-i18next';

import { ChampionshipCompetition, ConfigGroupPoints } from 'queries/types';

export enum FormMode {
  ADD = 'add',
  EDIT = 'edit',
}

type FormProps = {
  form: FormInstance;
  mode: FormMode;
  championshipCompetitions?: ChampionshipCompetition[];
  championshipStagesConfigGroupPoints: ConfigGroupPoints[];
  props: any;
};

export const SportsChampionshipStageForm = ({
  form,
  mode,
  championshipCompetitions,
  championshipStagesConfigGroupPoints,
}: FormProps) => {
  const { t } = useTranslation();

  const I18N_PREFIX = 'sportsChampionshipStageForm';
  const I18N_PREFIX_FIELDS = `${I18N_PREFIX}.fields`;

  return (
    <Row gutter={16}>
      <Col xs={24}>
        <Card>
          <Form.Item
            name="name"
            label={t(`${I18N_PREFIX_FIELDS}.name.field`)}
            rules={[{ required: true, message: t(`${I18N_PREFIX_FIELDS}.name.errorMessage`) }]}
          >
            <Input placeholder={t(`${I18N_PREFIX_FIELDS}.name.placeholder`)} />
          </Form.Item>

          {championshipCompetitions && (
            <Form.Item
              name="championshipCompetition"
              label={t(`${I18N_PREFIX_FIELDS}.competition.field`)}
              rules={[
                { required: true, message: t(`${I18N_PREFIX_FIELDS}.competition.errorMessage`) },
              ]}
            >
              <Select
                className="w-100"
                placeholder={t(`${I18N_PREFIX_FIELDS}.competition.placeholder`)}
                options={championshipCompetitions?.map((competition: ChampionshipCompetition) => {
                  return { label: competition.name.pt, value: competition.uuid };
                })}
              />
            </Form.Item>
          )}

          {championshipStagesConfigGroupPoints && (
            <Form.Item
              name="configGroupPoints"
              label={t(`${I18N_PREFIX_FIELDS}.configGroupPoints.field`)}
            >
              <Select
                className="w-100"
                placeholder={t(`${I18N_PREFIX_FIELDS}.configGroupPoints.placeholder`)}
                options={[
                  {
                    label: t(`${I18N_PREFIX_FIELDS}.configGroupPoints.options.null`),
                    value: '',
                  },
                  ...championshipStagesConfigGroupPoints?.map(
                    (configGroupPoints: ConfigGroupPoints) => {
                      return {
                        label: t(
                          `${I18N_PREFIX_FIELDS}.configGroupPoints.options.${configGroupPoints.id}`
                        ),
                        value: configGroupPoints.id,
                      };
                    }
                  ),
                ]}
              />
            </Form.Item>
          )}
        </Card>
      </Col>
    </Row>
  );
};
