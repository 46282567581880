import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Form } from 'antd';
import { Store } from 'rc-field-form/lib/interface';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { getCreatePage, ROUTES } from 'routes/config';
import styled from 'styled-components';

import { CategoriesForm } from './categoriesForm';
import { transformFormValues, transformInitialValues } from './utils';

import { PageHeader } from 'components/pageHeader';
import { RemoveModal } from 'components/removeModal';
import { SubmitButton } from 'components/submitButton';

import { IconDelete } from 'assets/icons/iconDelete';

import { CreateCategory, DeleteCategory, UpdateCategory } from 'queries/categoriesMutations';
import { GetCategory } from 'queries/category';
import {
  Mutation,
  MutationCreateCategoryArgs,
  MutationDeleteCategoryArgs,
  MutationUpdateCategoryArgs,
  Query,
  QueryCategoryArgs,
} from 'queries/types';

const StyledPageHeader = styled(PageHeader)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const I18N_PREFIX = 'pressForm';

export const CategoriesCreateEdit = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const published: React.MutableRefObject<boolean> = React.useRef(true);
  const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);

  const { uuid } = useParams<{ uuid: string }>();
  const section = 'categories';

  const routes = [
    {
      path: ROUTES.PressFeed,
      breadcrumbName: t('mainMenu.content.press'),
    },
    {
      path: ROUTES.PressCategories,
      breadcrumbName: t(`tabsMenu.press.${section}`),
    },
    {
      path: getCreatePage(section),
      breadcrumbName: t(`${I18N_PREFIX}.header.${section}.${uuid ? 'edit' : 'create'}`),
    },
  ];

  const [getCategory, { data: category }] = useLazyQuery<Query, QueryCategoryArgs>(GetCategory, {
    variables: {
      uuid,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (uuid) getCategory();
  }, [uuid]);

  const [form] = Form.useForm();

  const [createCategory, { loading: isCreating, data: isCreated }] = useMutation<
    Mutation,
    MutationCreateCategoryArgs
  >(CreateCategory);
  const [updateCategory, { loading: isSaving }] = useMutation<Mutation, MutationUpdateCategoryArgs>(
    UpdateCategory
  );
  const [deleteCategory, { loading: isRemoving, data: isRemoved }] = useMutation<
    Mutation,
    MutationDeleteCategoryArgs
  >(DeleteCategory);

  useEffect(() => {
    if (isRemoved) history.push(ROUTES.PressCategories);
  }, [isRemoved]);

  useEffect(() => {
    if (isCreated)
      history.push(`${ROUTES.PressCategories}/${ROUTES.Edit}/${isCreated?.createCategory.uuid}`);
  }, [isCreated]);

  const onFinish = async (values: any) => {
    console.log('Success:', {
      values: values,
      transformed: transformFormValues(values),
    });

    if (uuid) {
      updateCategory({
        variables: {
          uuid,
          input: transformFormValues(values),
        },
      });
    } else {
      //
      createCategory({
        variables: {
          input: transformFormValues(values),
        },
      });
    }

    published.current = true;
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  if (uuid && !category?.category) return null;

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        name="press-form"
        className="press-create-edit-form"
        initialValues={
          category?.category ? (transformInitialValues(category?.category) as Store) : undefined
        }
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <StyledPageHeader
          title={t(`${I18N_PREFIX}.header.${section}.${uuid ? 'edit' : 'create'}`)}
          breadcrumbs={{ routes, separator: '/' }}
        >
          <ButtonsWrapper>
            {uuid && (
              <Button
                className="mr-2"
                icon={<IconDelete width="14" height="14" className={'anticon'} />}
                htmlType="reset"
                onClick={() => setShowRemoveModal(true)}
              >
                {t(`${I18N_PREFIX}.reset`)}
              </Button>
            )}
            <SubmitButton isLoading={isCreating || isSaving} label={t(`${I18N_PREFIX}.submit`)} />
          </ButtonsWrapper>
        </StyledPageHeader>
        <CategoriesForm form={form} props />
      </Form>
      <RemoveModal
        title={t('modals.categoriesRemoveModal.title')}
        message={t('modals.categoriesRemoveModal.message')}
        isLoading={isRemoving}
        showModal={showRemoveModal}
        onClose={() => setShowRemoveModal(false)}
        onRemoveConfirm={() =>
          deleteCategory({
            variables: {
              uuid,
            },
          })
        }
      />
    </>
  );
};
