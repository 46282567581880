import { gql } from '@apollo/client';

export const UpdateHighlightStretch = gql`
  mutation UpdateHighlightStretch($uuid: UUID!, $stretch: Boolean!) {
    stretchHighlightFeedPost(uuid: $uuid, stretch: $stretch) {
      uuid
      highlight {
        stretch
      }
    }
  }
`;
