import { ColorGrey01 } from 'config/main';

import { IconProps } from './type';

export const IconSearch = ({ width, height, className, fill }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox={`0 0 16 16`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.0983 14.1161L10.4608 9.47861C11.1805 8.54825 11.5698 7.41075 11.5698 6.21432C11.5698 4.78218 11.0108 3.43932 10.0001 2.42682C8.9894 1.41432 7.64297 0.857178 6.21261 0.857178C4.78225 0.857178 3.43583 1.41611 2.42511 2.42682C1.41261 3.43753 0.855469 4.78218 0.855469 6.21432C0.855469 7.64468 1.4144 8.99111 2.42511 10.0018C3.43583 11.0143 4.78047 11.5715 6.21261 11.5715C7.40904 11.5715 8.54475 11.1822 9.47511 10.4643L14.1126 15.1C14.1262 15.1136 14.1424 15.1244 14.1601 15.1318C14.1779 15.1392 14.1969 15.143 14.2162 15.143C14.2354 15.143 14.2545 15.1392 14.2722 15.1318C14.29 15.1244 14.3062 15.1136 14.3198 15.1L15.0983 14.3232C15.1119 14.3097 15.1227 14.2935 15.1301 14.2757C15.1375 14.258 15.1412 14.2389 15.1412 14.2197C15.1412 14.2004 15.1375 14.1814 15.1301 14.1636C15.1227 14.1459 15.1119 14.1297 15.0983 14.1161ZM9.04118 9.04289C8.28404 9.79825 7.28047 10.2143 6.21261 10.2143C5.14475 10.2143 4.14118 9.79825 3.38404 9.04289C2.62868 8.28575 2.21261 7.28218 2.21261 6.21432C2.21261 5.14646 2.62868 4.14111 3.38404 3.38575C4.14118 2.63039 5.14475 2.21432 6.21261 2.21432C7.28047 2.21432 8.28583 2.62861 9.04118 3.38575C9.79654 4.14289 10.2126 5.14646 10.2126 6.21432C10.2126 7.28218 9.79654 8.28754 9.04118 9.04289Z"
        fill={fill || ColorGrey01}
      />
    </svg>
  );
};
