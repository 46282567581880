import { BreadcrumbProps, PageHeader as Header } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

type Props = {
  title: string;
  whiteBackground?: boolean;
  children?: any;
  className?: any;
  breadcrumbs?: BreadcrumbProps;
};

const StyledHeader = styled(Header)`
  padding-left: 0;
`;

export const PageHeader = ({
  title,
  children,
  whiteBackground = false,
  className,
  breadcrumbs,
}: Props) => {
  return (
    <div className={`${className} ${whiteBackground ? 'page-header-alt' : ''}`}>
      <StyledHeader
        title={<h3 className="mb-0 mr-3 font-weight-semibold">{title}</h3>}
        breadcrumb={{ ...breadcrumbs, itemRender }}
      />
      {children}
    </div>
  );
};

function itemRender(route: any, _params: any, routes: any, _paths: any) {
  const last = routes.indexOf(route) === routes.length - 1;
  return last ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={route.path}>{route.breadcrumbName}</Link>
  );
}
