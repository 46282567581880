import { ColorBlack01 } from 'config/main';

import { IconProps } from './type';

export const IconAngleRight = ({ width, height, className, fill }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 8 11"
      fill={fill || ColorBlack01}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.25 0.360179L0.25 1.39638C0.25 1.46684 0.286621 1.53316 0.346679 1.57461L6.08301 5.49974L0.34668 9.42487C0.286621 9.46632 0.25 9.53263 0.25 9.6031L0.25 10.6393C0.25 10.7291 0.358399 10.7816 0.436035 10.7291L7.55664 5.85757C7.81445 5.68073 7.81445 5.31875 7.55664 5.14328L0.436035 0.271756C0.358398 0.217874 0.25 0.270375 0.25 0.360179Z"
        fill={fill || ColorBlack01}
      />
    </svg>
  );
};
