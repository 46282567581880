import { gql } from '@apollo/client';

export const GetTeams = gql`
  query($filter: InputQueryFilter, $pagination: InputPagePagination) {
    teams(filter: $filter, pagination: $pagination) {
      pagination {
        pageIndex
        pageCount
      }
      edges {
        uuid
        name
        colors
        createdAt
        players {
          uuid
          firstName
          lastName
        }
      }
    }
  }
`;
