import { ColorError } from 'config/main';

import { IconProps } from './type';

export const IconDownArrow = ({ width, height, className, fill }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.8531 5H15.4715C15.3775 5 15.2891 5.04883 15.2339 5.12891L10.0003 12.7773L4.76684 5.12891C4.71158 5.04883 4.62315 5 4.52921 5H3.1476C3.02787 5 2.95786 5.14453 3.02787 5.24805L9.52324 14.7422C9.75903 15.0859 10.2417 15.0859 10.4756 14.7422L16.971 5.24805C17.0428 5.14453 16.9728 5 16.8531 5Z"
        fill={fill || ColorError}
      />
    </svg>
  );
};
