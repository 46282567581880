import { gql } from '@apollo/client';

export const GetChampionshipGroup = gql`
  query GetChampionshipGroup($uuid: UUID!) {
    championshipGroup(uuid: $uuid) {
      uuid
      name
      championshipMatches {
        uuid
        datetime
        live
        championshipMatchEntities(orderBy: [{ position: asc }, { uuid: asc }]) {
          matchScore
          drawScore
          championshipEntity {
            championshipTeam {
              uuid
              team {
                uuid
                shortName
              }
            }
          }
        }
      }
      championshipEntities(orderBy: [{ groupScore: desc }, { weight: desc }, { uuid: asc }]) {
        uuid
        groupScore
        weight
        championshipTeam {
          uuid
          team {
            uuid
            shortName
            name
            colors
          }
        }
        championshipEntityPlayers {
          player {
            firstName
            lastName
            identification
          }
        }
      }
      championshipStage {
        championshipCompetition {
          name
          championshipSport {
            configMatchEntityType
            configMatchEntityMaxNumber
          }
        }
      }
    }
  }
`;

export const UpdateChampionshipEntityWeight = gql`
  mutation UpdateChampionshipEntityWeight(
    $championshipEntityWeight: [InputChampionshipEntityWeight]!
  ) {
    championshipEntityUpdateWeight(championshipEntityWeight: $championshipEntityWeight) {
      uuid
    }
  }
`;

export const UpdateChampionshipEntityScore = gql`
  mutation UpdateChampionshipEntityScore(
    $championshipEntityGroupScore: [InputChampionshipEntityGroupScore]!
  ) {
    championshipEntityUpdateGroupScore(
      championshipEntityGroupScore: $championshipEntityGroupScore
    ) {
      uuid
    }
  }
`;

export const CreateChampionshipEntities = gql`
  mutation CreateChampionshipEntities(
    $championshipGroupUuid: UUID!
    $championshipTeamUuids: [UUID]!
  ) {
    createChampionshipEntities(
      championshipGroupUuid: $championshipGroupUuid
      championshipTeamUuids: $championshipTeamUuids
    ) {
      uuid
    }
  }
`;

export const DeleteChampionshipEntities = gql`
  mutation DeleteChampionshipEntities($uuids: [UUID]!) {
    deleteChampionshipEntities(uuids: $uuids) {
      uuid
    }
  }
`;
