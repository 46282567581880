import { useLazyQuery, useMutation } from '@apollo/client';
import { Form } from 'antd';
import { ALL_ITEMS_ON_PAGE } from 'config/main';
import { Store } from 'rc-field-form/lib/interface';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { ROUTES } from 'routes/config';
import styled from 'styled-components';

import { SportsChampionshipCompetitionForm, FormMode } from './sportsChampionshipCompetitionForm';
import { transformFormValues, transformInitialValues } from './utils';

import { PageHeader } from 'components/pageHeader';
import { SubmitButton } from 'components/submitButton';

import {
  CreateChampionshipCompetition,
  GetChampionshipCompetition,
  UpdateChampionshipCompetition,
} from 'queries/sportsChampionshipCompetitions';
import { GetChampionshipSports } from 'queries/sportsChampionshipSports';
import {
  ChampionshipSport,
  Mutation,
  MutationCreateChampionshipCompetitionArgs,
  MutationUpdateChampionshipCompetitionArgs,
  Query,
  QueryChampionshipSportsArgs,
  QueryChampionshipCompetitionArgs,
} from 'queries/types';

const StyledPageHeader = styled(PageHeader)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: 25px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const I18N_PREFIX = 'sportsChampionshipcompetitionForm';

export const SportsChampionshipCompetitionCreateEdit = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const history = useHistory();

  const { uuid, competition } = useParams<{ uuid: string; competition: string }>();

  const [createChampionshipCompetition, { loading: isCreating, data: isCreated }] = useMutation<
    Mutation,
    MutationCreateChampionshipCompetitionArgs
  >(CreateChampionshipCompetition);

  const [updateChampionshipCompetition, { loading: isUpdating, data: isUpdated }] = useMutation<
    Mutation,
    MutationUpdateChampionshipCompetitionArgs
  >(UpdateChampionshipCompetition);

  const [getChampionshipCompetition, { data: championshipCompetition }] = useLazyQuery<
    Query,
    QueryChampionshipCompetitionArgs
  >(GetChampionshipCompetition, {
    variables: {
      uuid: competition,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (competition) getChampionshipCompetition();
  }, []);

  const [getChampionshipSports, { data: championshipSports }] = useLazyQuery<
    Query,
    QueryChampionshipSportsArgs
  >(GetChampionshipSports, {
    variables: {
      pagination: {
        page: 1,
        take: ALL_ITEMS_ON_PAGE,
      },
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  useEffect(() => {
    getChampionshipSports();
  }, []);

  useEffect(() => {
    if (isCreated) {
      history.push(`${ROUTES.SportsChampionships}/${uuid}`);
    }
  }, [isCreated]);

  useEffect(() => {
    if (isUpdated) window.location.reload();
  }, [isUpdated]);

  const onFinish = async (values: any) => {
    console.log('Success:', {
      values: values,
      transformed: transformFormValues(values, uuid),
    });

    if (competition) {
      updateChampionshipCompetition({
        variables: {
          uuid: competition,
          input: transformFormValues(values, uuid),
        },
      });
    } else {
      createChampionshipCompetition({
        variables: {
          input: {
            ...transformFormValues(values, uuid),
          },
        },
      });
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  if (competition && !championshipCompetition?.championshipCompetition) return null;

  return (
    <Form
      layout="vertical"
      form={form}
      name="sports-championship-form"
      className="sports-championship-create-edit-form"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={
        championshipCompetition?.championshipCompetition
          ? (transformInitialValues(championshipCompetition?.championshipCompetition) as Store)
          : undefined
      }
    >
      <StyledPageHeader
        title={t(uuid ? `${I18N_PREFIX}.header.edit` : `${I18N_PREFIX}.header.create`)}
      >
        <ButtonsWrapper>
          <SubmitButton isLoading={isCreating || isUpdating} label={t(`${I18N_PREFIX}.submit`)} />
        </ButtonsWrapper>
      </StyledPageHeader>
      <SportsChampionshipCompetitionForm
        mode={uuid ? FormMode.EDIT : FormMode.ADD}
        form={form}
        championshipSports={championshipSports?.championshipSports.edges as ChampionshipSport[]}
        props
      />
    </Form>
  );
};
