import { ColorGrey01 } from 'config/main';

import { IconProps } from './type';

export const IconFeed = ({ width, height, className, fill }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.9096 6.26798H11.731V2.0894C11.731 1.91262 11.5864 1.76797 11.4096 1.76797H2.08815C1.91137 1.76797 1.76672 1.91262 1.76672 2.0894V11.4108C1.76672 11.5876 1.91137 11.7323 2.08815 11.7323H6.26672V15.9108C6.26672 16.0876 6.41137 16.2323 6.58815 16.2323H15.9096C16.0864 16.2323 16.231 16.0876 16.231 15.9108V6.5894C16.231 6.41262 16.0864 6.26798 15.9096 6.26798ZM6.26672 6.5894V10.3662H3.1328V3.13405H10.3649V6.26798H6.58815C6.41137 6.26798 6.26672 6.41262 6.26672 6.5894ZM10.3649 7.63405V10.3662H7.6328V7.63405H10.3649ZM14.8649 14.8662H7.6328V11.7323H11.4096C11.5864 11.7323 11.731 11.5876 11.731 11.4108V7.63405H14.8649V14.8662Z"
        fill={fill || ColorGrey01}
      />
    </svg>
  );
};
