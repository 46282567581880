import { gql } from '@apollo/client';

export const GetChampionshipStages = gql`
  query GetChampionshipStages(
    $filter: InputChampionshipStagesFilter!
    $pagination: InputPagePagination
  ) {
    championshipStages(filter: $filter, pagination: $pagination) {
      pagination {
        pageIndex
        pageCount
      }
      edges {
        uuid
        name
        championshipGroups {
          uuid
          name
        }
        championshipCompetition {
          uuid
          championship {
            uuid
          }
        }
      }
    }
  }
`;

export const CreateChampionshipStage = gql`
  mutation CreateChampionshipStage($input: InputChampionshipStage!) {
    createChampionshipStage(input: $input) {
      uuid
    }
  }
`;

export const UpdateChampionshipStage = gql`
  mutation UpdateChampionshipStage($uuid: UUID!, $input: InputChampionshipStage!) {
    updateChampionshipStage(uuid: $uuid, input: $input) {
      uuid
    }
  }
`;

export const GetChampionshipStage = gql`
  query GetChampionshipStage($uuid: UUID!) {
    championshipStage(uuid: $uuid) {
      uuid
      name
      championshipCompetition {
        uuid
        name
      }
      configGroupPoints {
        id
      }
    }
  }
`;

export const GetChampionshipStagesConfigGroupPoints = gql`
  query GetChampionshipStagesConfigGroupPoints {
    championshipStagesConfigGroupPoints {
      id
      type
    }
  }
`;

export const DeleteChampionshipStage = gql`
  mutation DeleteCChampionshipStage($uuid: UUID!) {
    deleteChampionshipStage(uuid: $uuid) {
      uuid
    }
  }
`;
