import { ColorGrey01 } from 'config/main';

import { IconProps } from './type';

export const IconPlusCircle = ({ width, height, className, fill }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M12.6962 8.35714H9.64258V5.30357C9.64258 5.21517 9.57026 5.14285 9.48186 5.14285H8.51758C8.42919 5.14285 8.35686 5.21517 8.35686 5.30357V8.35714H5.30329C5.2149 8.35714 5.14258 8.42946 5.14258 8.51785V9.48214C5.14258 9.57053 5.2149 9.64285 5.30329 9.64285H8.35686V12.6964C8.35686 12.7848 8.42919 12.8571 8.51758 12.8571H9.48186C9.57026 12.8571 9.64258 12.7848 9.64258 12.6964V9.64285H12.6962C12.7845 9.64285 12.8569 9.57053 12.8569 9.48214V8.51785C12.8569 8.42946 12.7845 8.35714 12.6962 8.35714Z"
          fill={fill || ColorGrey01}
        />
        <path
          d="M9 0C4.02991 0 0 4.02991 0 9C0 13.9701 4.02991 18 9 18C13.9701 18 18 13.9701 18 9C18 4.02991 13.9701 0 9 0ZM9 16.4732C4.87366 16.4732 1.52679 13.1263 1.52679 9C1.52679 4.87366 4.87366 1.52679 9 1.52679C13.1263 1.52679 16.4732 4.87366 16.4732 9C16.4732 13.1263 13.1263 16.4732 9 16.4732Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="18" height="18" fill={fill || 'white'} />
        </clipPath>
      </defs>
    </svg>
  );
};
