import { gql } from '@apollo/client';

export const GetChampionship = gql`
  query GetChampionship($uuid: UUID) {
    championship(uuid: $uuid) {
      uuid
      name
      shortName
      logo {
        url
        contentType
        width
        height
      }
      reach
      managementEntity {
        uuid
      }
      championshipTeams {
        uuid
        team {
          uuid
          name
        }
      }
    }
  }
`;

export const CreateChampionship = gql`
  mutation CreateChampionship($input: InputChampionship!) {
    createChampionship(input: $input) {
      uuid
    }
  }
`;

export const UpdateChampionship = gql`
  mutation UpdateChampionship($uuid: UUID!, $input: InputChampionship!) {
    updateChampionship(uuid: $uuid, input: $input) {
      uuid
    }
  }
`;

export const CreateChampionshipTeams = gql`
  mutation CreateChampionshipTeams($championshipUuid: UUID!, $teamUuids: [UUID]!) {
    createChampionshipTeams(championshipUuid: $championshipUuid, teamUuids: $teamUuids) {
      uuid
    }
  }
`;
