import { Button, Card, Table } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { SportsTeamsPlayersModal } from './sportsTeamsPlayersModal';

import { IconPlus } from 'assets/icons/iconPlus';

import { Player } from 'queries/types';

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 12px;
`;

const I18N_PREFIX: string = 'sportsTeamsPlayersList';

interface Props {
  players: any;
  teamUuid: string;
}

export const SportsTeamsPlayers = ({ players, teamUuid }: Props) => {
  const { t } = useTranslation();

  const [showPlayersModal, setShowPlayersModal] = useState<boolean>(false);

  const tableColumns = [
    {
      title: t(`${I18N_PREFIX}.name`),
      dataIndex: 'firstName',
      render: (firstName: string, player: Player) => (
        <span>
          {firstName} {player.lastName}
        </span>
      ),
    },
    {
      title: t(`${I18N_PREFIX}.createdAt`),
      dataIndex: 'createdAt',
      render: (date: string) => <span>{moment(date).format('DD/MM/YYYY')}</span>,
    },
    {
      title: '',
    },
  ];

  return (
    <Card>
      <ActionsWrapper>
        <Button
          type="primary"
          onClick={() => setShowPlayersModal(true)}
          icon={<IconPlus className={'anticon'} width="15" height="16" />}
        >
          {t(`${I18N_PREFIX}.actions.add`)}
        </Button>
      </ActionsWrapper>
      <div className="table-responsive">
        <Table
          columns={tableColumns}
          dataSource={players || []}
          //   loading={loading || mutationLoading}
          rowKey="uuid"
        />
      </div>
      <SportsTeamsPlayersModal
        showModal={showPlayersModal}
        onClose={() => setShowPlayersModal(false)}
        isLoading={false}
        onRemoveConfirm={() => {}}
        teamUuid={teamUuid}
      />
    </Card>
  );
};
