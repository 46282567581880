import { gql } from '@apollo/client';

export const GetUserInfo = gql`
  query {
    self {
      profile {
        firstName
        lastName
        picture
      }
    }
  }
`;
