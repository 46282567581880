import { LoadingOutlined } from '@ant-design/icons';
import { Input, Row, Col, Card, Form, FormInstance, Select } from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import { ColorGrey05 } from 'config/main';
import { useUploadFile } from 'hooks/useUploadFile';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { IconDelete } from 'assets/icons/iconDelete';
import { IconImage } from 'assets/icons/iconImage';

import { Championship, ChampionshipReach, Image, ManagementEntity } from 'queries/types';

export enum FormMode {
  ADD = 'add',
  EDIT = 'edit',
}

type FormProps = {
  form: FormInstance;
  mode: FormMode;
  managementEntities?: ManagementEntity[];
  props: any;
};

const DeleteButton = styled.button`
  background: #000;
  border: none;
  border-radius: 50%;
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  margin: -53px 0 0 15px;
`;

export const SportsChampionshipForm = ({ form, mode, managementEntities }: FormProps) => {
  const { t } = useTranslation();

  const I18N_PREFIX = 'sportsChampionshipForm';
  const I18N_PREFIX_FIELDS = `${I18N_PREFIX}.fields`;

  const imageUploadProps: any = {
    name: 'file',
    multiple: false,
    listType: 'picture-card',
    showUploadList: false,
  };

  const [logoData, setLogoData] = useState<Image | null>(form.getFieldValue('logo'));
  const {
    isUploadFileLoading: isLogoLoading,
    uploadFileData: logo,
    uploadFile: uploadLogo,
  } = useUploadFile();
  const handleUploadChange = (_ßfield: string, info: any) => {
    uploadLogo(info.fileList[0].originFileObj);
  };
  useEffect(() => {
    form.setFieldsValue({
      logo: logo?.data ? logo.data : form.getFieldValue('logo'),
    });
    setLogoData(form.getFieldValue('logo'));
  }, [logo]);

  const handleRemoveMedia = () => {
    form.setFieldsValue({
      logo: null,
    });
    setLogoData(null);
  };

  return (
    <Row gutter={16}>
      <Col xs={24} sm={24} md={17}>
        <Card>
          <Form.Item
            name="name"
            label={t(`${I18N_PREFIX_FIELDS}.name.field`)}
            rules={[{ required: true, message: t(`${I18N_PREFIX_FIELDS}.name.errorMessage`) }]}
          >
            <Input placeholder={t(`${I18N_PREFIX_FIELDS}.name.placeholder`)} />
          </Form.Item>
          <Form.Item
            name="shortName"
            label={t(`${I18N_PREFIX_FIELDS}.shortName.field`)}
            rules={[
              {
                required: true,
                message: t(`${I18N_PREFIX_FIELDS}.shortName.errorMessage`),
              },
            ]}
          >
            <Input placeholder={t(`${I18N_PREFIX_FIELDS}.shortName.placeholder`)} maxLength={6} />
          </Form.Item>
          <Form.Item
            name="reach"
            label={t(`${I18N_PREFIX_FIELDS}.reach.field`)}
            rules={[
              { required: true, message: t(`${I18N_PREFIX_FIELDS}.managementEntity.errorMessage`) },
            ]}
          >
            <Select
              className="w-100"
              placeholder={t(`${I18N_PREFIX_FIELDS}.reach.placeholder`)}
              disabled={mode === FormMode.EDIT}
              options={[
                { label: t(ChampionshipReach.Regional), value: ChampionshipReach.Regional },
                { label: t(ChampionshipReach.National), value: ChampionshipReach.National },
                { label: t(ChampionshipReach.European), value: ChampionshipReach.European },
                { label: t(ChampionshipReach.Worldwide), value: ChampionshipReach.Worldwide },
              ]}
            />
          </Form.Item>
          <Form.Item
            name="managementEntity"
            label={t(`${I18N_PREFIX_FIELDS}.managementEntity.field`)}
            rules={[
              { required: true, message: t(`${I18N_PREFIX_FIELDS}.managementEntity.errorMessage`) },
            ]}
          >
            <Select
              className="w-100"
              placeholder={t(`${I18N_PREFIX_FIELDS}.managementEntity.placeholder`)}
              options={managementEntities?.map((entity: ManagementEntity) => {
                return { label: entity.name.pt, value: entity.uuid };
              })}
            />
          </Form.Item>
        </Card>
      </Col>
      <Col xs={24} sm={24} md={7}>
        <Card>
          <Form.Item
            label={t(`${I18N_PREFIX_FIELDS}.logo.field`)}
            name="logo"
            rules={[{ required: true, message: t(`${I18N_PREFIX_FIELDS}.logo.errorMessage`) }]}
          >
            <Input type="hidden" />
            <Dragger
              {...imageUploadProps}
              beforeUpload={() => false}
              onChange={(e) => handleUploadChange('logo', e)}
            >
              {isLogoLoading ? (
                <div>
                  <LoadingOutlined className="font-size-xxl text-primary" />
                  <div className="mt-3">{t(`${I18N_PREFIX_FIELDS}.logo.uploading`)}</div>
                </div>
              ) : logoData ? (
                <img src={logoData.url || ''} alt="avatar" className="img-fluid" />
              ) : (
                <div>
                  <IconImage width="64" height="64" />
                  <p>{t(`${I18N_PREFIX_FIELDS}.logo.placeholder`)}</p>
                </div>
              )}
            </Dragger>
            {logoData && (
              <DeleteButton onClick={handleRemoveMedia}>
                <IconDelete width="12" height="13" fill={ColorGrey05} />
              </DeleteButton>
            )}
          </Form.Item>
        </Card>
      </Col>
    </Row>
  );
};
