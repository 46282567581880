import { gql } from '@apollo/client';

export const CreateSportsTeams = gql`
  mutation CreateSportsTeams($input: InputTeam!) {
    createTeam(input: $input) {
      uuid
    }
  }
`;

export const UpdateSportsTeams = gql`
  mutation UpdateASportsTeams($uuid: UUID!, $input: InputTeam!) {
    updateTeam(uuid: $uuid, input: $input) {
      uuid
    }
  }
`;

// export const DeleteSportsTeams = gql`
//   mutation DeleteASportsTeams($uuid: UUID!) {
//     deleteTeam(uuid: $uuid) {
//       uuid
//     }
//   }
// `;

export const GetSportsTeam = gql`
  query GetTeam($uuid: UUID!) {
    team(uuid: $uuid) {
      uuid
      name
      shortName
      colors
      logo {
        url
        contentType
      }
      players {
        firstName
        lastName
        createdAt
      }
    }
  }
`;
