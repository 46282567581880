import { IconProps } from './type';

export const IconFacebook = ({ width, height, className, fill }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 11 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.80859 10.875L10.3008 7.64062H7.17188V5.53125C7.17188 4.61719 7.59375 3.77344 9 3.77344H10.4414V0.996094C10.4414 0.996094 9.14062 0.75 7.91016 0.75C5.34375 0.75 3.65625 2.33203 3.65625 5.14453V7.64062H0.773438V10.875H3.65625V18.75H7.17188V10.875H9.80859Z"
        fill={fill || 'black'}
      />
    </svg>
  );
};
