import { ROUTES } from 'routes/config';
import { MenuProps } from 'typings/menu';

const I18N_PREFIX = 'tabsMenu.press';

/**
 * MAIN MENU SECTION
 */
export const tabsMenu: MenuProps[] = [
  {
    key: ROUTES.PressFeed,
    path: ROUTES.PressFeed,
    title: `${I18N_PREFIX}.feed`,
    icon: '',
  },
  {
    key: ROUTES.PressNews,
    path: ROUTES.PressNews,
    title: `${I18N_PREFIX}.news`,
    icon: '',
  },
  {
    key: ROUTES.PressVideo,
    path: ROUTES.PressVideo,
    title: `${I18N_PREFIX}.video`,
    icon: '',
  },
  {
    key: ROUTES.PressGallery,
    path: ROUTES.PressGallery,
    title: `${I18N_PREFIX}.gallery`,
    icon: '',
  },
  {
    key: ROUTES.PressCategories,
    path: ROUTES.PressCategories,
    title: `${I18N_PREFIX}.categories`,
    icon: '',
  },
];
