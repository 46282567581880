import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'routes/config';
import styled from 'styled-components';

import { tabsMenu } from './config';

import { PageHeader } from 'components/pageHeader';
import { SubmitButton } from 'components/submitButton';
import { TabsMenu } from 'components/tabsMenu';

const StyledPageHeader = styled(PageHeader)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 42px;
  position: relative;
  margin-bottom: 25px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TabsMenuWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
`;

interface Props {
  uuid?: string;
  name?: string;
  loading?: boolean;
  section?: string;
}

export const Header = ({ uuid, name, loading, section }: Props) => {
  const { t } = useTranslation();

  const I18N_PREFIX = 'sportsTeamsForm';

  const routes = [
    {
      path: ROUTES.SportsTeams,
      breadcrumbName: t('mainMenu.sports.teams'),
    },
    {
      path: `${ROUTES.SportsTeams}${ROUTES.Create}`,
      breadcrumbName: t(`${I18N_PREFIX}.header.${uuid ? 'edit' : 'create'}`),
    },
  ];

  return (
    <StyledPageHeader
      title={t(`${uuid && name ? name : `${I18N_PREFIX}.header.create`}`)}
      breadcrumbs={{ routes, separator: '/' }}
      whiteBackground={!!uuid}
    >
      <ButtonsWrapper>
        {/* {uuid && (
        <Button
          className="mr-2"
          icon={<IconDelete width="14" height="14" className={'anticon'} />}
          htmlType="reset"
          onClick={() => setShowRemoveModal(true)}
        >
          {t(`${I18N_PREFIX}.reset`)}
        </Button>
      )} */}
        {section !== 'players' && (
          <SubmitButton isLoading={loading} label={t(`${I18N_PREFIX}.submit`)} />
        )}
      </ButtonsWrapper>
      {uuid && (
        <TabsMenuWrapper>
          <TabsMenu data={tabsMenu(uuid)} />
        </TabsMenuWrapper>
      )}
    </StyledPageHeader>
  );
};
