import { IconProps } from './type';

export const IconInstagram = ({ width, height, className, fill }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.875 4.70703C6.625 4.70703 4.83203 6.53516 4.83203 8.75C4.83203 11 6.625 12.793 8.875 12.793C11.0898 12.793 12.918 11 12.918 8.75C12.918 6.53516 11.0898 4.70703 8.875 4.70703ZM8.875 11.3867C7.43359 11.3867 6.23828 10.2266 6.23828 8.75C6.23828 7.30859 7.39844 6.14844 8.875 6.14844C10.3164 6.14844 11.4766 7.30859 11.4766 8.75C11.4766 10.2266 10.3164 11.3867 8.875 11.3867ZM14.0078 4.56641C14.0078 4.03906 13.5859 3.61719 13.0586 3.61719C12.5312 3.61719 12.1094 4.03906 12.1094 4.56641C12.1094 5.09375 12.5312 5.51562 13.0586 5.51562C13.5859 5.51562 14.0078 5.09375 14.0078 4.56641ZM16.6797 5.51562C16.6094 4.25 16.3281 3.125 15.4141 2.21094C14.5 1.29688 13.375 1.01562 12.1094 0.945312C10.8086 0.875 6.90625 0.875 5.60547 0.945312C4.33984 1.01562 3.25 1.29688 2.30078 2.21094C1.38672 3.125 1.10547 4.25 1.03516 5.51562C0.964844 6.81641 0.964844 10.7188 1.03516 12.0195C1.10547 13.2852 1.38672 14.375 2.30078 15.3242C3.25 16.2383 4.33984 16.5195 5.60547 16.5898C6.90625 16.6602 10.8086 16.6602 12.1094 16.5898C13.375 16.5195 14.5 16.2383 15.4141 15.3242C16.3281 14.375 16.6094 13.2852 16.6797 12.0195C16.75 10.7188 16.75 6.81641 16.6797 5.51562ZM14.9922 13.3906C14.7461 14.0938 14.1836 14.6211 13.5156 14.9023C12.4609 15.3242 10 15.2188 8.875 15.2188C7.71484 15.2188 5.25391 15.3242 4.23438 14.9023C3.53125 14.6211 3.00391 14.0938 2.72266 13.3906C2.30078 12.3711 2.40625 9.91016 2.40625 8.75C2.40625 7.625 2.30078 5.16406 2.72266 4.10938C3.00391 3.44141 3.53125 2.91406 4.23438 2.63281C5.25391 2.21094 7.71484 2.31641 8.875 2.31641C10 2.31641 12.4609 2.21094 13.5156 2.63281C14.1836 2.87891 14.7109 3.44141 14.9922 4.10938C15.4141 5.16406 15.3086 7.625 15.3086 8.75C15.3086 9.91016 15.4141 12.3711 14.9922 13.3906Z"
        fill={fill || 'black'}
      />
    </svg>
  );
};
