import { ColorGrey01 } from 'config/main';

import { IconProps } from './type';

export const IconPlay = ({ width, height, className, fill }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.71724 15.1998C1.60733 15.1998 0.699219 14.2917 0.699219 13.1818V2.81782C0.699219 1.70791 1.60733 0.799805 2.71724 0.799805C3.06318 0.799805 3.40913 0.900706 3.72625 1.07368L12.692 6.26287C13.3262 6.62323 13.701 7.27188 13.701 8.00701C13.701 8.72773 13.3262 9.39079 12.692 9.75115L3.72625 14.9259C3.40913 15.0989 3.06318 15.1998 2.71724 15.1998ZM2.71724 2.47188C2.57309 2.47188 2.37129 2.58719 2.37129 2.81782V13.1818C2.37129 13.4124 2.57309 13.5277 2.71724 13.5277C2.77489 13.5277 2.83255 13.5133 2.89021 13.4845L11.856 8.2953C12.0001 8.20881 12.0289 8.07908 12.0289 7.9926C12.0289 7.90611 12.0001 7.77638 11.856 7.68989L2.89021 2.50071C2.83255 2.48629 2.77489 2.47188 2.71724 2.47188Z"
        fill={fill || ColorGrey01}
      />
    </svg>
  );
};
