import styled from 'styled-components';

import { ChampionshipEntityPlayer, Player } from 'queries/types';

export const setPlayers = (
  originList: any,
  checkedList: any,
  targetList: any
): {
  players: any;
  playersGroup: any;
} => {
  let playersGroup = [...targetList];

  checkedList.forEach((checked: any) => {
    const index = originList.findIndex((a: any) => (a && a.value && a.value === checked) || false);
    playersGroup = [...playersGroup, originList[index]];

    originList.splice(index, 1);
  });

  return {
    players: originList,
    playersGroup,
  };
};

export const setPlayersGroup = (
  originList: any,
  checkedList: any,
  targetList: any
): {
  players: any;
  playersGroup: any;
} => {
  let playersGroup = [...targetList];

  checkedList.forEach((checked: any) => {
    const index = originList.findIndex((a: any) => (a && a.value && a.value === checked) || false);
    playersGroup = [...playersGroup, originList[index]];

    originList.splice(index, 1);
  });

  return {
    players: playersGroup,
    playersGroup: originList,
  };
};

export const getAllPlayersValues = (playerList: any) => {
  return playerList.map((player: any) => player.value);
};

const PlayerName = styled.span`
  span {
    color: #9fa8b7;
  }
`;

export const playerToOptions = (players: Player[]) => {
  return players.map((player: Player) => {
    return {
      label: (
        <PlayerName>
          {player.firstName} {player.lastName} <span>{player.identification}</span>
        </PlayerName>
      ),
      value: player.uuid,
    };
  });
};

export const entityPlayerToOptions = (players: ChampionshipEntityPlayer[]) => {
  return players.map((player: ChampionshipEntityPlayer) => {
    return {
      label: (
        <PlayerName>
          {player.player.firstName} {player.player.lastName}{' '}
          <span>{player.player.identification}</span>
        </PlayerName>
      ),
      value: player.player.uuid,
    };
  });
};
