import { useMutation } from '@apollo/client';
import { Button, Tag } from 'antd';
import { lineClamp } from 'helpers/lineClamp';
import { getPostType } from 'helpers/posts';
import moment from 'moment';
import { FC } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ListActionElementType, ListActionElement } from 'components/listActionElement';

import { IconCalendar } from 'assets/icons/iconCalendar';
import { IconDelete } from 'assets/icons/iconDelete';

import { UpdateHighlightStretch } from 'queries/highlightMutations';
import {
  FeedPost,
  FeedPostOrigin,
  FeedPostType,
  Mutation,
  MutationStretchHighlightFeedPostArgs,
} from 'queries/types';

const Container = styled.div`
  padding: 10px;
  background: white;
  margin: 0 6px;
  justify-content: space-between;
`;

const Category = styled.span`
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 10px;
`;

const Title = styled.p`
  font-size: 14px;
  line-height: 18px;
  color: black;
  margin-bottom: 12px;
  ${lineClamp(3)}
`;

const Cover = styled.div`
  padding-top: 200%;
  position: relative;
  overflow: hidden;
  margin-bottom: 12px;
  background: #eef2f5;

  img,
  video,
  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    height: 100%;
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  video {
    pointer-events: none;
  }
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
`;

const StyledTag = styled(Tag)`
  display: flex;
  align-items: center;
  margin: 0 0 0 auto;

  svg {
    width: 12px;
    margin-right: 5px;
  }
`;

const StretchToggle = styled.div`
  margin: 10px 0 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    font-size: 12px;
    color: #9fa8b7;
  }
`;

interface HighlightsItemProps extends FeedPost {
  index: number;
  onRemoveClick: (uuid: string) => void;
  setHighlightStretch: (uuid: string, stretch: boolean) => void;
}

export const HighlightsItem: FC<HighlightsItemProps> = ({
  index,
  uuid,
  type,
  origin,
  title,
  subtitle,
  cover,
  createdAt,
  blocks,
  highlight,
  onRemoveClick,
  setHighlightStretch,
}: HighlightsItemProps) => {
  const { t } = useTranslation();

  const [stretch, setStretch] = useState<boolean>(highlight.stretch);

  useEffect(() => {
    setHighlightStretch(uuid, stretch);
  }, [stretch]);

  /**
   * VIDEO: highlight.video.url, blocks[0].data.file.url, highlight.image.url, cover.url
   * GALLERY: highlight.image.url, cover.url, blocks[0].data.files[0].data.file.url
   * EVERYTHING_ELSE: highlight.video.url, highlight.image.url, cover.url
   */
  const getMediaPreview = () => {
    let previewContent;
    switch (type) {
      case FeedPostType.Video:
        previewContent =
          highlight?.video?.url ||
          (blocks && blocks.pt.blocks && blocks.pt.blocks[0]?.data.file.url) ||
          '';
        previewContent = previewContent
          ? renderVideo(previewContent)
          : renderImage(highlight?.image?.url || cover?.url || '');

        break;
      case FeedPostType.Gallery:
        previewContent = renderImage(
          highlight?.image?.url ||
            cover?.url ||
            (blocks && blocks.pt.blocks && blocks.pt.blocks[0]?.data?.file?.url) ||
            (blocks && blocks.pt.blocks && blocks.pt.blocks[0]?.data?.files[0]?.data.file.url) ||
            ''
        );
        break;
      default:
        previewContent = highlight?.video?.url || '';
        previewContent = previewContent
          ? renderVideo(previewContent)
          : renderImage(highlight?.image?.url || cover?.url || '');
        break;
    }

    return (
      <Cover>{previewContent ? previewContent : <span>{t('highlights.noMedia')}</span>}</Cover>
    );
  };

  const renderImage = (source: string) => {
    return source && <img src={source} alt={title.pt} />;
  };

  const renderVideo = (source: string) => {
    const setVideoPlay = (event: Event) => {
      const eventTarget = event?.target as HTMLVideoElement;
      eventTarget && eventTarget.play();
    };

    const setVideoRestart = (event: MouseEvent) => {
      const eventTarget = event?.target as HTMLVideoElement;
      if (eventTarget) {
        eventTarget.pause();
        eventTarget.currentTime = 0;
      }
    };

    const setVideoDragabble = (event: MouseEvent, isDragabble: boolean) => {
      const eventTarget = event?.target as HTMLVideoElement;
      if (eventTarget) {
        isDragabble
          ? eventTarget.classList.add('dragabble')
          : eventTarget.classList.remove('dragabble');
      }
    };

    return (
      source && (
        <video
          src={source}
          muted={true}
          // @ts-ignore
          //onMouseOver={setVideoPlay}
          // @ts-ignore
          //onMouseOut={setVideoRestart}
          // @ts-ignore
          //onMouseDown={(event) => setVideoDragabble(event, true)}
          // @ts-ignore
          //onMouseUp={(event) => setVideoDragabble(event, false)}
        />
      )
    );
  };

  return (
    <Draggable key={index} draggableId={uuid} index={index}>
      {(provided) => (
        <Container
          className="board-column"
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <div>
            <Category className="text-main-color">{t(getPostType({ origin, type }))}</Category>
            <Title>
              {title.pt}
              {subtitle.pt}
            </Title>
          </div>
          <div>
            <StretchToggle>
              <span>{t('highlights.stretch')}</span>
              <ListActionElement
                type={ListActionElementType.Toggle}
                action={() => setStretch(!stretch)}
                checked={highlight.stretch}
              >
                {t('actions.hide')}
              </ListActionElement>
            </StretchToggle>
            {getMediaPreview()}
            <Actions>
              <Button
                onClick={() => {
                  onRemoveClick(uuid);
                }}
                icon={<IconDelete width="15" height="15" />}
                shape="circle"
                hidden={origin === FeedPostOrigin.Pub}
              />
              <StyledTag className="bg-gray-lightest">
                {<IconCalendar width="18" height="18" />}
                <span className="ml-1 font-weight-semibold">
                  {moment(createdAt).format('DD/MM/YYYY')}
                </span>
              </StyledTag>
            </Actions>
          </div>
        </Container>
      )}
    </Draggable>
  );
};
