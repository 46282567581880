import { useMutation } from '@apollo/client';
import { Form, Input, Select, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { SubmitButton } from 'components/submitButton';

import { IconPlus } from 'assets/icons/iconPlus';

import { GetChampionshipMatch } from 'queries/sportsChampionshipMatch';
import { CreateMatchClock } from 'queries/sportsChampionshipMatchClock';
import { ClockDirection, Mutation, MutationChampionshipMatchClockCreateArgs } from 'queries/types';

const DisabledContainer = styled.div`
  text-align: center;
  margin-bottom: 25px;
`;

const Container = styled.div`
  background: white;
  padding: 20px;
  border: 1px solid #edf2f9;
  border-radius: 10px;
  margin-bottom: 25px;
`;

const StyledForm = styled(Form)`
  display: flex;
  justify-content: center;

  & > div,
  & > button {
    margin: 0 5px;
    width: 20%;
  }
  & > button {
    justify-content: center;
  }
`;

const StyledH4 = styled.h4`
  font-weight: bold;
  margin-bottom: 25px;
`;

const I18N_PREFIX = 'sportsMatch.sections.summary.addPart';
const I18N_PREFIX_FIELDS = `${I18N_PREFIX}.fields`;

interface Props {
  disabled: boolean;
}

export const AddPart = ({ disabled }: Props) => {
  const { t } = useTranslation();
  const { uuid } = useParams<{ uuid: string }>();

  const [createMatchClock, { loading }] = useMutation<
    Mutation,
    MutationChampionshipMatchClockCreateArgs
  >(CreateMatchClock, {
    refetchQueries: [
      {
        query: GetChampionshipMatch,
        variables: {
          uuid,
        },
      },
    ],
    awaitRefetchQueries: true,
  });

  const onFinish = async (values: any) => {
    createMatchClock({
      variables: {
        uuid,
        clock: {
          ...values,
          startValue: values.startValue * 60000,
          title: {
            pt: values.title,
          },
        },
      },
    });
  };

  return (
    <>
      {disabled ? (
        <DisabledContainer>{t(`${I18N_PREFIX}.disabled`)}</DisabledContainer>
      ) : (
        <Container>
          <StyledH4>{t(`${I18N_PREFIX}.title`)}</StyledH4>
          <StyledForm
            layout="horizontal"
            name="add-event-form"
            className="add-event-form"
            initialValues={{
              direction: ClockDirection.Asc,
              startValue: 0,
            }}
            onFinish={onFinish}
          >
            <Form.Item
              name="title"
              rules={[{ required: true, message: t(`${I18N_PREFIX_FIELDS}.errorMessage`) }]}
            >
              <Input placeholder={t(`${I18N_PREFIX_FIELDS}.title`)} />
            </Form.Item>
            <Form.Item
              name="startValue"
              rules={[
                { required: true, message: t(`${I18N_PREFIX_FIELDS}.errorMessage`) },
                {
                  validator(_rule, value, callback) {
                    if (!Number.isInteger(parseFloat(value))) {
                      callback(t(`O valor deve ser um número inteiro`));
                    }

                    callback();
                  },
                },
              ]}
            >
              <Input type="number" min="0" placeholder={t(`${I18N_PREFIX_FIELDS}.startValue`)} />
            </Form.Item>
            <Form.Item
              name="direction"
              rules={[{ required: true, message: t(`${I18N_PREFIX_FIELDS}.errorMessage`) }]}
            >
              <Select
                className="w-100"
                placeholder={t(`${I18N_PREFIX_FIELDS}.direction`)}
                options={[
                  {
                    label: t(`${I18N_PREFIX_FIELDS}.${ClockDirection.Asc}`),
                    value: ClockDirection.Asc,
                  },
                  {
                    label: t(`${I18N_PREFIX_FIELDS}.${ClockDirection.Desc}`),
                    value: ClockDirection.Desc,
                  },
                ]}
              />
            </Form.Item>
            <SubmitButton
              isLoading={loading}
              icon={<IconPlus className={'anticon'} width="15" height="16" />}
              label={t(`${I18N_PREFIX_FIELDS}.submit`)}
            />
          </StyledForm>
        </Container>
      )}
    </>
  );
};
