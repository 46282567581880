import { LoadingOutlined } from '@ant-design/icons';
import { Input, Row, Col, Card, Form, FormInstance, Upload } from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import { ColorBlack } from 'config/main';
import { ColorGrey05 } from 'config/main';
import { useUploadFile } from 'hooks/useUploadFile';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { IconDelete } from 'assets/icons/iconDelete';
import { IconImage } from 'assets/icons/iconImage';

import { Image } from 'queries/types';

const GuideContent = styled.p`
  white-space: pre-line;
`;

const DeleteButton = styled.button`
  background: #000;
  border: none;
  border-radius: 50%;
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  margin: -53px 0 0 15px;
`;

const FormItemColor = styled(Form.Item)`
  margin-bottom: 0;
  flex-direction: row !important;
  align-items: start;

  label {
    color: #9fa8b7;
    padding-right: 10px;
    padding-top: 7px;

    &:before {
      display: none !important;
    }
  }

  input {
    width: 50px;
    height: 27px !important;
    padding: 0;
  }
`;

export enum FormMode {
  ADD = 'add',
  EDIT = 'edit',
}

const imageUploadProps: any = {
  name: 'file',
  multiple: false,
  listType: 'picture-card',
  showUploadList: false,
};

type FormProps = {
  form: FormInstance;
  mode: FormMode;
  props: any;
};

export const SportsTeamsForm = ({ form, mode }: FormProps) => {
  const { t } = useTranslation();
  const { Dragger } = Upload;

  const [logoData, setLogoData] = useState<Image | null>(form.getFieldValue('logo'));

  const {
    isUploadFileLoading: isLogoLoading,
    uploadFileData: logo,
    uploadFile: uploadLogo,
  } = useUploadFile();

  const handleUploadChange = (info: any) => {
    uploadLogo(info.fileList[0].originFileObj);
  };

  useEffect(() => {
    form.setFieldsValue({
      logo: logo?.data ? logo.data : form.getFieldValue('logo'),
    });
    setLogoData(form.getFieldValue('logo'));
  }, [logo]);

  const handleRemoveMedia = () => {
    form.setFieldsValue({
      logo: null,
    });
    setLogoData(null);
  };

  const I18N_PREFIX = 'sportsTeamsForm';
  const I18N_PREFIX_FIELDS = `${I18N_PREFIX}.fields`;
  const I18N_PREFIX_SECTIONS = `${I18N_PREFIX}.sections`;

  return (
    <Row gutter={16}>
      <Col xs={24} sm={24} md={17}>
        <Card title={t(`${I18N_PREFIX_SECTIONS}.basicInfo`)}>
          <Form.Item
            name="name"
            label={t(`${I18N_PREFIX_FIELDS}.name.field`)}
            rules={[{ required: true, message: t(`${I18N_PREFIX_FIELDS}.name.errorMessage`) }]}
          >
            <Input placeholder={t(`${I18N_PREFIX_FIELDS}.name.placeholder`)} />
          </Form.Item>
          <Form.Item
            name="shortName"
            label={t(`${I18N_PREFIX_FIELDS}.shortname.field`)}
            rules={[{ required: true, message: t(`${I18N_PREFIX_FIELDS}.shortname.errorMessage`) }]}
          >
            <Input placeholder={t(`${I18N_PREFIX_FIELDS}.shortname.placeholder`)} />
          </Form.Item>
        </Card>
      </Col>
      <Col xs={24} sm={24} md={7}>
        <Card title={t(`${I18N_PREFIX_SECTIONS}.visual`)}>
          <Form.Item label={t(`${I18N_PREFIX_FIELDS}.colors.field`)}>
            <FormItemColor
              name="color1"
              label={`${t(`${I18N_PREFIX_FIELDS}.color.field`)} 1`}
              rules={[{ required: true, message: t(`${I18N_PREFIX_FIELDS}.colors.errorMessage`) }]}
            >
              <Input type="color" placeholder={t(`${I18N_PREFIX_FIELDS}.colors.placeholder`)} />
            </FormItemColor>
            <FormItemColor
              name="color2"
              label={`${t(`${I18N_PREFIX_FIELDS}.color.field`)} 2`}
              rules={[{ required: true, message: t(`${I18N_PREFIX_FIELDS}.colors.errorMessage`) }]}
            >
              <Input type="color" placeholder={t(`${I18N_PREFIX_FIELDS}.colors.placeholder`)} />
            </FormItemColor>
            <FormItemColor
              name="color3"
              label={`${t(`${I18N_PREFIX_FIELDS}.color.field`)} 3`}
              rules={[{ required: true, message: t(`${I18N_PREFIX_FIELDS}.colors.errorMessage`) }]}
            >
              <Input type="color" placeholder={t(`${I18N_PREFIX_FIELDS}.colors.placeholder`)} />
            </FormItemColor>
          </Form.Item>
          <Form.Item label={t(`${I18N_PREFIX_FIELDS}.logo.field`)} name="logo">
            <Input type="hidden" />
            <Dragger
              {...imageUploadProps}
              beforeUpload={() => false}
              onChange={handleUploadChange}
              style={logoData ? { backgroundColor: ColorBlack } : null}
            >
              {isLogoLoading ? (
                <div>
                  <LoadingOutlined className="font-size-xxl text-primary" />
                  <div className="mt-3">{t(`${I18N_PREFIX_FIELDS}.logo.uploading`)}</div>
                </div>
              ) : logoData ? (
                <img src={logoData.url || ''} alt="avatar" className="img-fluid" />
              ) : (
                <div>
                  <IconImage width="64" height="64" />
                  <p>{t(`${I18N_PREFIX_FIELDS}.logo.placeholder`)}</p>
                </div>
              )}
            </Dragger>
            {logoData && (
              <DeleteButton onClick={handleRemoveMedia}>
                <IconDelete width="12" height="13" fill={ColorGrey05} />
              </DeleteButton>
            )}
          </Form.Item>
        </Card>
        <Card title={t(`${I18N_PREFIX_FIELDS}.guide.field`)}>
          <GuideContent>{t(`${I18N_PREFIX_FIELDS}.guide.content`)}</GuideContent>
        </Card>
      </Col>
    </Row>
  );
};
