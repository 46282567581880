import i18n from 'locales/i18n';
import moment from 'moment';
import styled from 'styled-components';

import { Scalars } from 'queries/types';

export const commonColumns: any[] = [
  {
    title: i18n.t('columns.title'),
    dataIndex: 'title',
    ellipsis: true,
    width: '80%',
    render: (text: Scalars['TranslatableField']) => (
      <span className="font-weight-bold">{text.pt}</span>
    ),
  },
  {
    title: i18n.t('columns.publishedAt'),
    dataIndex: 'publishedAt',
    align: 'right',
    render: (date: string) => <span>{moment(date).format('DD/MM/YYYY')}</span>,
  },
];

export const socialColumns: any[] = [
  {
    title: i18n.t('columns.subtitle'),
    dataIndex: 'subtitle',
    ellipsis: true,
    width: '80%',
    render: (text: Scalars['TranslatableField']) => (
      <span className="font-weight-bold">{text.pt}</span>
    ),
  },
  {
    title: i18n.t('columns.publishedAt'),
    dataIndex: 'publishedAt',
    align: 'right',
    render: (date: string) => <span>{moment(date).format('DD/MM/YYYY')}</span>,
  },
];
