import { useLazyQuery } from '@apollo/client';
import { Menu, Dropdown, Avatar, Spin } from 'antd';
import { useAuthDispatch } from 'context/AuthContext';
import { useEffect } from 'react';

import { profileMenu } from './config';

import { Query } from 'queries/types';
import { GetUserInfo } from 'queries/userInfo';

export const ProfileNav = () => {
  const [getUserInfo, { data, loading }] = useLazyQuery<Query>(GetUserInfo);

  useEffect(() => {
    // get feed posts
    getUserInfo();
  }, [getUserInfo]);

  const profile = data?.self?.profile;

  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          {loading && <Spin />}
          {!loading && (
            <>
              <Avatar size={45} src={profile?.picture} />
              <div className="pl-3">
                <h4 className="mb-0">{profile?.firstName}</h4>
                <span className="text-muted">{profile?.lastName}</span>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="nav-profile-body">
        <NavMenu />
      </div>
    </div>
  );

  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={['click']}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item>
          <Avatar src={profile?.picture} />
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

const NavMenu = () => {
  const authDispatch = useAuthDispatch();

  return (
    <Menu>
      {/*profileMenu.map((el, i) => {
        return (
          <Menu.Item key={i}>
            <a href={el.path}>
              {<Icon className='mr-3' type={el.icon} />}
              <span className="font-weight-normal">{el.title}</span>
            </a>
          </Menu.Item>
        );
      })*/}
      <Menu.Item key={profileMenu.length + 1} onClick={() => authDispatch({ type: 'LOGOUT' })}>
        <span>
          {/* <LogoutOutlined className='mr-3' /> */}
          <span className="font-weight-normal">Sign Out</span>
        </span>
      </Menu.Item>
    </Menu>
  );
};
