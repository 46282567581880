import { Button } from 'antd';
import { ReactNode } from 'react';

interface Props {
  isLoading?: boolean;
  label: string;
  disabled?: boolean;
  icon?: ReactNode;
}

// THIS HACK SHOULD BE DELETED

export const SubmitButton = ({ isLoading, label, disabled, icon }: Props) => {
  return (
    <>
      <button type="submit" disabled style={{ display: 'none' }} aria-hidden="true" />
      <Button type="primary" htmlType="submit" loading={isLoading} disabled={disabled} icon={icon}>
        {label}
      </Button>
    </>
  );
};
