import { gql } from '@apollo/client';

export const GetChampionshipEvents = gql`
  query getChampionshipEvents(
    $filter: InputChampionshipEventsFilter!
    $pagination: InputPagePagination
  ) {
    championshipEvents(filter: $filter, pagination: $pagination) {
      edges {
        key
        name
        configs
      }
    }
  }
`;

export const CreateChampionshipMatchEvent = gql`
  mutation CreateChampionshipMatchEvent($uuid: UUID!, $eventData: InputEventData!) {
    championshipMatchEventCreate(uuid: $uuid, eventData: $eventData) {
      uuid
    }
  }
`;
