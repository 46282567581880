import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Form, Select } from 'antd';
import { ALL_ITEMS_ON_PAGE } from 'config/main';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { ROUTES } from 'routes/config';
import styled from 'styled-components';

import { PageHeader } from 'components/pageHeader';
import { SubmitButton } from 'components/submitButton';

import { GetChampionshipMatch } from 'queries/sportsChampionshipMatch';
import { CreateChampionshipMatchEntities } from 'queries/sportsChampionshipMatchTeams';
import { GetTeams } from 'queries/sportsTeamsList';
import {
  ChampionshipEntity,
  Mutation,
  MutationAddTeamsToChampionshipMatchArgs,
  MutationCreateChampionshipMatchEntitiesArgs,
  Query,
  QueryChampionshipMatchArgs,
  QueryTeamsArgs,
  Team,
} from 'queries/types';

const Container = styled.div`
  background: white;
  border: 1px solid #edf2f9;
  border-radius: 10px;
  padding: 50px;
  display: flex;
  align-items: center;
  flex-flow: column;

  & > div {
    max-width: 520px;
    width: 100%;
  }
`;

const StyledPageHeader = styled(PageHeader)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: 25px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;

  button {
    margin-left: 12px;
  }
`;

const I18N_PREFIX = 'sportsMatch.sections.teams.add';

export const ChampionshipMatchAddTeams = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const { uuid } = useParams<{ uuid: string }>();

  const [getChampionshipMatch, { data, loading }] = useLazyQuery<Query, QueryChampionshipMatchArgs>(
    GetChampionshipMatch,
    {
      variables: {
        uuid,
      },
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    getChampionshipMatch();
  }, [getChampionshipMatch]);

  const [addTeamsToChampionshipMatch, { loading: isAdding, data: isAdded }] = useMutation<
    Mutation,
    MutationCreateChampionshipMatchEntitiesArgs
  >(CreateChampionshipMatchEntities);

  useEffect(() => {
    if (isAdded) history.push(`${ROUTES.SportsMatch}/${uuid}/teams`);
  }, [isAdded]);

  const onFinish = async (values: any) => {
    addTeamsToChampionshipMatch({
      variables: {
        championshipMatchUuid: uuid,
        championshipEntityUuids: [values.team1, values.team2],
      },
    }).catch((error) => {
      console.error(error);
    });
  };

  const teams =
    (data?.championshipMatch.championshipGroup.championshipEntities as ChampionshipEntity[]) || [];
  const options = teams.map((entity: ChampionshipEntity) => {
    return { label: entity.championshipTeam.team.name, value: entity.uuid };
  });

  return (
    <Form onFinish={onFinish} layout="vertical">
      <StyledPageHeader title={'Associar Equipas'}>
        <ButtonsWrapper>
          <SubmitButton isLoading={loading || isAdding} label={t(`${I18N_PREFIX}.submit`)} />
        </ButtonsWrapper>
      </StyledPageHeader>
      <Container>
        <Form.Item
          name="team1"
          label={t(`${I18N_PREFIX}.team1`)}
          rules={[{ required: true, message: t(`${I18N_PREFIX}.errorMessage`) }]}
        >
          <Select
            className="w-100"
            placeholder={t(`${I18N_PREFIX}.team1`)}
            disabled={!data}
            options={options}
          />
        </Form.Item>
        <Form.Item
          name="team2"
          label={t(`${I18N_PREFIX}.team2`)}
          rules={[{ required: true, message: t(`${I18N_PREFIX}.errorMessage`) }]}
        >
          <Select
            className="w-100"
            placeholder={t(`${I18N_PREFIX}.team2`)}
            disabled={!data}
            options={options}
          />
        </Form.Item>
      </Container>
    </Form>
  );
};
