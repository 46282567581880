import { Input } from 'antd';
import { useTranslation } from 'react-i18next';

import { IconSearch } from 'assets/icons/iconSearch';

interface Props {
  onChange: React.ChangeEventHandler;
}

export const SearchField: React.VFC<Props> = ({ onChange }: Props) => {
  const { t } = useTranslation();

  return (
    <Input
      placeholder={t('actions.search')}
      prefix={<IconSearch width="16" height="16" />}
      onChange={onChange}
    />
  );
};
