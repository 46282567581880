import i18n from 'locales/i18n';
import { ModalTabButton } from 'typings/tabs';

import { FeedPostOrigin, FeedPostType } from 'queries/types';

/**
 * MAIN MENU SECTION
 */
export const tabsMenu: ModalTabButton[] = [
  {
    key: 'article',
    title: i18n.t('filters.article'),
    type: [FeedPostType.News],
    origin: [FeedPostOrigin.Internal],
  },
  {
    key: 'opinion',
    title: i18n.t('filters.opinion'),
    type: [FeedPostType.Opinion],
    origin: [FeedPostOrigin.Internal],
  },
  {
    key: 'video',
    title: i18n.t('filters.video'),
    type: [FeedPostType.Video],
    origin: [FeedPostOrigin.Internal],
  },
  {
    key: 'gallery',
    title: i18n.t('filters.gallery'),
    type: [FeedPostType.Gallery],
    origin: [FeedPostOrigin.Internal],
  },
  {
    key: 'social',
    title: i18n.t('filters.social'),
    origin: [FeedPostOrigin.Facebook, FeedPostOrigin.Instagram],
  },
];
