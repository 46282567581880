import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

import { FeedPostType } from 'queries/types';

const I18N_PREFIX: string = 'postsList';

interface Props {
  selectedPostType: string;
  onChange: (value: string) => void;
  data: FeedPostType[];
}

export const PostsTypeFilter: React.VFC<Props> = ({ selectedPostType, onChange, data }: Props) => {
  const { Option } = Select;
  const { t } = useTranslation();

  const types = data.length === 0 ? Object.values(FeedPostType) : data;

  if (types.length <= 1) return null;

  return (
    <Select
      defaultValue={selectedPostType}
      className="w-100"
      style={{ minWidth: 180 }}
      onChange={onChange}
      placeholder={t(`${I18N_PREFIX}.type`)}
    >
      <Option value="">{t(`${I18N_PREFIX}.filter.all`)}</Option>
      {types.map((elm) => {
        return (
          <Option key={elm} value={elm}>
            {t(elm)}
          </Option>
        );
      })}
    </Select>
  );
};
